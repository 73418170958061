<template>
<TheNavbar />
  <main class="certification-main">
    <div class="titre-photo-certification">
      <div class="container-fluid">
        <div class="row certification-bloc1 mt-lg-3">
          <div
            class="certification-bloc-titre-gauche col-lg-5 order-1 p-md-0 p-lg-1"
          >
            <div class="titre-certification ml20">
              <h1>certification<br />biologique</h1>
              <div class="certification-separation"></div>
              <img
                src="../../public/images/logo_certification-biologique.svg"
                alt="Erablieres Sirop Érables"
                class="img-fluid certification-medres"
              />
            </div>
          </div>

          <div class="photo-certification col-lg-7 order-lg-1 text-center p-0">
            <img
              src="../../public/images/sirop_erable_certification_bio.jpg"
              alt="Cabane à sucre les Érablières Bonneville"
              class="img-fluid certification-nomob image-fw p-0"
            />
            <img
              src="../../public/images/certification-erablieres-bonneville.jpg"
              alt="Erablieres Sirop Érables"
              class="img-fluid certification-mob p-0"
            />
          </div>

          <div class="col-12 mobile-sous-image-certification"></div>

          <div class="certification-faq col-lg-5 order-5 order-lg-4 pb-md-5 p-md-0 p-lg-1">
            <img
              src="../../public/images/logo_certification-biologique.svg"
              alt="Erablieres Sirop Érables"
              class="img-fluid certification-logo"
            />

            <p class="texte-certification-faq ml20">
              Nous sommes certifiés biologiques à St-Sylvère depuis 2020.
              L’érablière Aux mille érables de Ste-Thècle est également
              certifiée biologique depuis années. Pour cela, nous devons nous
              conformer à des normes strictes :
            </p>
            <div v-for="(description, index) in descriptions" :key="index" class="ml20">
              <div class="certification-separation-collapse"></div>
              <a
                data-bs-toggle="collapse"
                :href="`#${description.shortcut}`"
                role="button"
                aria-expanded="false"
                aria-controls="amenagement"
              >
                <div class="link-collapse" @click="handleCollapse(`${index}`)">
                  <span class="plus-moins" :id="`certification-item${index}`"
                    >+</span
                  >
                  <p class="desc-cert">{{ description.lien }}</p>
                </div>
              </a>
              <div class="collapse" :id="description.shortcut">
                <div>
                  <p :id="`text-collapse${index}`" class="open-collapse">
                    {{ description.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <TheFooter />
</template>

<script>
import listeDescriptions from "../composables/certificationDescriptions";
import TheNavbar from '../components/TheNavbar';
import TheFooter from '../components/TheFooter';

export default {
  name: "Certification",
  components: {TheNavbar, TheFooter},
  setup() {
    const descriptions = listeDescriptions();

    const handleCollapse = (index) => {
      if (
        document.querySelector(`#certification-item${index}`).innerHTML == "+"
      ) {
        document.querySelector(`#certification-item${index}`).innerHTML = "-";
      } else {
        document.querySelector(`#certification-item${index}`).innerHTML = "+";
      }
    };

    return {
      handleCollapse,
      descriptions,
    };
  },
};
</script>

<style scope>


.certification-main {
  background: linear-gradient(90deg, #ffffff 50%, #f5f3f0 50%);
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
  min-height: calc(100vh - 290px)
}
.certification-logo {
  margin-top: -40px;
  margin-bottom: 40px;
}

.certification-bloc-logo-wrapper {
  grid-column-start: 2;
  grid-column-end: 12;
}

.titre-photo-certification {
  grid-column-start: 2;
  grid-column-end: 12;
}
.certification-full-width {
  grid-column-start: 1;
  grid-column-end: 13;
}

.certification-bloc1 {
  position: relative;
}

.certification-bloc-titre-gauche {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titre-certification h1 {
  font-weight: bold;
  font-size: 30px;
  font-family: "Lato", sans-serif;
  letter-spacing: 1.35px;
  color: #9b552b;
  text-transform: uppercase;
  padding-bottom: 15px;
}

.certification-separation {
  width: 100px;
  border-top: 1px solid #222222;
  text-align: right;
}

.certification-separation-collapse {
  width: 100%;
  border-top: 1px solid #9b552b;
  text-align: right;
  margin-right: 25px;
}

.description-certification h5 {
  padding-top: 20px;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.68px;
  color: #222222;
  text-transform: uppercase;
}

.mobile-sous-image-certification {
  display: none;
}

.certification-mob {
  display: none;
}

.certification-logo-full {
  display: flex;
  justify-content: center;
  align-items: center;
}

.certification-faq {
  margin-left: 0px;
 
}

.certification-faq p,
a {
  padding-right: 15px;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #222222;
}

.texte-certification-faq {
  padding: 0 15px 70px 0;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #222222;
}
.desc-cert {
  padding-top: 20px;
}

.plus-moins {
  width: 20px;
  font-size: 29px;
  font-family: "Lato", sans-serif;
  font-weight: 100;
  margin-bottom: 2px;
  margin-right: 10px;
  color: #9b552b;
}

.link-collapse {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#text-collapse0,
#text-collapse1,
#text-collapse2,
#text-collapse3,
#text-collapse4 {
  padding-left: 30px;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #9b552b;
}

.titre-photo-certification {
  margin: 20px;
}

.open-collapse {
  padding-bottom: 20px;
}

.certification-medres {
  display: none;
}


@media (min-width: 577px) and (max-width: 991px) {
  .certification-main {
    background: none;
  }

  .certification-logo {
    display: none;
  }

  .photo-certification {
    margin-bottom: 30px;
  }

  .titre-certification {
    position: relative;
  }

  .certification-medres {
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    width: 60px;
  }

  .texte-certification-faq {
    padding: 25px 15px 25px 0;
  }

  .titre-photo-certification {
    margin: 0px;        /*  --------------------------------- ETAIT 10px  DERNIERE MODIF --------------- */
  }
  .open-collapse {
    background-color: #ffffff;
    padding: 20px 0;
  }

  .ml20{
  margin-left:20px;
}

.mr20{
  margin-right:20px;
}

  .pl20{
    padding-left:20px;
  }
  .pr20{
    padding-right:20px;
  }

  .certification-separation-collapse {
  width: calc(100vw - 40px);

}


#text-collapse0,
#text-collapse1,
#text-collapse2,
#text-collapse3,
#text-collapse4 {
  padding-left: 30px;
  margin-right: 20px;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #9b552b;
}


}

/* ====== VERSION MOBILE ======  */

@media (max-width: 576px) {
  .certification-main {
    background: none;
    background-color: #f5f3f0;
  }

  .certification-mob {
    display: block;
    width: 100%;
 
  }

  .certification-nomob {
    display: none;
  }

  .texte-certification-faq {
    padding: 0 15px 10px 0;
  }

  .titre-certification h1 {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #222222;
  }

  .description-certification h5 {
    font-size: 12px;

    letter-spacing: 0.54px;
  }

  .mobile-sous-image-certification {
    display: block;
    height: 40px;
    background: linear-gradient(90deg, #f5f3f0 50%, #9b552b 50%);
  }

  .titre-photo-certification {
    grid-column-start: 0;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 5;
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    padding-top: 40px;
  }

  .titre-certification {
    max-width: 100%;
  }

  .titre-photo-certification {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 2;
    margin-top: -40px;
  }

  .certification-historique {
    padding-top: 20px;
  }

  .certification-faq p {
    padding-right: 15px;
    font-size: 12px;
    letter-spacing: 0.42px;
    color: #222222;
  }

  .certification-logo {
    width: 50px;
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .certification-separation-collapse {
    margin-left: 0;
    width: 100%;
  }

  .certification-separation {
    margin-left: 0;
  }

  .open-collapse {
    background-color: #ffffff;
    padding: 20px 0;
  }

  .plus-moins {
    margin-bottom: -3px;
  }

  .certification-mob {
    display: block;
    width: 100%;
  }
}
</style>
