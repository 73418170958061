<template>
<TheNavbar />
  <TheHeader />
  <div class="boutique_container">
    <div class="boutique" v-if="produits">
      <div
        v-for="item in produits"
        :key="item.lienProduit"
        class="produit_boutique"
      >
        <router-link :to="`${adresseActuelle}/${item.lienProduit}`">
          <img class="imageproduit m-0" :src="item.imageHighRes" :alt="item.nom" />
          <h3 class="mt-3 text-uppercase">{{ item.nom }}</h3>
          <p v-if="item.multiFormat" id="if-multi">Plusieurs formats</p>
        </router-link>
      </div>
    </div>
  </div>
<TheFooter />
</template>

<script>
import TheNavbar from '../../components/TheNavbar';
import TheFooter from '../../components/TheFooter';
import getProduits from "../../composables/getProduits";
import TheHeader from "../../components/TheHeader";
import { useRoute } from "vue-router";
import { onMounted } from 'vue';



export default {
  name: "Boutique",
  components: { TheHeader, TheNavbar, TheFooter}, 
  setup() {
    const route = useRoute();
    const adresseActuelle = route.path;
    

      const onLoad = onMounted(()=>{
            document.querySelector('#nav').classList.remove('split');
        });
      


    // LISTING DES PRODUITS
    const { produits, error, load } = getProduits();
    load();


    return {
      produits,
      error,
      load,
      route,
      adresseActuelle,
    };
  },
};
</script>

<style scope>
h3 {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.68px;
  color: #222222;
  text-align: center;
}

#if-multi{
  font-size: 15px;
  color: #9B552B;
  font-weight: lighter;
  letter-spacing: 0.53px;
  text-align: center;
}

.boutique_container {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
  grid-template-areas: ". boutique .";
  padding-bottom: 50px;
}

.boutique {
  grid-area: boutique;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px 60px;
  padding: 20px;
}

.imageproduit {
  width: 100%;
}

.produit_boutique {
  margin-top: 45px;
/*   
  box-shadow: 0px 14px 22px -9px #bbcbd8; */
}

a,
a:hover {
  text-decoration: none;
  color: #6a6a6a;
}

/* TABLETTE */
@media only screen and (max-width: 830px) {
  .boutique {
    grid-template-columns: 1fr 1fr;
  }

    .produit_boutique {
  margin-top: 5px;
/*   
  box-shadow: 0px 14px 22px -9px #bbcbd8; */
}
}

/* MOBILE  */
@media only screen and (max-width: 560px) {
  .boutique {
    grid-template-columns: 1fr;
  }
  .produit_boutique {
  margin-top: 0;
/*   
  box-shadow: 0px 14px 22px -9px #bbcbd8; */
}

}
</style>
