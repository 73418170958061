<template>
<TheNavbar />
<TheHeader />


<div class="panier-container">
<div v-if="store.state.cart.length != 0" class="panier">
<section v-if="etape === 1">

<div class="container-fluid p-0">
 <div class="row border-bottom pb-2 pt-2">
    
           
            <div class="col-md-1 wrapper-center-x">
                
            </div>

            <div class="col-md-2 wrapper-center-x">
                
            </div>
            
            <div class="col-md-4">
                <p class="v-mid d-none d-md-block">Produit</p>
            </div>

            <div class="col-md-1 wrapper-center-x">
                <p class="v-mid d-none d-md-block">Prix</p>
            </div>

            <div class="col-md-2">
                <div class="v-mid wrapper-center-x">
                    <p class="d-none d-md-block">Quantité</p>
                </div>
            </div>
            <div class="col-md-2">
                <div class="v-mid wrapper-end">
                    <div class="spacer-end-titre">
                        Total
                    </div>
                </div>
            </div>
           

        </div>
    <transition-group name="toast">
    <div v-for="(item, index) in store.state.cart" :key="index">

        <div  class="row items-dans-panier">
        
        <div class="col-1 d-block d-sm-none border-bottom">
            <i class="far fa-trash-alt v-mid" @click="store.methods.removeItem(item.id, item.format)"></i>
        </div>
         
        <div class="col-11 col-sm-12">
           
        <div class="row border-bottom pb-2 pt-2">
    
            <div class="col-1 col-sm-1 mt-4 mt-sm-0 wrapper-center-x d-none d-sm-block">
                <i class="far fa-trash-alt v-mid" @click="store.methods.removeItem(item.id, item.format)"></i>
            </div>

            <div class="col-3 col-sm-2 wrapper-center-x">
                <img :src="item.image" :alt="item.nom" class="img-fluid img-panier">
            </div>
            
            <div class="col-8 col-sm-4">
                <p class="v-mid text-uppercase">{{item.nom}}<br/>{{item.format}}</p>
            </div>

            <div class="col-3 col-sm-1 mt-2 mt-sm-0 wrapper-center-x">
                <p class="v-mid">{{prixWithDec(item.prix)}}$</p>
            </div>


            <div class="col-3 col-sm-2 mt-2 mt-sm-0">
                <div class="v-mid wrapper-center-x">
                    <button @click="store.methods.decreaseQty(item.id, item.format)" class="decrease"><p>-</p></button>
                        <div class="panier_quantite"><p> {{item.quantite}}</p></div>
                    <button @click="store.methods.increaseQty(item.id, item.format)" class="increase"><p>+</p></button>
                </div>
            </div>
            <div class="col-6 col-sm-2 mt-2 mt-sm-0">
                <div class="v-mid wrapper-end">
                   
                <div class="spacer-end">{{calcTotal(item.prix, item.quantite)}}$</div>
               </div>
            </div>
           

        </div>
     

</div>
    
</div>

      
        </div>

    </transition-group>
</div>

      
        

<div v-if="erreur.length > 5" class="alert alert-danger alert-dismissible fade show mt-5 text-center" role="alert">
  {{ erreur }}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
      <div class="panier-methode-livraison">
          <div class="panier-titre">
              <h3 class="titre-recuperation-methodes" id="methoderecup">Methodes de récupération</h3>
              
          </div>
          <div></div>
          <div></div>

          <div class="form-radio">
                <input class="" type="radio" name="recuperation" id="st-sylvere" value="vc-st-sylvere" v-model="infosClient.methodeRecuperation" @change="methodePrix()">
                <label for="st-sylvere">
                    <p>St-Sylvère (bureau coordonnateur)<br /> 1315 Route du 10eme Rang, St-Sylvère,<br /> G0Z 1H0.</p>
                </label>
              
          </div>
        <div class="form-radio">
              <input class="choix-livraison" type="radio" name="recuperation" id="ste-thecle" value="vc-ste-thecle" v-model="infosClient.methodeRecuperation" @change="methodePrix()">
                <label  for="ste-thecle" class="choix-livraison">
                     <p>Ste-Thècle (présence occasionnelle)<br /> 1671 ch. Saint-Georges, Sainte-Thècle,<br /> G0X 3G0.</p>
                </label>
          
        </div>
      <div class="form-radio">
             <input class="choix-livraison" type="radio" name="recuperation" id="quebec" value="vc-quebec" v-model="infosClient.methodeRecuperation" @change="methodePrix()">
                <label for="quebec" class="choix-livraison">
                       <p>Québec<br /> 715 rue Ste-Madeleine, Québec,<br /> G1R 3M5.</p>
                </label>
          
        
    </div>
          
           <div class="panier-titre padding-livraison-locale">
              <h3 class="titre-recuperation-methodes">livraison locale</h3>
          </div>
          <div></div>
          <div></div>
    <div class="options-livraison">
        <div class="form-radio" v-if="store.state.sousTotal >= 50">
          
                <input type="radio" name="recuperation" id="livraison-st-sylvere" value="livraison-st-sylvere" v-model="infosClient.methodeRecuperation" @change="methodePrix()">
                <label for="livraison-st-sylvere">
                       <p>St-Sylvère : supplément de 5$</p>
                </label>
         
          
        </div>
        
         <div class="form-radio" v-else>
          
                 <input type="radio" name="recuperation" disabled id="livraison-st-sylvere" value="livraison-st-sylvere" v-model="infosClient.methodeRecuperation" @click="popMessage()">
                <label for="livraison-st-sylvere">
                       <p>St-Sylvère : supplément de 5$</p>
                </label>
         
          
        </div>
              <div class="form-radio" v-if="store.state.sousTotal >= 50">
                
                <input class="choix-livraison" type="radio" name="recuperation" id="livraison-autres" value="livraison-autres" v-model="infosClient.methodeRecuperation" @change="methodePrix()">
        
                <label for="livraison-autres" class="choix-livraison">
                       <p>Ste-Gertrude, Daveluyville, St-Wenceslas : 10$</p>
                </label>
         
                </div>
                <div class="form-radio" v-else>
                   
                <input  type="radio" name="recuperation" disabled id="livraison-autres" value="livraison-autres" v-model="infosClient.methodeRecuperation" @click="popMessage()">
                
                <label for="livraison-autres" >
                       <p>Ste-Gertrude, Daveluyville, St-Wenceslas : 10$</p>
                </label>
         
                </div>
                
            <div class="info-livraison font-weight-light" v-if="store.state.sousTotal >= 50">
                <p>Une commande d’au moins 50$ est nécessaire<br /> pour sélectionner la livraison locale.</p>
            </div>
            <div class="info-livraison" v-else>
                <p class="fs-5">Une commande d’au moins 50$ est nécessaire<br /> pour sélectionner la livraison locale.</p>
            </div>
          
        
    </div>
   
    </div>

    <div class="total-panier">

        <h4>TOTAL DE VOTRE COMMANDE</h4>

        <div class="total-panier-desc bloc-panier">
            <div class="bloc-panier-total"><p>Sous-total</p></div>
            <div class="bloc-panier-total"><p>{{prixWithDec(store.state.sousTotal)}}$</p></div>
        </div>
        
        <div class="separator-item"></div>

        <div class="choix-livraisons bloc-panier">
            <div class="bloc-panier-total">
                <p v-if="infosClient.methodeRecuperation == 'vc-quebec'">Récupération<br/> Québec</p>
                <p v-else-if="infosClient.methodeRecuperation == 'vc-ste-thecle'">Récupération<br/> Ste-Thècle</p>
                <p v-else-if="infosClient.methodeRecuperation == 'vc-st-sylvere'">Récupération<br/> St-Sylvère</p>
                <p v-else-if="infosClient.methodeRecuperation == 'livraison-st-sylvere'">Livaison<br/> St-Sylvère</p>
                <p v-else-if="infosClient.methodeRecuperation == 'livraison-autres'">Livaison<br/> Autres</p>
                <p v-else>Méthode de récupération</p>

            </div>
       
            <div class="bloc-panier-total">
                <p v-if="infosClient.actionRecup !== 'livraison'">Sur rendez-vous<br/>( Sans frais )</p>
                <p v-else>{{prixWithDec(infosClient.recuperationPrix)}}$</p>
                
                </div>
        </div>
        <div class="separator-item"></div>
        <div class="taxes-applicables bloc-panier">
           
            
                <div class="bloc-panier-total"> <p>TPS : {{store.state.montantTPS}}$</p></div>
              <div class="bloc-panier-total"><p>TVQ : {{store.state.montantTVQ}}$</p></div>
        </div>
        <div class="separator-item"></div>
        <div class="total-global bloc-panier">
            <div class="bloc-panier-total font-total"><p>Total</p></div>
            <div class="bloc-panier-total font-total"><p>{{store.state.total}}$</p></div>
          
        </div>
        <div class="paiement-bloc-panier">
        <button class="paiement-boutton" @click="paiement()">PAIEMENT</button>
      
        </div>
     
    </div>
    
    </section>
    <section v-if="etape === 2" class="confirmation-commande">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="pb-2 pb-md-3 pb-lg-5 text-brun">Votre commande</h2>
                
                    <table class="table  table-sm pb-2 table-panier">
            
                    <tbody>
                        <tr v-for="(item, index) in store.state.cart" :key="index" >
                        <td>{{ item.quantite }}<span class="no-mob"> X </span></td>
                        <td class="nom-produit-panier">{{ item.nom }}</td>
                        <td>{{ item.format}}</td>
                        <td class="text-end">{{prixWithDec(item.quantite*item.prix)}}$</td>
                        </tr>
                        <tr v-if="infosClient.methodeRecuperation == 'vc-quebec'" class="pb-2">
                            <td>Récupération à :</td>
                            <td>{{store.state.adressesRecuperation[2].nom}}</td>
                            <td class="text-end"></td>
                        </tr>
                        <tr v-if="infosClient.methodeRecuperation == 'vc-st-sylvere'" class="pb-2">
                            <td></td>
                            <td>Récupération:</td>
                            <td>{{store.state.adressesRecuperation[0].nom}}</td>
                            <td class="text-end"></td>
                        </tr>
                        <tr v-if="infosClient.methodeRecuperation == 'vc-ste-thecle'" class="pb-2">
                            <td></td>
                            <td>Récupération:</td>
                            <td>Ste-Thècle</td>
                            <td class="text-end"></td>
                        </tr>
                        <tr v-if="infosClient.methodeRecuperation == 'livraison-st-sylvere'" class="pb-2">
                            <td></td>
                            <td>Livraison:</td>
                            <td>Saint-Sylvère</td>
                            <td class="text-end">5.00$</td>
                        </tr>
                        <tr v-if="infosClient.methodeRecuperation == 'livraison-autres'" class="pb-2">
                            <td></td>
                            <td>Livraison:</td>
                            <td>Ste-Gertrude,Daveluyville,<br/>St-Wenceslas</td>
                            <td class="text-end">10.00$</td>
                        </tr>
                        <tr v-if="store.state.montantTVQ > 0" class="pb-2">
                            <td></td>
                            <td></td>
                            <td>TPS :<br/> {{store.state.montantTPS}}$</td>
                            <td class="text-end">TVQ :<br/> {{store.state.montantTVQ}}$</td>
                        </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-end">Total : {{store.state.total}}$</td>
                        </tr>
                    </tbody>
                    </table>
                <div class="button-modif-wrapper pt-3">
                    <button type="button" @click="modifCommande()" class="modif-commande">Modifier ma commande</button>
                </div>
                    
                </div>
                <div class="col-lg-6">
                  <h2 class="pb-3 text-brun">Choisissez votre méthode de paiement</h2>
           
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="paiement-online" value="paiement-online" v-model="methodePaiement" @change="handlePaiement()" >
                        <label class="form-check-label" for="paiement-online">En ligne ( Carte de crédit )</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="paiement-argent" value="paiement-argent" v-model="methodePaiement" @change="handlePaiement()">
                        <label class="form-check-label" for="paiement-argent">En argent ( lors de la {{infosClient.actionRecup}} de la marchandise )</label>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6  pt-3 pt-md-0">
                              <div class="form-floating">
                                <input type="text" class="form-control" id="prenom-input" placeholder="Prénom" v-model="infosClient.prenom" required>
                                <label for="prenom-input">Prénom</label>
                            </div>
                        </div>
                        <div class="col-md-6 pt-3 pt-md-0">   
                            <div class="form-floating ">
                                <input type="text" class="form-control" id="nom-input" placeholder="Nom" v-model="infosClient.nom" required>
                                <label for="nom-input">Nom</label>
                                </div>
                        </div>
                        <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="tel" class="form-control" id="telephone-input" placeholder="Téléphone" v-model="infosClient.telephoneMaison" @keyup="formatPhoneNumber(infosClient.telephoneMaison)" maxlength="14" required>
                                <label for="telephone-input">Téléphone</label>
                            </div>
                        </div>
                         <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="tel" class="form-control" id="mobile-input" placeholder="Cellulaire" v-model="infosClient.mobile" @keyup="formatMobile(infosClient.mobile)" maxlength="14">
                                <label for="mobile-input">Cellulaire</label>
                            </div>
                        </div>
                        <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="email" class="form-control" id="email-input" placeholder="Courriel" v-model="infosClient.courriel" required>
                                <label for="email-input">Courriel</label>
                            </div>
                        </div>
                          <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="text" class="form-control" id="num-nom-rue-input" placeholder="N° et nom de rue" v-model="infosClient.numNomRue" required>
                                <label for="num-nom-rue-input">N° et nom de rue</label>
                            </div>
                        </div>
                       
                         <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="text" class="form-control" id="ville-input" placeholder="Ville" v-model="infosClient.ville" required>
                                <label for="ville-input">Ville</label>
                            </div>
                        </div>

                         <div class="col-md-6 pt-3">   
                            <div class="form-floating">
                                <input type="text" class="form-control" id="code-postale-input" placeholder="Code Postal" v-model="infosClient.codePostal" required>
                                <label for="code-postale-input">Code Postal</label>
                            </div>
                        </div>
                         <div class="col-md-12 pt-5" v-if="methodePaiement == 'paiement-argent'">   
                            <p>* Le paiement se fera en argent sur {{infosClient.actionRecup}} de la marchandise.</p>
                            <p v-if="infosClient.actionRecup == 'livraison'">* Il est important vérifier l'adresse de livraison.</p>
                        </div>
                        <div class="col-md-12 pt-5" v-if="methodePaiement == 'paiement-online'">   
                        </div>
                        <div class="col-8 pt-5">   
                        </div>
                        <div class="col-12 pt-2 pt-md-4 bouton-commande" v-if="methodePaiement == 'paiement-argent'">  
                            <button @click="confirmCommande()" class="bouton-commande-self">Confirmer la commande</button>
                        </div>
                         <div class="col-12  pt-2 pt-md-4 bouton-commande" v-else-if="methodePaiement == 'paiement-online'">  
                            <button @click="constructProductsForStripe()" class="bouton-commande-self">Confirmer la commande</button>
                        </div>
                        <div class="col-12 pt-2 pt-md-4 bouton-commande" v-else>  
                            <button disabled class="bouton-commande-self">Confirmer la commande</button>
                        </div>
                        
                       
                    </div>
                  
                </div>
            </div>
        </div>
    </section>
    </div>

    <!-- SI PANIER EST VIDE -->
    <div v-else class="panier-vide">
       <div class="panier-vide-carre text-center">
           <h1>Oups!</h1>
           <p>Votre panier est vide,<br/>
           poursuivez votre magasinage<br/>
           pour remplir ce panier de nos produits d'érable</p>
       </div>
    </div>
    
</div>


<TheFooter />

</template>

<script>

import TheNavbar from '../components/TheNavbar'
import TheFooter from '../components/TheFooter'
import TheHeader from '../components/TheHeader'
import { useRouter } from 'vue-router'

import { inject, ref, onMounted, watchEffect, reactive } from 'vue'
export default {
  name: 'Panier',
  components: { TheHeader, TheFooter, TheNavbar },
  setup () {
    const store = inject('store')
    const infosClient = reactive({})
    const route = useRouter()
    const erreur = ref('')
    const methodePaiement = ref('')
    const stripe = Stripe('pk_live_51IeJTlGTfKp5GINnrJdTwoyQqHE30tlxu5Io2m4kwkAvVFrmhHcjjvgYVAJgbn0Q6hjKE07aGEz164DnkltQR8bQ00HD3l0dnk')
    infosClient.recuperationPrix = 0
    const etape = ref(1)

    const onLoad = onMounted(() => {
      // GERE BACKGROUND
      store.methods.calculSousTotal()
      document.querySelector('#nav').classList.remove('split')
    })

    const formatPhoneNumber = (phoneNumberString) => {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        infosClient.telephoneMaison = '(' + match[1] + ') ' + match[2] + '-' + match[3]
        return infosClient.telephoneMaison
      }
      return null
    }

    const formatMobile = (phoneNumberString) => {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        infosClient.mobile = '(' + match[1] + ') ' + match[2] + '-' + match[3]
        return infosClient.mobile
      }
      return null
    }

    watchEffect(() => { // VERIFIE QUE LA LIVRAISON EST DESACTIVE SI MONTANT REVIEN SOUS 50
      if (store.state.sousTotal < 50) {
        if (infosClient.methodeRecuperation == 'livraison-st-sylvere' || infosClient.methodeRecuperation == 'livraison-autres') { infosClient.methodeRecuperation = '' }
        infosClient.recuperationPrix = 0
        store.methods.calculSousTotal()
      }
    })

    const methodePrix = () => {
      infosClient.actionRecup = ''
      if (infosClient.methodeRecuperation == 'vc-quebec' || infosClient.methodeRecuperation == 'vc-st-sylvere' || infosClient.methodeRecuperation == 'vc-ste-thecle') {
        infosClient.recuperationPrix = 0
        store.state.recuperationPrix = 0
        store.methods.calculSousTotal()

        infosClient.actionRecup = 'récupération'
      } else {
        infosClient.actionRecup = 'livraison'

        if (infosClient.methodeRecuperation == 'livraison-st-sylvere') {
          store.state.recuperationPrix = 5
          infosClient.recuperationPrix = 5
          store.methods.calculSousTotal()
        } else {
          store.state.recuperationPrix = 10
          infosClient.recuperationPrix = 10
          store.methods.calculSousTotal()
        }
      }
    }
    const prixWithDec = (prix) => {
      return prix.toFixed(2)
    }

    const calcTotal = (prix, qty) => {
      return (prix * qty).toFixed(2)
    }

    const paiement = () => {
      // VERIFICATION QUE METHODE DE RECUPERATION EST SELECTIONNÉE
      if (infosClient.methodeRecuperation === undefined) {
        erreur.value = "Vous devez choisir une méthode de récupération pour passer à l'étape suivante !"
        return
      } else {
        erreur.value = ''
      }
      // SI LA QUANTITE N'EST PAS À 0 GARDER DE LE PRODUIT
      const filteredQtyCart = []
      store.state.cart.forEach(item => {
        if (item.quantite != 0) {
          filteredQtyCart.push(item)
        }
        store.state.cart = filteredQtyCart
      })
      window.scrollTo(0, 0)
      etape.value = 2
    }

    const handlePaiement = () => {
      store.state.infosClient = infosClient
    }

    const createStripeCheckout = async (productsList) => {
      fetch('https://leserablieresbonneville.ca/checkout/create-stripe-checkout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(productsList)
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (session) {
 ``
 return stripe.redirectToCheckout({ sessionId: session.id })
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(function (error) {
          console.error('Error:', error)
        })
    }

    const constructProductsForStripe = () => {
      const productsList = []
      store.state.cart.forEach(item => {
        productsList.push({
          price_data: {
            currency: 'cad',
            product_data: {
              name: item.nom.toUpperCase() + ' ' + item.format.toUpperCase(),
              images: [item.image]
            },
            unit_amount: item.prix * 100
          },
          quantity: item.quantite
        })
      })
      if (infosClient.recuperationPrix != 0) {
        let nomLivraison = ''
        if (infosClient.methodeRecuperation == 'livraison-st-sylvere') {
          nomLivraison = '- Livraison St-Sylvère'
        } else {
          nomLivraison = ''
        }
        productsList.push({
          price_data: {
            currency: 'cad',
            product_data: {
              name: `Livraison ${nomLivraison}`

            },
            unit_amount: infosClient.recuperationPrix * 100
          },
          quantity: 1
        })
      } // CALCUL TAXES
      if (store.state.montantTVQ != 0) {
        let taxes = parseFloat(store.state.montantTPS) + parseFloat(store.state.montantTVQ)
        taxes = parseInt(taxes * 100)
        productsList.push({
          price_data: {
            currency: 'cad',
            product_data: {
              name: 'Total des taxes ( TPS TVQ )'

            },
            unit_amount: taxes // A changer
          },
          quantity: 1
        })
      };
      // AJOUT DES TAXES AU INFOSCLIENT
      store.state.infosClient.montantTVQ = store.state.montantTVQ
      store.state.infosClient.montantTPS = store.state.montantTPS

      store.methods.storeInfosClient() // Temp Store Data

      createStripeCheckout(productsList)
    }

    const confirmCommande = () => {
      if (!infosClient.mobile) {
        infosClient.mobile = '(888) 888-8888'
      }

      store.methods.storeInfosClient() // Temp Store Data

      route.push({ path: '/boutique/success/paiement-argent' })
    }

    const modifCommande = () => {
      etape.value = 1
    }

    const popMessage = () => {
      document.querySelector('#text-liv').classList.add('pop')
      console.log('click')
    }

    return {
      store,
      calcTotal,
      prixWithDec,
      methodePrix,
      paiement,
      etape,
      erreur,
      methodePaiement,
      handlePaiement,
      createStripeCheckout,
      constructProductsForStripe,
      infosClient,
      confirmCommande,
      modifCommande,
      formatPhoneNumber,
      formatMobile,
      popMessage

    }
  }
}

</script>

<style scoped>


/* enter transitions */
  .toast-enter-active {
    animation: wobble 0.8s ease;
  }
  /* leave transitions */
  .toast-leave-to {
    opacity: 0;
    transform: translateX(-60px);
  }
  .toast-leave-active {
    transition: all 0.5s ease;
  }
  @keyframes wobble {
    0% { transform: translateY(-100px); opacity: 0 }
    50% { transform: translateY(0px); opacity: 1 }
    60% { transform: translateX(8px); opacity: 1 }
    70% { transform: translateX(-8px); opacity: 1 }
    80% { transform: translateX(4px); opacity: 1 }
    90% { transform: translateX(-4px); opacity: 1 }
    100% { transform: translateX(0px); opacity: 1 }
  }

/* MODIF NOUVEAU CART */

.wrapper-center-x{
    display: flex;
    justify-content: center;
}

.wrapper-end{
    display: flex;
    justify-content: flex-end;
}



.spacer-end{
    margin-right: 30px;
}

.spacer-end-titre{
    margin-right: 40px;
}

/* FIN MODIF*/

.panier-vide{
    grid-column-start: 2;
    grid-column-end:12;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9B552B;
}

.panier-vide-carre{
    width: 80%;
    height: 300px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.panier-vide-carre h1{
    font-size: 6em;
    font-family: "Lato", sans-serif;
}

.panier-vide-carre p{
    font-size: 1em;
    color: #9B552B !important;
    text-transform: uppercase;
}


.button-modif-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.bouton-commande{
    display: flex;
    justify-content: flex-end;

}


.modif-commande{
      
    text-transform: uppercase;
    width: 220px;
    height: 74px;
    background-color:#f5f3f0;
    border: none;
    color : #9B552B;
    font-size: 13px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    letter-spacing:0.58px;
    
}

.titre-recuperation-methodes{
    font-size: 15px;
    font-weight: bold;
    color: #9b552b;
    letter-spacing:1.13px;
}



.text-brun{
   color: #9b552b;
}

.total-panier h4{
    font-size: 30px;
    color:#9b552b;
    font-weight: bold;
    letter-spacing:1.35px;
}
.aligne-droite{
    display: flex;
    justify-content: flex-end;
}

.nom-produit-panier::first-letter {
  text-transform: capitalize;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fa-trash-alt{
    cursor: pointer;
    transition-duration: 0.3s;
}

.fa-trash-alt:hover{
    transition: 0.2s;
    transform: scale(1.1) translateX(4px);
}

.panier-container {
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
  min-height: calc(100vh - 375px);

}

.panier{
  grid-row-start: 1;
  grid-column-start:2;
  grid-column-end: 12;
  padding: 60px 20px;
}

.desc-panier, .item-in-cart{
    display: grid;
    grid-template-columns: 200px 4fr 2fr 2fr 2fr;
    border-bottom: 1px solid;
}
button:focus {outline:0;}

.pl5{
    padding-left: 12px;
}


.panier-methode-livraison{
    display: grid;
    padding-top: 45px;
    grid-template-columns: 1fr 1fr 1fr;
}

.padding-livraison-locale{
    padding-top:60px;
}

.panier-titre{
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
 
}

.panier-titre h3{
    padding-bottom: 15px;
}
.item-in-cart{
    padding: 10px 0;
}

.panier-desc{
    padding-left: 10px;
   
}
.panier-produit-wrapper{
    display: flex;
    align-items: center;

}

.separator-item{

     border-bottom: 1px solid;
}


.v-mid{
    height: 100%;
    display: flex;
    align-items: center;
}


.img-panier{
    height:80px;
}

.desc-panier{
    padding-bottom: 10px;
}

.panier-container p{
    font-size: 15px;
    font-family: "Lato", sans-serif;
    letter-spacing: 0.53px;
    color: #222222;
}

.pad-top{
     padding-top: 10px;
}


.info-livraison{
    padding: 20px 0 0 27px;
    
}

.pop{
    transform: scale(2);
}

.panier-container p{ 
    padding: 0;
    margin: 0;
}
.form-radio{
   
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.item_quantite{
    margin-left: -12px;
    
}


.panier_quantite{
    min-width: 25px;
    min-height: 35px;

    display:inline-flex;
    justify-content: center;
    align-items: center;
    
    background-color: #f5f3f0;
    border: 1px solid #f5f3f0;
   
}

.increase, .decrease{
 
  text-align: center;
  min-width: 35px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  min-height: 35px;
  vertical-align: middle;
  background-color: #f5f3f0;
  border: 1px solid #f5f3f0;
  z-index: 10;
}

.increase p{
  color:#9b552b;
}

.decrease p{
    color:#9b552b;
}



.form-radio label{
padding-left: 15px;


}

.form-radio input{
    background-color: #9B552B;
}


.total-panier{
    padding-top : 40px;
    display: flex;
    flex-direction: column;
   

}

.panier-separator{
    border-top: 1px solid #000000;
    width: 100%;
}

.paiement-boutton{
    width: 309px;
    height: 74px;
    background-color:#f5f3f0;
    border: none;
    color : #9B552B;
    font-size: 13px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    letter-spacing:0.58px;
}

.bloc-panier{
    display: flex;
    justify-content: flex-start;
}

.bloc-panier-total{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height:65px;
    width: 150px;
}

.paiement-bloc-panier{
    display: flex;
    justify-content: flex-end;
}


.font-total p {
    color: #9b552b;
    font-size: 15px;
}


.bouton-commande-self{
  
    text-transform: uppercase;
    width: 220px;
    height: 74px;
    background-color:#f5f3f0;
    border: none;
    color : #9B552B;
    font-size: 13px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    letter-spacing:0.58px;
    
}



.panier input{
    cursor: pointer;
}
@media (max-width: 991px){

.paiement-boutton, .modif-commande, .bouton-commande-self{
    width: 220px;
    height: 74px;
    color:#f5f3f0;
    background-color : #9B552B;
    font-size: 13px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    letter-spacing:0.58px;
}
}




/* @media (max-width: 576px) {  */
@media (max-width: 768px) {


.paiement-boutton, .modif-commande, .bouton-commande-self{
    width: 100%;
    /* height: 74px;
    color:#f5f3f0;
    background-color : #9B552B;
    font-size: 13px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    letter-spacing:0.58px; */
}

.modif-commande{
    margin-bottom: 35px;
}






.panier{

  padding: 5px 20px;
}

.desc-panier, .item-in-cart{
    grid-template-columns: 30px 4fr 2fr 2fr 2fr;
}

.panier-methode-livraison{

    padding-top: 35px;
    grid-template-columns: 1fr;
   
}

.wrapper-center-x{
    justify-content:flex-start;
}


.panier-nom-produit{
 
    width: 100px;
}


.panier-format{
    width: 120px;
}

.panier-total{
    width: 30px;
}
.panier-methode-livraison p{
    font-size: 12px;
}

.items-panier .row{

    border-bottom: 1px solid;
    
}

.img-panier{
height: 45px;
width: 42px;
}

.panier-container p, .table-panier{
    font-size: 12px;
}

.choix-livraison{
    margin-top: 15px;
}

.increase, .decrease{
  width: 20px;
}

.panier_quantite{
    width: 15px;
}

.fa-trash-alt{
    transform: scale(0.7);
}


.panier_quantite{
    min-width: 25px;
    height: 30px;
    background-color: #9B552B;
    border: 1px solid #9B552B;
}

.panier_quantite p{
    color: #ffffff;
}

.increase, .decrease{
  min-width: 25px;
  height: 30px;
  background-color: #9B552B;
  border: 1px solid #9B552B;
}

.increase p, .decrease p{
  color: #ffffff;
}
 
}
</style>