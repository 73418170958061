<template>
<TheNavbar />

    <div class="boutique-success">
        <div class="carre-merci">
            <h1>Merci!</h1>
            <p class="text-center">
                pour votre commande.
            <span class="mobile-success"><br/></span>
            notre équipe communiquera avec vous
            sous peu</p>

        </div>
    </div>
    
  <TheFooter />
</template>

<script>

import TheNavbar from '../../components/TheNavbar';
import TheFooter from '../../components/TheFooter';
import { projectFirestore } from "../../firebase/config";
import { ref, inject, onMounted} from "vue";
import { useRoute } from "vue-router";



export default {
  name: "Success",
  components: { TheFooter, TheNavbar},

  setup() {
    const store = inject('store');
    // LECTURE PRODUIT
    const route = useRoute();
    const facture = ref();

    const idTransactionSuccess = route.params.id;
    const checkoutResponse = ref();
    const currentNumFacture = ref(0);
 

    // GENERE LE PADDING AVANT LE DEBUT DU NUM DE FACTURE
    const createPaddingFacture = (numFacture)=> {
      var padding = '' ;
        if(numFacture < 10){
            padding = '00000';
        }else if(numFacture >= 10 && numFacture < 100){
            padding = '0000';
        }else if(numFacture >= 100 && numFacture < 1000){
            padding = '000';
        }else if(numFacture >= 1000 && numFacture < 10000){
            padding = '00';
        }else if(numFacture >= 10000 && numFacture < 100000){
            padding = '0';
        }else{
            padding = ''
        }

        return padding;
    }


       const generateEmail = async () => {
   
            //POST request with body equal on data in JSON format
            fetch('https://leserablieresbonneville.ca/email/commande', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(store.state.factureClient),
            })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
            console.log('Success:', data);
            })
            //Then with the error genereted...
            .catch((error) => {
            console.error('Error:', error);
            });

       }




    const generateFacture = async (session, numFacture) => {

          if(store.state.checkoutDone) {
           // SI FACTURE DEJA UPLOADER DANS DB ANNULER UPLOAD
           return;
       };
       

       if(session == 'paiement-argent'){
        // SI PAIEMENT EN ARGENT

   



        facture.value = {
                    'dateCreated': new Date(),
                    'numFacture':  numFacture.toLowerCase(),
                    'methodeShipping' : store.state.infosClient.actionRecup,
                    'methodeRecuperation' : store.state.infosClient.methodeRecuperation,
                    'itemsSold': store.state.cart,
                    'montantTotal' : store.state.total,
                    'prenom' : store.state.infosClient.prenom.toLowerCase(),
                    'nom' : store.state.infosClient.nom.toLowerCase(),
                    'courriel' : store.state.infosClient.courriel.toLowerCase(),
                    'telephoneMaison' : store.state.infosClient.telephoneMaison,
                    'mobile' : store.state.infosClient.mobile,
                    'numNomRue' : store.state.infosClient.numNomRue.toLowerCase(),
                    'ville' : store.state.infosClient.ville.toLowerCase(),
                    'codePostal' : store.state.infosClient.codePostal.toLowerCase(),
                    'paymentMethod' : 'argent',
                    'marchandiseDonnee': false,
                    'isDeleted': false,
                    'montantTPS': store.state.montantTPS,
                    'montantTVQ': store.state.montantTVQ,
                    'totalTaxes': (parseFloat(store.state.montantTPS) + parseFloat(store.state.montantTVQ)).toFixed(2)

        };

        
        store.state.factureClient = facture.value;
        
       }else{
           // SI PAIEMENT PAR INTERNET
             facture.value = {
                    'dateCreated': new Date(),
                    'numFacture':  numFacture.toLowerCase(),
                    'transactionId' : session.id,
                    'montantTotal' : (session.amount_total/100).toFixed(2),
                    'customerId' : session.customer,
                    'prenom' : store.state.infosClient.prenom.toLowerCase(),
                    'nom' : store.state.infosClient.nom.toLowerCase(),
                    'courriel' : store.state.infosClient.courriel.toLowerCase(),
                    'telephoneMaison' : store.state.infosClient.telephoneMaison,
                    'mobile' : store.state.infosClient.mobile,
                    'numNomRue' : store.state.infosClient.numNomRue.toLowerCase(),
                    'ville' : store.state.infosClient.ville.toLowerCase(),
                    'codePostal' : store.state.infosClient.codePostal.toLowerCase(),
                    'emailTransaction' : session.customer_details.email.toLowerCase(),
                    'paymentMethod' : session.payment_method_types[0],
                    'itemsSold' : store.state.cart,
                    'methodeShipping' : store.state.infosClient.actionRecup,
                    'methodeRecuperation' : store.state.infosClient.methodeRecuperation,
                    'marchandiseDonnee': false,
                    'isDeleted': false,
                    'montantTPS': store.state.infosClient.montantTPS,
                    'montantTVQ': store.state.infosClient.montantTVQ,
                    'totalTaxes': (parseFloat(store.state.montantTPS) + parseFloat(store.state.montantTVQ)).toFixed(2)
        };
            store.state.factureClient = facture.value;
       }    
       
        // FINALISATION
         const res = await projectFirestore.collection("factures").add(store.state.factureClient); // SOUMET L'OBJET
            if(res){
                try{
                    const updateNum = await projectFirestore.collection('numfacture').doc('4WbBV8utXhOeNbxnJBXu').update({lastFacture: currentNumFacture.value})
                }catch(err){
                    console.log(err)
                }
          
                store.methods.clearCart() // FLUSH CART QUAND COMMANDE OK 
                store.methods.clearInfosClient(); // FLUSH INFO CLIENT
                store.state.infosClient = undefined;
         
            }else{
                console.log('une erreur est survenue');
            }

        generateEmail();
    }



    const onLoad = onMounted(()=>{
        // CHANGE BACKGROUND NAVBAR
        document.querySelector('#nav').classList.remove('split');
     
        // GET LAST NUM FACTURE OF
        var docRef = projectFirestore.collection("numfacture").doc('4WbBV8utXhOeNbxnJBXu');
        docRef.get().then((doc) => {
            if (doc.exists) {
                currentNumFacture.value = doc.data().lastFacture+1
                store.methods.getInfosClient(); // Get infos client

                if(idTransactionSuccess !== 'paiement-argent'){
                    // SI PAIEMENT PAR CARTE 
                    fetch('https://leserablieresbonneville.ca/checkout/chekout-session?id=' + idTransactionSuccess)
                    .then((response) => response.json())
                    .then((session) => {
                        checkoutResponse.value = JSON.stringify(session, null, 2);
                        generateFacture(session, 'S-'+createPaddingFacture(currentNumFacture.value)+currentNumFacture.value);
                        
                    })
                    .catch((error) => {
                        console.log('ERREUR: ', error);
                    })
                    }else{
                        generateFacture('paiement-argent', 'A-'+createPaddingFacture(currentNumFacture.value)+currentNumFacture.value); // si paiement argent passer la variable a generer facture
                    }
 

            } else {
                // doc.data() will be undefined in this case
                console.log('Un problem est survenue');
                
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
           
    })

    return {
        checkoutResponse,
        facture,
        store
    };
  },
};
</script>

<style scope>

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');



.boutique-success{
    min-height: calc(100vh - 290px);
    width: 100%;
    background-color: #f5f2f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9B552B;
    font-family: "Lato", sans-serif;
}

.carre-merci{
    
    width: 85%;
    max-width: 1445px;
    height: 300px;
    border: 1px  solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carre-merci h1{
    font-size: 6em;
    font-family: "Lato", sans-serif;
}

.carre-merci p{
    font-size: 1em;
    text-transform: uppercase;
}



@media (max-width: 600px) {

.boutique-success{
    min-height: calc(100vh - 265px);

}


.carre-merci{
    padding: 20px;
    margin: 20px;
}



.carre-merci h1{
    font-size: 4em;
    font-family: 'Libre Baskerville', serif;
}

.carre-merci p{
    font-size: 0.8em;
    text-transform: uppercase;
}




}


</style>