
<template>
        <div class="menu-mobile">
        <ul class="menu-mobile-container">
                <li @click="handleMenuMobile('Accueil')">ACCUEIL</li>
                <li @click="handleMenuMobile('Apropos')">A PROPOS</li>
                <li @click="handleMenuMobile('Boutique')">BOUTIQUE</li>
                <li @click="handleMenuMobile('Certification')">CERTIFICATION</li>
                <li @click="handleMenuMobile('Contact')">CONTACT</li>
                <li @click="handleMenuMobile('Panier')" id="cart-menu-mobile"><img src="../../../public/images/cart_mobile.svg" alt="Panier achat en ligne Les Erablieres Bonneville" class="cart-img-mobile"><span v-if="store.state.numberItemsInCart !== 0" class="badgeMobile">{{store.state.numberItemsInCart}}</span></li>
            </ul>
        </div>
</template>

<script>

import { useRouter } from "vue-router";
import { inject } from 'vue';

export default {
    name : 'MenuMobile',
    setup(){

        const route = useRouter();
        const store = inject('store');

        const handleMenuMobile = (link) => {
        const body = document.querySelector('body');
        if(!store.state.menuMobileView){
            body.classList.add('no-scroll'); // ENLEVE LE SCROLL 
        }else{
            body.classList.remove('no-scroll'); // remet LE SCROLL
        }
        store.state.menuMobileView = !store.state.menuMobileView;
        route.push({ name: link });
        }

  
        return{
          handleMenuMobile, store
        }
    
}}
</script>

<style>

.menu-mobile{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 80px;
    height: 100vh;
    width: calc(100vw - 80px);
    z-index: 1100;
    background-color: #9B552B;
}

#cart-menu-mobile{
  position: relative;
}

.cart-img-mobile{
    padding-top: 10px;
}


.menu-mobile-container {
    position: relative;
}

.menu-mobile ul {
    list-style: none;
    position: absolute;
    top: 80px;
    left: 20px;
}


.menu-mobile ul li{
    color: #D6D1C4;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding: 10px;
    cursor: pointer;
}

.badgeMobile{
   
    position: absolute;
    top: 10px;
    left: 25px;
    font: 15px;
    font-family: "Lato", sans-serif;
    border-radius: 50%;
    background-color:#ffffff ;
    color:#9b552b;
    font-weight: bold;
    width: 2em;
    height: 2em;
    box-sizing: initial;
    text-align: center;
    border-radius: 50%;    
    line-height: 2em;
    opacity: 0.9;
    box-sizing: content-box;  
}


.menu-mobile {
  animation: show-menu .3s ease-out reverse;
}
@keyframes show-menu {
  0% {
    transform:translateX(0);
  
  }

  50% {
     transform:translateX(-80vw);
  }

  100% {
    transform:translateX(-100vw);


  }
}





</style>