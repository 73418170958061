<template>
  <div class="header d-flex justify-content-between">
        <!-- BOUTON TOGGLE -->
        <a href="#menu-toggle" class="btn btn-primary" id="menu-toggle" @click.prevent="toggleSideNav()"><span class="material-icons">compare_arrows</span></a>
        <!-- LOGIN INFO -->
        <div class="user_container">
        <h5>Les Érablières Bonneville</h5><button @click="signOut()" class="btn btn-secondary">Se déconnecter</button>
        </div>
  </div>

  
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';
import { useRouter } from "vue-router";



export default {
    name : 'HeaderGestion',
    setup(){  

   const route = useRouter();


       // FONCTION TEMPLATE
       // SIDEBAR
        const toggleSideNav = () => {
            document.querySelector('#wrapper').classList.toggle('toggled');
            if (document.querySelector('.links_sidenav_inventaire').style.display === "none") {
              setTimeout( () => {
                  document.querySelector('.links_sidenav_inventaire').style.display = "block";
              }, 300);
            } else {
              document.querySelector('.links_sidenav_inventaire').style.display = "none";
            }
        }


      

        const signOut = () => {
          firebase.auth().signOut().then(() => {
                route.push({ name: 'Login' });
          }).catch((error) => {
            console.log(error)
          });

        }



        return{
          toggleSideNav,
          signOut
        }
    }

}
</script>

<style>

.user_container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user_container h5{
padding-right: 20px;
padding-top: 5px;
}

.header{
  display: flex;
  align-items: center;
  height: 50px;
  width:100%;

}

#menu-toggle{
  display: flex;
  align-items: center;
}


</style>


