<template>
<footer>
    <div class="footer-contenu">
        <div class="logo-erabliere-footer">
            <img src="../../public/images/logo_erablioeres_bonneville_footer.svg" class="img-fluid">
        </div>
        <div class="footer-adresse">
            <p><span style="text-transform: uppercase">St-Sylvère</span> <br class="mob"><span style="font-weight: normal">(bureau coordonnateur)</span><br />
            1315 Route du 10eme Rang,<br />
            St-Sylvère, G0Z 1H0</p>
        </div>

    </div>

</footer>

</template>

<script>


export default {
    name : 'TheFooter',
    setup(){

      
        
  
    
        return{
          
        }
    
}}
</script>

<style scope>

footer{
    
    min-height: 130px;
    display:grid;
    grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
    grid-template-areas:
      ". footer-contenu .";
      background-color: #222222;
}

.footer-contenu{
    height: 100%;
    width: 100%;
    grid-area: footer-contenu;
    display: flex;
    justify-content: space-between;
    padding: 20px;
 
    
}

.footer-adresse{
    padding-top: 13px;
    width: 215px;
    
}


.footer-adresse p {

    font-size: 13px;
    font-weight: bold;
    color: #9b552b !important;
    line-height: 20px;
}


.mob{
    display: none;
}

.logo-erabliere-footer{
    width: 200px;
    display: flex;
    justify-content: flex-start;

  
}


@media (max-width: 600px) {

.footer-contenu{
padding: 10px 20px;
    
}



.footer-adresse p {

    font-size: 9px;
    font-weight: bold;
    color: #9b552b !important;
    line-height: 20px;
}

.logo-erabliere-footer img{
    width: 80%;
 

}

.mob{
    display: block;
}

.footer-adresse{
    padding-top: 13px;
    width: 40%;
}

}

 



</style>