<template>
<TheNavbar />
<main class="accueil">
  <!-- CARRE BRUN HAUT GAUCHE -->
  <div class="accueil-intro-left">
    <div class="titrage-accueil">
        <h1>Les Érablières <span class="no-mob"><br/></span>Bonneville <span class="no-mob">:</span></h1> 
        <h2>L’érable a son meilleur!</h2>
        <router-link to="/boutique">
        <button class="bouton-lien-boutique">Découvrez nos produits</button>
        </router-link>
      </div>
  </div>
  <!-- TITRAGE HAUT GAUCHE -->
 
  <!-- PHOTO SIROP -->
  <div class="accueil-intro-right">
   
  </div>

  <div class="accueil-sous-image">
      <div class="accueil-separation-sous-image"></div>
  </div>
  <div class="accueil-carre-brun"></div>
  <div class="bouton-mobile-apropos mob">
    <router-link to="/apropos">
    <button class="bouton-lien-apropos">En apprendre d'avantage</button>
    </router-link>
  </div>
  <div class="photo-daniel marge-gauche">
    
  </div>
  <!-- TEXTE ENTREPRENEUR -->
  <div class="texte-entrepreneur marge-droite">
    <div class="wrapper-texte-entrepreneur">
    <h3 class="titre-entrepreneur pt-4 mt-md-0">L’entrepreneur</h3>
    <h2 class="slogan-entrepreneur">Un sirop, un goût <br />exceptionnel et distinctif</h2>
    <p class="contenu-entrepreneur">La simplicité est aussi caractéristique de nos érablières. Daniel aime travailler dans la simplicité et la bonne humeur et ses employés sont 
      consultés et écoutés. </p>
      <p class="contenu-entrepreneur">Le propriétaire vise la perfection de son sirop d’érable, dont le bon gout raffiné et délicat est reconnu par ses acheteurs et clients.
      </p>
        <router-link to="/apropos">
    <button  class="bouton-lien-boutique mt-5 no-mob">En apprendre d'avantage</button>
      </router-link>
    </div>
  </div>
  <div class="accueil-boutique-titre marge-gauche marge-droite">
    <div class="boutique-titre-separation">
      <h4>notre<span class="no-mob"><br /></span>
      boutique</h4>
      <div class="accueil-separation-boutique"></div>
    </div>
    <div class="accueil-separation no-mob"></div>
  </div>
     <div class="boutique-preview marge-gauche marge-droite" v-if="produits">
 
 <vueper-slides
  class="no-shadow no-mob"
  :visible-slides="3"
  :slide-multiple="true"
  :gap="10"
  :arrows-outside="true"
  :infinite="true"

  :breakpoints="{ 950: { visibleSlides: 2, fixedHeight: '440px' } }">
  <vueper-slide v-for="(item, index) in produits" :key="index">
    <template v-slot:content>
      <div class="produit-preview-boutique">
        <router-link :to="`${adresseActuelle}boutique/${item.lienProduit}`">
          <img class="imageproduit" :src="item.imageHighRes" :alt="item.nom" />
          <h3 class="mt-3 text-uppercase">{{ item.nom }}</h3>
        </router-link>
      </div>
    </template>
  </vueper-slide>
</vueper-slides>

<div class="accueil-boutique-mobile mob">
<div v-for="( item, index ) in produits" :key="index" >
<div v-if="index == 0 || index == 2 || index == 4" class="wrapper-accueil-produit-mobile">
  <router-link :to="`${adresseActuelle}boutique/${item.lienProduit}`">

    <div class="accueil-produit-mobile" >
      <div class="produit-mobile-tagimage" :style="{ backgroundImage: 'url(' + item.imageHighRes + ')'}">
      </div>
        <div class="accueil-infos-produit">
          <div>

            <h3 class="mt-3 text-uppercase">{{ item.nom }}</h3>
            <p v-if="item.multiFormat">Plusieurs formats</p>

          </div>
        </div>
      </div>
    
  </router-link>
 </div>
</div>
<router-link to="/boutique">
<h3>VOIR TOUS NOS PRODUITS</h3>
</router-link>
</div>


</div>

</main>
<BandeauContact class="bandeau" />
<div id="map">

</div>
<TheFooter />

</template>

<script>
import TheNavbar from '../components/TheNavbar';
import TheFooter from '../components/TheFooter';
import getProduits from '../composables/getProduits';
import BandeauContact from '../components/Accueil/BandeauContact';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';
import {useRouter, useRoute} from 'vue-router';
import { onMounted } from 'vue';
import { gsap } from 'gsap';



export default {
  name: 'Accueil',
  components: {VueperSlides, VueperSlide, BandeauContact, TheNavbar, TheFooter},

  setup(){
 
     


     const startAnim = () => {
          gsap.from(".titrage-accueil h1", {x:+100, opacity: 0, duration: 0.6, delay: 0.2});
          gsap.from(".titrage-accueil h2", {opacity: 0, duration: 1, delay: 0.3});
          gsap.from(".bouton-lien-boutique", { opacity: 0, duration: 0.2, delay: 0.3}); 
      }


    // BOUTIQUE POUR ALLER DANS BOUTIQUE
    const route = useRoute();
    const adresseActuelle = route.path;
    const router = useRouter();
  

    const goToProduit = (lienProduit) => {
      router.push({path: `/boutique/${lienProduit}`});
    }
   

    const pushToProduit = (lien) => {
      router.push({path: `/boutique/${lien}`});
    }


  // GET PRODUITS
    const { produits, error, load } = getProduits();
    load();
    

 
const onLoad = onMounted(()=>{
        // DECLANCHER ANIMATIONS DEBUTS
        startAnim();


        var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        mapboxgl.accessToken = 'pk.eyJ1IjoiZXJhYmxpZXJlc2Jvbm5ldmlsZSIsImEiOiJja284dzNieWMyMDQ1Mm9scmNnb3ptcGF0In0.d6RBeqMU5hlA3tlz2DXtiw';
        var map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-72.2267, 46.4333], // starting position [lng, lat]
        zoom: 8
        });
        map.on('load', function () {
        // Add an image to use as a custom marker
        map.loadImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        function (error, image) {
        if (error) throw error;
        map.addImage('custom-marker', image);
        // Add a GeoJSON source with 2 points
        map.addSource('points', {
        'type': 'geojson',
        'data': {
        'type': 'FeatureCollection',
        'features': [
        {
        // feature for Mapbox DC
        'type': 'Feature',
        'geometry': {
        'type': 'Point',
        'coordinates': [
        -72.20750198785557, 46.20088614305213
        ]
        },
        'properties': {
        'title': 'St-Sylvère'
        }
        },
        {
        // feature for Mapbox SF
        'type': 'Feature',
        'geometry': {
        'type': 'Point',
        'coordinates': [-72.50515576706894, 46.80002968707714]
        
        },
        'properties': {
        'title': 'Ste-Thècle'
        }
        }
        ]
        }
        });

        // Add a symbol layer
        map.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        'layout': {
        'icon-image': 'custom-marker',
        // get the title name from the source's "title" property
        'text-field': ['get', 'title'],
        'text-font': [
        'Open Sans Semibold',
        'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 1.25],
        'text-anchor': 'top'
        }
        });
        }
        );
        });

     })

    return{
      produits, 
      error, 
      load,
      adresseActuelle,
      pushToProduit,
      goToProduit,
      onLoad
   
  
    }
  }
}
</script>

<style>
.landmark {
            width: 250px;
            padding: 7px 0 0 0;
            background: rgba(247, 247, 247, 0.75);
            border-radius: 5px;
            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
            font-family: Helvetica, Arial, sans-serif;
            -webkit-transform-origin: 0 10px;
            transform-origin: 0 10px;
        }
        
        .landmark h1 {
            margin-top: 0;
            padding: 5px 15px;
            background: #2aaef5;
            color: rgba(255, 255, 255, 0.9);
            font-size: 16px;
            font-weight: 300;
        }
        
        .landmark section {
            padding: 0 15px 5px;
            font-size: 14px;
        }
        
        .landmark section p {
            margin: 5px 0;
        }
        
        .landmark:after {
            content: "";
            position: absolute;
            top: 7px;
            left: -13px;
            width: 0;
            height: 0;
            margin-bottom: -13px;
            border-right: 13px solid #2aaef5;
            border-top: 13px solid rgba(0, 0, 0, 0);
            border-bottom: 13px solid rgba(0, 0, 0, 0);
        }
        
        @-webkit-keyframes scale-and-fadein {
            0% {
                -webkit-transform: scale(0.2);
                opacity: 0;
            }
        
            100% {
                -webkit-transform: scale(1);
                opacity: 1;
            }
        }
        
        @keyframes scale-and-fadein {
            0% {
                transform: scale(0.2);
                opacity: 0;
            }
        
            100% {
                transform: scale(1);
                opacity: 1;
            }
        }


.accueil{
    background: linear-gradient(90deg, #ffffff 50%, rgba(214, 209, 196, 0.3) 50%);
    display: grid;
    grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
    grid-auto-rows: 100px;
}

.accueil-intro-left{
  grid-row-start: 1;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 9;
  background-color: #9b552b;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content:center;
}

.titrage-accueil{
  
  margin:auto;
  padding-left:10px;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  z-index: 11;
}


.accueil-intro-right{
  margin-top: -50px;
  grid-row-start: 2;
  grid-row-end: 7;
  grid-column-start: 5;
  grid-column-end: 13;
  margin-left: 50px;
  z-index: 20;
  background-image: url(../../public/images/erablieres_bonneville_sirop_erable.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
}

.titrage-accueil h1{

  letter-spacing: 1.53px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 34px;
  color: #FFFFFF;
  
}

.titrage-accueil h2{

  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-style: italic;
  letter-spacing: 1.35px;
  color: #FFFFFF;
}

.bouton-lien-boutique{
 
  margin-top: 20px;
  max-width: 252px;
  background: #222222;
  border-radius: 13px;
  padding: 13px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.58px;
  color: #D6D1C4;
  border: none;

}


.photo-daniel{
  
  grid-row-start: 9;
  grid-row-end: 17;
  grid-column-start: 2;
  grid-column-end: 8;
  background-image: url("../../public/images/daniel_bonneville.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 50px;
}


.texte-entrepreneur{
  
  grid-row-start: 9;
  grid-row-end: 17;
  grid-column-start: 8;
  grid-column-end: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titre-entrepreneur{
  
  margin-bottom:  55px;
  color: #222222;
  letter-spacing: 0.68px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
}

.slogan-entrepreneur{

  margin-bottom: 64px;
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1.35px;
  color: #9B552B;
  text-transform: uppercase;

}

.contenu-entrepreneur{

  max-width: 420px;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #222222;

}

.accueil-boutique-titre{

  grid-row-start: 18;
  grid-row-end: 22;
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  justify-content: space-between;
}

.boutique-titre-separation h4{

  font-weight: bold;
  text-align: left;
  font-size: 30px;
  letter-spacing: 1.35px;
  color: #9B552B;
  text-transform: uppercase;
}


.accueil-separation{

  width: 85%;
  margin: 35px 0 0 20px;
  border-top: 1px solid #222222;
  text-align: right;
}

.boutique-preview{

  grid-row-start: 19;
  grid-row-end: 25;
  grid-column-start: 2;
  grid-column-end: 12;

}

.produit-preview-boutique{

width: 100%;

}

.vueperslides__arrows{
  position: absolute;
  top: -30px;
  right: 20px;
  transform: scale(0.7);
}

.marge-gauche{
  margin-left: 20px;
}

.marge-droite{
  margin-right: 20px;
}

.bandeau{
  grid-row-start: 19;
  grid-row-end: 25;
  grid-column-start: 0;
  grid-column-end: 13;
}



.accueil-separation-boutique,
.accueil-carre-brun,
.accueil-separation-sous-image,
.accueil-sous-image{
  display: none;
}

.carte-erablieres{
  grid-column-start: 2;
  grid-column-end: 12;
}

#map{
    border: 0;
    width:100%;
    height: 500px;
    filter: grayscale(100%);
}

.produit-mobile-tagimage{
z-index: 100;
}


@media (min-width: 1500px) {



.titrage-accueil{
  
  margin-left: calc(50vw - 722px);

}

}





@media (max-width: 1370px) {

.titrage-accueil{
  padding-left:0px;
}

  .titrage-accueil h1{
    font-size: 30px;
  }

  .titrage-accueil h2{
    font-size: 26px;
  }

  .accueil-intro-left{
    grid-row-end: 5;
  }


  .accueil-intro-right{
    grid-row-end: 6;
  }

   .photo-daniel,
   .texte-entrepreneur{

  grid-row-start: 7;
  grid-row-end: 15;
 
}

.boutique-preview{

  grid-row-start: 17;
  grid-row-end: 22;


}

.accueil-boutique-titre{

  grid-row-start: 16;
  grid-row-end: 20;

}





}

@media (max-width: 1160px) {
  .titrage-accueil h1{
    font-size: 26px;
  }

  .titrage-accueil h2{
    font-size: 22px;
  }

  .accueil-intro-left{
    grid-row-end: 5;
  }


  .accueil-intro-right{
    grid-row-end: 6;
  }




.slogan-entrepreneur{
  font-size: 24px;
}
}
@media (max-width: 1040px) {
  
.slogan-entrepreneur{
  font-size: 24px;
}


  .boutique-preview{


  grid-row-end: 21;


}

}



@media (max-width: 950px) {

.accueil-intro-left{
  grid-row-end: 4;
  grid-column-end: 8;
}


.accueil-intro-right{
  grid-row-end: 5;
  grid-column-start: 6;
  margin-left: -20px;
}


.slogan-entrepreneur{
  font-size: 24px;
}

.bouton-lien-boutique{
  max-width: 222px;
  padding: 13px;
}

.boutique-preview{


  grid-row-end: 23;

}



}

@media (max-width: 889px) {
 .accueil-intro-right{

  margin-top: 0px;
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 13;
  margin-left: 0;

}


.accueil-intro-left{
  margin-bottom: 0;
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 13;
  background-color: transparent;
  z-index: 50;

  display: flex;
  flex-direction: column;
  justify-content:center;
}

.titrage-accueil{

  width: 100%;
 
  align-items: center;
  z-index: 11;
}


.titrage-accueil h1{

  padding-top: 30px;
  letter-spacing: 0.81px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 24px;

  
}

.titrage-accueil h2{

  text-align: center;
  font-size: 22px;
  font-style: italic;
  letter-spacing: 0.72px;
 
}

.bouton-lien-boutique{
 
  margin-top: 60px;
  max-width: 260px;
  background: #222222;
  border-radius: 13px;
  padding: 13px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.58px;
  color: #D6D1C4;
  border: none;

}

.accueil{
  background: none;
}

.accueil-sous-image {
    display: block;
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end:13;
    width: 100%;
    min-height: 41px;
    max-height: 41px;
    background: linear-gradient(90deg, hsl(36, 48%, 41%) 50%, #9b552b 50%);
    position: relative;
  }

.accueil-separation-sous-image {
  display: block;
  position: absolute;
  bottom: 0;
  left: 20px;
  min-width: 100px;
  border-top: 1px solid #9B552B;
  text-align: right;
}

.accueil-separation-boutique{
 
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100px;
  border-top: 1px solid #9B552B;
  text-align: right;

}


.texte-entrepreneur{
    margin-top: -40px;
    grid-row-start: 6;
    grid-row-end: 8;
    grid-column-start: 1;
    grid-column-end:13;
    padding: 0 20px; 
}

.texte-entrepreneur h2{
  display: none;
}
.contenu-entrepreneur{
  max-width: 100%;
}

.titre-entrepreneur{
  margin-bottom:  25px;

}


.accueil-carre-brun{
  display: block;
  background-color: #9b552b;
  grid-row-start: 10;
  grid-row-end: 14;
  grid-column-start: 1;
  grid-column-end:7;
}


.photo-daniel{

  grid-row-start: 8;
  grid-row-end: 13;
  grid-column-start: 3;
  grid-column-end: 11;
  margin-right: 0;
}


.bouton-mobile-apropos{
  grid-row-start: 13;
  grid-row-end: 14;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  justify-content: center;


}

.bouton-lien-apropos{
 
  margin-top: 20px;
  max-width: 260px;
  background: #222222;
  border-radius: 13px;
  padding: 13px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.58px;
  color: #D6D1C4;
  border: none;
  height: 50px;


}


.accueil-boutique-titre{
  margin-top: 45px;
  grid-row-start: 14;
  grid-row-end: 20;
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  justify-content: space-between;
}

.boutique-titre-separation h4{
  padding-top: 30px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.68px;
  text-transform: uppercase;
  color: #000000;

}

.boutique-titre-separation{
  position: relative;
}


.accueil-boutique-mobile{

  display: flex;
  flex-direction: column;

}


.wrapper-accueil-produit-mobile{
  width: 100%;
 background-color: #f5f3f0;
}

.boutique-preview{
  margin-top: 20px;
  grid-row-start: 15;
  grid-row-end: 30;
  grid-column-start: 2;
  grid-column-end: 12;

}

.accueil-produit-mobile{
  height: calc(100vw / 2);
  width: 100%;
  display: flex;
  justify-content:space-between;
 
}

.produit-mobile-tagimage{
  border-top-left-radius: 20px 20px;
  width: 50%;
  background-size: cover;
  background-position: center;
}

.accueil-infos-produit{
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border-bottom-right-radius: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accueil-infos-produit h3{
  max-width:140px;
  color: #222222;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 50px;
  margin:auto;
}

 .accueil-infos-produit p{
    font-size: 18px;
    font-family: "Lato", sans-serif;
    color: #9B552B;
  }

  .accueil-boutique-mobile h3{
    padding-top : 30px;
  }

}


@media only screen and (max-width: 800px){

.boutique-preview{

  grid-row-end: 29;


}





}

@media only screen and (max-width: 750px){

.boutique-preview{

  grid-row-end: 28;


}
#map{
  display: none;
}
}
@media only screen and (max-width: 680px){



.boutique-preview{
  grid-row-end: 27;
}




}

@media only screen and (max-width: 620px){

.boutique-preview{

  grid-row-end: 26;


}

}
@media only screen and (max-width: 600px){

  .accueil-infos-produit p{
    font-size: 13px;

  }

  .accueil-infos-produit h3{
  max-width:110px;
  color: #222222;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 30px;
}

  .boutique-preview{
  grid-row-start: 12;
  grid-row-end: 23;

}


  .accueil-sous-image {

    grid-row-start: 4;
    grid-row-end: 5;
    margin-top: -34px;
  }
 

 .accueil-intro-right{
  margin-bottom: 33px;
  grid-row-end: 4;

}


.accueil-intro-left{
  margin-bottom: 33px;
  grid-row-end: 4;

}



.titrage-accueil h1{

  padding-top: 60px;
  font-size: 18px;

}

.titrage-accueil h2{

  font-size: 16px;


}

.bouton-lien-boutique{
 
  margin-top: 50px;
  max-width: 220px;
  font-size: 10px;
  margin-left: 12px;

}


.texte-entrepreneur{
    margin-top: 20px;
    grid-row-start: 5;
    grid-row-end: 6;
}


.accueil-carre-brun{

  grid-row-start: 8;
  grid-row-end: 11;

}


.photo-daniel{

  grid-row-start: 7;
  grid-row-end: 10;
  grid-column-start: 3;
  grid-column-end: 11;
  margin-right: 0;

}


.bouton-mobile-apropos{
  grid-row-start: 10;
  grid-row-end: 11;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  justify-content: center;
  min-height: 40px;
  max-height:40px;

}


.bouton-lien-apropos{
 
  margin-top: 20px;
  max-width: 220px;
  font-size: 10px;
  height: 40px;

}


.accueil-boutique-titre{
  margin-top: 35px;

  grid-row-start: 11;
  grid-row-end: 20;
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  justify-content: space-between;
}





}

@media only screen and (max-width: 550px){
.boutique-preview{
grid-row-end: 22;
}

}

@media only screen and (max-width: 480px){
.boutique-preview{
grid-row-end: 21;
}

}

@media only screen and (max-width: 410px){
.boutique-preview{
grid-row-end: 20;
}

}
@media only screen and (max-width: 350px){
.boutique-preview{
grid-row-end: 19;
}
}

@media only screen and (max-width: 319px){
.boutique-preview{
grid-row-end: 17;
}

}


</style>
