import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getFactures = () => {
    const factures = ref([]) 

    const errorFacture = ref(null)


    const loadFacture = async () => {
        try {
            const res = await projectFirestore.collection('factures').get()
            res.docs.forEach( doc => {
                if(doc.data().isDeleted === false){ // SELECTIONNE SEULEMENT LES PRODUITS NON DELETÉ
                     factures.value.push({...doc.data(), factureId: doc.id})
                }
             })
        }
        catch(err) {
            error.value = err.message
            console.log(error.value)
        }
        // SORT FACTURE EN ORDRE DE DATE DECROISSANT
        factures.value.sort((a, b) => {
            return b.dateCreated - a.dateCreated
        })
        
    }
  
    return { factures, errorFacture, loadFacture}
}

export default getFactures;



