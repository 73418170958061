<template>
<nav id="nav" class="split">
    <div class="navbar-contenu">
        <div class="hamburger">
            <div id="nav-icon1" @click="handleMenuMobile()">
                <span></span>
                <span></span>
                <span></span>
            </div>

        </div>
        <div class="nav-links no-mob">
            <router-link to="/apropos">à propos</router-link>
        </div>
        <div class="nav-links no-mob">
            <router-link to="/boutique">boutique</router-link>
        </div>
        <div class="logo-nav">
             <router-link to="/">
              <img src="../../public/images/logo_erablieres_bonneville.png" alt="Les Erablieres Bonneville" class="logo-nav-image">
             </router-link>
        </div>

        <div class="nav-links no-mob">
             <router-link to="/certification">certification</router-link>
        </div>
        <div class="nav-links no-mob">
            <router-link to="/contact">contact</router-link>
        </div>

        <div class="nav-cart">
            <router-link to="/boutique/panier">
                <img src="../../public/images/cart.svg" alt="Panier achat en ligne Les Erablieres Bonneville" class="cart-img">
                <div v-if="store.state.numberItemsInCart !== 0" class="theBadge">{{store.state.numberItemsInCart}}</div>
            </router-link>
        </div>

    </div>
  
</nav>

<transition name="overlay">
    <SlideMobile v-if="store.state.menuMobileView" />
</transition>


   <transition name="menu-mobile-on">
    <MenuMobile v-if="store.state.menuMobileView"/>

  </transition>
<transition name="bounce">
    <PopCart v-if="store.state.showCart > 0" /> 
</transition>
</template>

<script>


import PopCart from '../components/Boutique/Cart/PopCart';
import SlideMobile from '../components/TheNavbar/SlideMobile';
import MenuMobile from '../components/TheNavbar/MenuMobile';
// import { gsap } from "gsap";

import { inject, onMounted} from 'vue';

export default {
    name : 'TheNavbar',
    
    components:{PopCart, SlideMobile, MenuMobile},
    setup(){
 
        const store = inject('store');
  

        const onLoad = onMounted(()=>{
            store.methods.checkIfCart();
        });

        

        // CHANGE FOND SI DANS ACCUEIL CERTIFICATIONS OU A PROPOS
    

        // watchEffect(() => { // effect on add

        //             if(store.state.numberItemsInCart !== 0 ){
        //                 console.log('check badge')
        //                 const tl = gsap.timeline();
        //                 tl.to(".theBadge", {duration: 0.3, scale: 1.05 }) 
        //                 .to(".theBadge", {duration:0.3, scale: 0.95 })
        //     };

    
        



        // });

      
       


        const handleMenuMobile = () => {

            document.querySelector('#nav-icon1').classList.toggle('open');
            const body = document.querySelector('body');
            
            if(!store.state.menuMobileView){
                body.classList.add('no-scroll'); // ENLEVE LE SCROLL 
            }else{
                body.classList.remove('no-scroll'); // remet LE SCROLL
            }
            store.state.menuMobileView = !store.state.menuMobileView;
            
        }

       
  
    
        return{

            handleMenuMobile,
            store
         
            
        }
    
}}
</script>

<style scope>

.hamburger{
    width: 58px;
}

nav{
    z-index: 500;
    height: 160px;
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
    grid-template-areas:
      ". navbar-contenu .";

}


/* MENU HAMBURGER  */
.hamburger{
    transform: scale(0.5);
}

#nav-icon1 {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #9b552b;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.navbar-contenu{
    height: 100%;
    width: 100%;
    grid-area: navbar-contenu;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 70px;
  
}

.nav-links{ 
    font-size: 15px;
    text-transform: uppercase;
}

.nav-links a, .nav-links a:hover {
    color: #9b552b;
    font-weight: bold;
    letter-spacing: 1.13px;
}

.split{
     background: linear-gradient(90deg, #ffffff 50%, #f5f3f0  50%);
}

.logo-nav{
    padding-left: 35px;
}

.logo-nav-image{
    height:120px;
}

.nav-cart{
    position: relative;
}



.theBadge{
   
    position: absolute;
    top: -12px;
    left: 16px;
    font: 14px Arial, sans-serif;
    border-radius: 50%;
    background-color: #9b552b;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    width: 2em;
    height: 2em;
    box-sizing: initial;
    text-align: center;
    border-radius: 50%;    
    line-height: 2em;
    box-sizing: content-box;  

}



.hamburger{
    visibility: hidden;

}

/* ======== VERSION PETITE TABLETTE / MOBILE ======== */

@media (max-width: 888px) {

nav{
    height: 80px;
}


.split{
     background: none;
     background-color: #f5f3f0;
}

.nav-cart{
    visibility: hidden;
}

.navbar-contenu{
    flex-direction: row-reverse;
    padding:  10px 20px 20px 0;
}

.hamburger{
    margin-top: 0px;
    visibility: visible;
    width: 40px;
}


.logo-nav{
    padding-left: 0;
}

.logo-nav-image{
    height:60px;
    margin-top: 0px;
    padding-left: 5px;
}


.no-mob{
    display:none;
}



.logo-nav{
    margin-left: 25px;
    
}

.cart-img{
    width: 70%;
    padding-bottom: 10px;
}



}


 
.overlay {
  animation: overlay-appear 1s;
}
.overlay {
  animation: overlay-appear 1s reverse;
}

@keyframes overlay-appear {
  0% {
    transform:translateX(-320px);
    opacity: 0;
  }
  50% {
    transform:translateX(40px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}




</style>