import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "les-erablieres-bonnevilles.firebaseapp.com",
    projectId: "les-erablieres-bonnevilles",
    storageBucket: "les-erablieres-bonnevilles.appspot.com",
    messagingSenderId: "823964597115",
    appId: "1:823964597115:web:0c1f77081ee102dc0e1bde",
    measurementId: "G-1SNCTQQ5J8"
  };

  // INIT FIREBASE

  firebase.initializeApp(firebaseConfig)
  
  // INIT FIRESTORE SERVICE

  const projectFirestore = firebase.firestore()

  export { projectFirestore }
