const listeDescriptions = () => {
    const liste = [{
        lien: 'Pour l’aménagement et l’entretien de l’érablière',
        shortcut: 'amenagement',
        description: "Les pratiques d’aménagement doivent être respectueuses de l’érablière. Les essences compagnes de l’érable a sucre doivent représenter au moins 15% du bois de l’érablière. Nous pouvons faire des éclaircies, mais pas de débroussaillage complet."
    },
    {
        lien: 'Collecte de l’eau d’érable',
        shortcut: 'collecte-eau',
        description: 'Nous devons suivre des normes d’entaillage précises. Pas d’entaillage sur les érables plus petits, de 20 cm et moins. Une entaille pour ceux de 20 à 40 cm, deux entailles pour 40 a 60 cm et trois entailles pour 60 cm et plus. L’alcool éthylique est le seul produit permis lors de l’entaillage. Le désentaillage est obligatoire immédiatement après la récolte de l’eau d’érable. Tout l’équipement venant en contact avec l’eau est compatible avec les produits alimentaires. '
    },
    {
        lien: 'Traitement de l’eau d’érable',
        shortcut: 'traitement-eau',
        description: 'Nous utilisons le traitement par osmose inversée pour concentrer le sucre de l’eau d’érable.  Nous pouvons filtrer l’eau. Les casseroles de l’évaporateur doivent être en acier inoxydables.  Nous utilisons un agent anti-mousse permis ( de l\'huile de tournesol désodorisé ), pour éviter les débordements. Les barils sont principalement en acier inoxydables.'
    },
    {
        lien: 'Le nettoyage de l’équipement',
        shortcut: 'nettoyage',
        description: 'Ils doivent être rincés après l’assainissement. Les membranes d’osmose sont nettoyées avec de la soude caustique quand l’efficacité de la machine diminue en bas de 85% et les casseroles de l’évaporateur nettoyés a tous les jours avec le filtrat de l’eau d’érable.'
    },
    {
        lien: 'Registres',
        shortcut: 'registre',
        description: 'Nous devons tenir des registres de ce que nous faisons sur l’érablière, autant au niveau des osmoses que des produits ( quantité, format, couleur ),  .Toutes nos opérations sont inscrites et transmises a Ecocert . Nous devons avoir une entrevue et les documents a fournir, papiers et photos a chaque année afin de renouveler notre certification. Nos étiquettes doivent aussi suivre des normes précises.'
    }
   ]

    return liste
}

export default listeDescriptions