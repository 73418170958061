<template>
<TheNavbar />
  <TheHeader />
  <div class="container-fluid p-0 produit_container mt-0 mt-lg-5 ">
    <div v-if="produit" class="produit">
      <div class="row">
        <!-- IMAGE PRINCIPALE -->
        <div class="col-md-6 imgPrincipale p-0 order-md-1">
          <div v-if="!produit.multiFormat || selectedFormat == 0" class="image-principale-container">
          <img
            :src="produit.imageHighRes"
            class="img-fluid produit-image-principale"
            id="selectedImg"
            :alt="produit.nom"
          />
          </div>
          <div v-if="selectedFormat == 1" class="image-principale-container">
          <img
      
            :src="produit.imageFormat2HighRes"
            class="img-fluid"
            id="selectedImg"
            :alt="produit.nom"
          />
          </div>
          <div v-if="selectedFormat == 2" class="image-principale-container">
          <img
           
            :src="produit.imageFormat3HighRes"
            class="img-fluid"
            id="selectedImg"
            :alt="produit.nom"
          />
          </div>
          <div v-if="selectedFormat == 3" class="image-principale-container">
          <img
    
            :src="produit.imageFormat4HighRes"
            class="img-fluid"
            id="selectedImg"
            :alt="produit.nom"
          />
          </div>
        </div>
        <!-- IMAGES SECONDAIRES -->
        <div class="col-md-6 p-0 order-md-3 imagesSecContainer">
          <div class="imagesSecWrapper">
            <div class="wrapper-sec">

              <img
                :src="produit.imageSec1Thumb"
                class="img-fluid imgSec imgSec1"
                :alt="produit.nom"
                
              />
         
            
              <img
                :src="produit.imageSec2Thumb"
                class="img-fluid imgSec"
                :alt="produit.nom"
                
              />
            </div>
       
            
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="col-md-6 order-md-2 pl-md-2 pl-lg-0  description">
          <div class="row">
            <div class="col-12 nom-wrapper">
              <h1 class="nom-produit">{{ produit.nom }}</h1>
            </div>
            <div class="row">
              <div class="col-12  mt-3">
                <h2 class="sous-titre-produit">
                  {{ produit.soustitreProduit }}
                </h2>
              </div>
            </div>
            <div class="row desc-wrapper">
              <div class="col-12  mt-3">
                <p class="description-produit">{{ produit.description }}</p>
              </div>
            </div>
            <div class="row formats-choix-produit">
              <div class="formats-produit">
                <h5 v-if="produit.multiFormat" class="format-ecriture">
                  Formats
                </h5>
                <h5 v-else class="format-ecriture">Format</h5>
                <div class="formats">
                  <div @click="select(0)">
                    <img
                      :src="produit.imageThumb"
                      class="img-format img-fluid selected"
                      id="format0"
                      
                      :alt="produit.nom"
                    />
                  </div>
                  <div v-if="nbrFormats >= 2" @click="select(1)">
                    <img
                      :src="produit.imageFormat2Thumb"
                      class="img-format img-fluid"
                      id="format1"
                      
                      :alt="produit.nom"
                    />
                    <link
                      rel="preload"
                      as="image"
                      :href="produit.imageFormat2HighRes"
                    />
                  </div>
                  <div v-if="nbrFormats >= 3" @click="select(2)">
                    <img
                      :src="produit.imageFormat3Thumb"
                      class="img-format img-fluid"
                      id="format2"
                      
                      :alt="produit.nom"
                    />
                    <link
                      rel="preload"
                      as="image"
                      :href="produit.imageFormat3HighRes"
                    />
                  </div>
                  <div v-if="nbrFormats == 4" @click="select(3)">
                    <img
                      :src="produit.imageFormat4Thumb"
                      class="img-format img-fluid"
                      id="format3"
                      
                      :alt="produit.nom"
                    />
                    <link
                      rel="preload"
                      as="image"
                      :href="produit.imageFormat4HighRes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 order-md-5 mt-3 tarification p-md-0">
        <div class="wrapper-tarification">

            <p class="format-texte">
              {{ produit.tarification[selectedFormat].format }}
            </p>

     
            <p class="prix-texte">
              Prix: {{ (produit.tarification[selectedFormat].prix).toFixed(2) }} $
            </p>
       

          <!-- CART QUANTITY -->

          <div class="qty-block mt-4">
            <div class="qty">
              <input
                type="text"
                name="qty"
                maxlength="12"
                v-model="qtyProduit"
                title=""
                class="input-text"
              />
              <div class="qty_inc_dec">
                <i class="increment" @click="changeQty('+')">+</i>
                <i class="decrement" @click="changeQty('-')">-</i>
              </div>
            </div>
            <button
              type="button"
              @click="addToCart()"
              title="Ajouter à mon panier"
              class="btn-cart text-uppercase"
            >
              ajouter à mon panier
            </button>
          </div>
          </div>
        </div>
        <!-- FIN CART -->
      </div>
    </div>
  </div>
  <!-- CHOIX DE FORMATS -->
  <TheFooter />
</template>

<script>
import TheHeader from "../../components/TheHeader";
import TheNavbar from '../../components/TheNavbar';
import TheFooter from '../../components/TheFooter';

import { projectFirestore } from "../../../src/firebase/config";

import { ref, inject, onMounted} from "vue";
import { useRoute } from "vue-router";



export default {
  name: "Produit",
  components: { TheHeader, TheFooter, TheNavbar},

  setup() {
    // IMPORT STORE
    const store = inject('store');
    // LECTURE PRODUIT
    const route = useRoute();
    const produit = ref();
    const nbrFormats = ref();
    const selectedFormat = ref(0);
    const qtyProduit = ref(1);

      const onLoad = onMounted(()=>{

          document.querySelector('#nav').classList.remove('split');
          window.scrollTo(0, 0);

      });


    // OBTENIR INFO PRODUIT
    projectFirestore
      .collection("inventaire")
      .where("lienProduit", "==", route.params.produit)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          produit.value = { ...doc.data(), produitId: doc.id };
          nbrFormats.value = produit.value.tarification.length;
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    const select = (id) => {
      // SELECTIONNE LE BON FORMAT
      selectedFormat.value = id;
      if (nbrFormats.value == 1) {
        // RIEN FAIRE
      } else {
        if (id == 0) {
          document.querySelector("#format0").classList.add("selected");
          document.querySelector("#format1").classList.remove("selected");
          if (nbrFormats.value >= 3) {
            document.querySelector("#format2").classList.remove("selected");
          }
          if (nbrFormats.value == 4) {
            document.querySelector("#format3").classList.remove("selected");
          }
        } else if (id == 1) {
          document.querySelector("#format0").classList.remove("selected");
          document.querySelector("#format1").classList.add("selected");
          if (nbrFormats.value >= 3) {
            document.querySelector("#format2").classList.remove("selected");
          }
          if (nbrFormats.value == 4) {
            document.querySelector("#format3").classList.remove("selected");
          }
        } else if (id == 2) {
          document.querySelector("#format0").classList.remove("selected");
          document.querySelector("#format1").classList.remove("selected");
          document.querySelector("#format2").classList.add("selected");
          if (nbrFormats.value == 4) {
            document.querySelector("#format3").classList.remove("selected");
          }
        } else if (id == 3) {
          document.querySelector("#format0").classList.remove("selected");
          document.querySelector("#format1").classList.remove("selected");
          document.querySelector("#format2").classList.remove("selected");
          document.querySelector("#format3").classList.add("selected");
        }
      }
    };

    
  

    const changeQty = (action) => {
      if (action == "+") {
        qtyProduit.value++;
      } else {
        if (qtyProduit.value != 0) {
          qtyProduit.value--;
        }
      }
    };

  
    const addToCart = () => {
    let image;
    switch(selectedFormat.value) {
    case 0:
        image = produit.value.imageThumb;
        break;
      case 1:
        image = produit.value.imageFormat2Thumb;
        break;
      case 2:
        image = produit.value.imageFormat3Thumb;
        break;
        case 3:
        image = produit.value.imageFormat4Thumb;
        break;
      default:
       console.log('erreur')
    }


    store.state.itemToAdd = { id: produit.value.produitId, isTaxable: produit.value.isTaxable, quantite: qtyProduit.value, prix: produit.value.tarification[selectedFormat.value].prix, format: produit.value.tarification[selectedFormat.value].format, nom: produit.value.nom, image: image};
    store.methods.pushToCart();
  
    }

    return {
      route,
      produit,
      nbrFormats,
      select,
      selectedFormat,
      changeQty,
      qtyProduit,
      addToCart,
    };
  },
};
</script>

<style scoped>


.qty {
  float: left;
  width: 80px;
  margin-right: 10px;
  user-select: none;
}

.qty_inc_dec .increment,
.qty_inc_dec .decrement {
  float: left;
  text-align: center;
  width: 35px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-style: normal !important;
  font-size: 15px;
  line-height: 20px;
  height: 25px;
  vertical-align: middle;
  color:#9b552b;
  background-color: #d6d1c4;
  border: 1px solid #ffffff;
}

.qty_inc_dec .increment {
  border-bottom: 0;
  line-height: 25px;
}

.qty_inc_dec {
  
  width: 10px;
  height: 50px;
  display: inline-block;
}



.qty input[type="text"] {
  float: left;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  outline: 0;
  font-size: 15px;
  text-align: center;
  width: 50px;
  height: 50px;
  color: #222222;
  background-color: #d6d1c4;
  line-height: 40px;
  border: 1px solid #ffffff;
  border-right: 0;
}

.produit_container button[type="button"] {
  margin-left: 20px;
  cursor: pointer;
  width: 240px;
  border: none;
  color: #9B552B;
  background-color: #d6d1c4;
  margin-top: 1px;
  height: 48px;
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight:bold;
  letter-spacing:0.58px;
  border:none;

}



.produit_container button[type="button"]:active,
.produit_container button[type="button"]:hover,
.produit_container button[type="button"]:focus {
  outline: none;
}

.produit_container {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
  grid-template-areas: ". produit .";
  min-height: calc(100vh - 428px);
}

.produit {
  grid-area: produit;
  width: 100%;
  padding: 30px;
}

.selected {
  border: 2px solid #9b552b;
  
}

/* IMAGES  */
.imagesSecWrapper {
  display: flex;
  justify-content: flex-start;

}

.imgPrincipale {
  display: flex;
  justify-content: flex-start;

}

.wrapper-sec {
max-width: 469px;
}

.image-principale-container{
  max-width: 469px;
}



.imgSec1 {
  margin-right: 2.6%;
}
.imgSec {
  padding-top: 11px;
  /* max-width: 48.75%; */
   max-width: 48.7%;
}



/* DESCRIPTION */

.description {
  position: relative;
  min-height: 330px;
}

.nom-produit {
  font-size: 30px;
  color: #9B552B;
  letter-spacing: 1.35px;
  font-weight: bold;
  text-transform: uppercase;
}



.format-ecriture,
.sous-titre-produit{
  letter-spacing: 0.68px;
  font-size: 15px;
  color: #222222;
  font-weight: bold;
}

.sous-titre-produit {
  text-transform: uppercase;
}

.nom-wrapper {
  margin-top: 50px;
}

.format-wrapper {
  width: 220px;
  display: flex;
  justify-content:flex-start;
}

.format-texte {
  color: #222222;
  font-size: 17px;

}

.description-produit{
  font-size: 15px;
  letter-spacing: 0.53px;
  color :#222222;
  line-height: 25px;
}
.marge-desc {
  padding-left: 20px;
  
}

p {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #7e7e7e;
}

.formats-choix-produit{
  margin-top: 10%;
}

.formats-produit {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -60px;
  z-index: 2;
}

.formats{
  margin-left: -3px;
}

.tarification {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
}


.wrapper-tarification{
  position: absolute;
  bottom: 0;
  padding-left: 10px;
}

.prix-texte {
  color: #9b552b;
  font-weight: bold;
  letter-spacing: 1.13px;
}

/* IMAGES FORMATS */

.img-format {
  max-height: 130px;
  margin-right: 11px;
  padding: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}

.formats {
  display: flex;
  justify-content: flex-start;
}


/* MOBILE  */
@media only screen and (max-width: 767px) {


  .imgPrincipale,
  .imagesSecContainer {
  display: flex;
  justify-content: center;

}

.formats-choix-produit{
  margin-top: 0;
}

.wrapper-tarification{
  padding-left: 0;
}



.formats-produit{
  margin-left: -5px;
}

.nom-produit,
.sous-titre-produit,
.description-produit,
.format-texte,
.prix-texte,
.qty-block{
  margin-left: -15px;
}



.formats-produit h5, .formats{
  margin-left: -10px;
}


.produit_container button[type="button"] {
  margin-left: 2px;
  width: 183px;
  color: #9B552B;
  border: 1px solid #d6d1c4;
  height: 47px;
  font-size: 12px;
  letter-spacing:0.58px;
  transition: all 0.2s;
 
}


.qty_inc_dec .increment,
.qty_inc_dec .decrement {
  float: left;
  text-align: center;
  width: 35px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  height: 25px;
  vertical-align: middle;
  color:#9b552b;
  background-color: #d6d1c4;
}

.qty_inc_dec .decrement {
 border: 1px solid #ffffff;
 
}

.qty_inc_dec .decrement{
 border-bottom: 1px solid #ffffff;
 border-left: 1px solid #ffffff;
 border-right: 1px solid #ffffff;
}

.formats-produit,
.wrapper-tarification {
  position: relative;
  
}

.wrapper-tarification{
  padding-top: 40px;
}



}



</style>