import { reactive } from 'vue';


const state = reactive({
    menuMobileView: false,
    cart: [],
    itemToAdd: {},
    currentItem: {},
    numberItemsInCart: 0,
    menuMobile: false,
    filterCartId : [], 
    filterCartIdFormat : [],
    showCart: 0,
    tvq: 0.09975,
    tps: 0.05,
    montantTVQ: 0,
    montantTPS: 0,
    sousTotal: 0,
    recuperationPrix: 0,
    actionRecup:'',
    methodeRecuperation: '',
    adressesRecuperation: [
        {nom: 'St-Sylvère', adresse: '1315, Route du 10eme Rang', ville: 'St-Sylvere', codePostal: 'G0Z 1H0' },
        {nom: 'Ste-thècle', adresse: '1671, ch Saint-Georges', ville: 'St-Thècle', codePostal: 'G0X 3G0' },
        {nom: 'Québec', adresse: '715, rue Ste-Madeleine', ville: 'Québec', codePostal: 'G1R 3M5' },
    ],
    total: 0,
    checkoutDone: false,
    infosClient: {},
    factureClient: {}
    

})

const methods = {
    calcNbrItemCart() {
        state.numberItemsInCart = 0; // RESET NOMBRE ITEM DANS CART
        state.cart.forEach(item => {
            state.numberItemsInCart += item.quantite; // CALCUL NOMBRE TOTAL ITEMS
        })
    },
    storeInfosClient(){
       
        sessionStorage.setItem('infos-checkout', JSON.stringify(state.infosClient));
        state.checkoutDone = false; // RESET CHECKOUT PROCESSUS

    },
    getInfosClient(){
      
        if(sessionStorage.getItem('infos-checkout')){
            state.infosClient = JSON.parse(sessionStorage.getItem('infos-checkout'));
            
        }else{
            state.checkoutDone = true;
           
        }

    },
    clearInfosClient(){
 
        sessionStorage.clear();

    },

    updateLocalStorage(){
        localStorage.setItem('cart-erablieres-bonneville', JSON.stringify(state.cart)); //stringify object and store
        
    },

    checkIfCart(){
        if(localStorage.getItem('cart-erablieres-bonneville') && state.cart.length == 0){
            state.cart = JSON.parse(localStorage.getItem('cart-erablieres-bonneville'));
            this.calcNbrItemCart();
        }
        
    },
    clearCart(){
        // flush cart 
        localStorage.clear();
        state.cart = [];
        state.numberItemsInCart = 0;
    },
    

    pushToCart() {
        state.currentItem = state.itemToAdd;
        this.showCart(); // AFFICHE ITEM AJOUTÉ
        if(state.itemToAdd.quantite != 0){ // SI UTILISATEUR A CHOISI 0 NE RIEN AJOUTER
            if((!state.cart.length)){ // SI CART VIDE  AJOUTER SANS VERIFIER
            state.cart.push(state.itemToAdd)
            }else{
                state.filterCartId = state.cart.filter(item => item.id == state.itemToAdd.id) // LIST DE MEME ID
                if(state.filterCartId.length == 0){
                    state.cart.push(state.itemToAdd) // SI PAS DE MEME ID AJOUTER
                }else{
                    state.filterCartIdFormat = state.filterCartId.filter(item => item.format == state.itemToAdd.format) // LIST DE MEME ID ET MEME FORMAT
                    if(state.filterCartIdFormat == 0){ // SI PAS DE MEME FORMAT AJOUTER
                        state.cart.push(state.itemToAdd)
                    }else{
                        state.cart.forEach(item => {
                            if(item.id == state.itemToAdd.id && item.format == state.itemToAdd.format){ // SI MEME FORMAT ET ID AJOUTER QUANTITEE
                                item.quantite += state.itemToAdd.quantite;  
                            }
                        })
                    }
                }
            }
        }
        this.updateLocalStorage();
        this.calcNbrItemCart();
        this.calculSousTotal();
        state.filterCartId = [];
        state.filterCartIdFormat = [];
        state.itemToAdd = {}; // RESET ITEMTOADD
        },
        
        showCart() {
                state.showCart++;
                setTimeout(() => {
                    state.showCart--;
                }, 2000); //wait 2 seconds
        },
        removeItem(itemId, format) {
            let filterArray = state.cart.filter((item) => (item.id !== itemId || item.format !== format)); // GARDER SEULEMENT LE RESTE
            state.cart = filterArray; // RECOPIER LE ARRAY
            this.calcNbrItemCart();
            this.updateLocalStorage();
            this.calculSousTotal();
            
        }, 
        increaseQty(itemId, format) {
            state.cart.forEach(item => {
                if(item.id === itemId && item.format === format){
                    item.quantite++;
                    this.updateLocalStorage();
                    this.calcNbrItemCart();
                }
            });
            this.calculSousTotal();
        },
        decreaseQty(itemId, format) {
            state.cart.forEach(item => {
                if(item.id === itemId && item.format === format){
                    if(item.quantite > 0){
                        item.quantite--;
                        this.updateLocalStorage();
                        this.calcNbrItemCart();
                    }
                }
            });
            this.calculSousTotal();
        },
        calculSousTotal(){
            state.sousTotal = 0.00; // RESET SOUS TOTAL
            {
                state.cart.forEach(item => {
                    state.sousTotal+=(item.prix*item.quantite)
                 
                    return state.sousTotal
                })
            }
            this.calculTPS()
            this.calculTVQ()
            this.calculTotalFacture();
        },
        calculTPS(){
            let tps = 0; 
            let taxeRecup = 0;
            let totalTaxe = 0;
                state.cart.forEach(item => {
                    if(item.isTaxable){   
                        tps += ((item.prix*item.quantite)*state.tps);
                    }
                        taxeRecup = state.recuperationPrix*state.tps
                
                    totalTaxe = taxeRecup + tps;
                    state.montantTPS = totalTaxe.toFixed(2);
                    return state.montantTPS
                })
            
        },
        calculTVQ(){
            let tvq = 0;
            let taxeRecup = 0;
            let totalTaxe = 0;
                state.cart.forEach(item => {
                    if(item.isTaxable){   
                        tvq += ((item.prix*item.quantite)*state.tvq);
                    }
                        taxeRecup = state.recuperationPrix*state.tvq

                    totalTaxe = taxeRecup + tvq;
                    state.montantTVQ = totalTaxe.toFixed(2);
                    return state.montantTVQ
                })
        },
        calculTotalFacture(){
            let montantTotal = 0;
            montantTotal = state.sousTotal+parseFloat(state.montantTVQ)+parseFloat(state.montantTPS)+state.recuperationPrix;
            state.total = montantTotal.toFixed(2);
        },


        





       


}

const getters = {

}

export default{
    state,
    methods,
    getters
 
}