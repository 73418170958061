<template>
<TheNavbar />
<section class="contact-main">
<div class="contact-contenu-bloc-1">
<div class="contact-points-services titre-contact">
  <h1>NOS 3 POINTS <br /> DE SERVICES </h1>
  <div class="contact-separation"></div>
  <div class="contact-recuperer">
    <h2>Nous vous offrons 2 façons de récupérer votre commande</h2>
  </div>
  
</div>
<div class="container-fluid p-0">
  <div class="row trois-points">
    <div class="col-12">
      <div class="rendez-vous-wrapper">
        <img src="../../public/images/pin-rendezvous.svg" class="pin-rendezvous">
        <h4>SUR RENDEZ-VOUS</h4>
      </div>
      
      <p>
      <ul>
        <li>1- Placez votre commande dans notre boutique en indiquant le mode de récupération.</li>
        <li>2- Nous communiquerons avec vous afin d’établir les détails. </li>
        <li>3- On demande un délai d’au moins 24 heures pour la préparation de votre commande.</li>
      </ul>
      </p>
    </div>
    <div class="col-md-4">
      <p>St-Sylvère (bureau coordonnateur)<br /> 1315 Route du 10eme Rang,<br /> St-Sylvère, G0Z 1H0.</p>
    </div>
      <div class="col-md-4 contact-bloc-adresse">
        <div class="contact-vertical-separation"></div>
      <p>Ste-Thècle (présence occasionnelle)<br /> 1671 ch. Saint-Georges,<br /> Sainte-Thècle, G0X 3G0.</p>
        <div class="contact-vertical-separation"></div>
    </div>
      <div class="col-md-4 contact-marge-left">
      <p>Québec<br />715 rue Ste-Madeleine,<br /> Québec, G1R 3M5.</p>
    </div>
    <div class="col-12">
      <div class="livraison-wrapper">
        <img src="../../public/images/livraison.svg" alt="Livraison locale" class="livraison-logo">
        <h4>Livraison locale</h4>
      </div>
      
      <p>Il est possible pour une commande d’au moins 50$ d’effectuer la livraison locale.</p><p>
      St-Sylvère : supplément de 5$.</p><p> Ste-Gertrude, Daveluyville, St-Wenceslas : 10$.</p>
    </div>
  </div>
  </div>
</div>
</section>


<section class="contact-formulaire">

  <div class="contact-bloc-2">
    <div class="container-fluid p-0">
      <div class="row">


  <div class="col-lg-6 questions">
    <h2 class="">Vous avez<br /> Des questions?</h2>
    <div class="contact-separation"></div>
    <p>
    Écrivez-nous dans les champs disponibles sur cette page.<br /> Ou laissez-nous un message sur notre répondeur,<br />
    il nous fera plaisir de retourner votre appel dans les 24 à 48h.
    </p>

    <a href="tel:8197862192" type="tel">819 786-2192</a><p id="contact-horraire"> En semaine, de 9h à 17h</p>

  </div>
  <div class="col-lg-6 formulaire-contact">

      <form @submit.prevent="sendForm()">
      <div class="row">
  
        <div class="col-md-6 p-1"><input type="text" name="nom" placeholder="VOTRE NOM" class="input-fields" v-model="infoContact.nom" required></div>
        <div class="col-md-6 p-1"><input type="email" name="email" placeholder="VOTRE COURRIEL" class="input-fields" v-model="infoContact.email" required></div>
        <div class="col-12 p-1 bloc-textarea"><textarea row="30" col="60" name="demande" placeholder="VOTRE DEMANDE" class="input-fields" v-model="infoContact.text" required></textarea></div>
        <div class="col-12 contact-bloc-bouton p-1"><button type="submit" class="contact-bouton-envoyer" :disabled='isDisabled'><span v-if="!formSubmitted">ENVOYER</span><span v-else><i class="far fa-check-circle fa-2x"></i></span></button></div>
  
      </div>

    </form>
  </div>

    </div>

      </div>



</div>
</section>


<TheFooter />
</template>

<script>

import TheNavbar from '../components/TheNavbar';
import TheFooter from '../components/TheFooter';
import { reactive, ref, onMounted } from 'vue';

export default {
  name: 'Contact',
  components: {TheNavbar, TheFooter},
  setup(){

    const infoContact = reactive({});
    const formSubmitted = ref(false);
    const isDisabled = ref(false);

    const onLoad = onMounted(()=>{
            document.querySelector('#nav').classList.remove('split');
        });

        
    const sendForm = async () => {
            console.log('sentbefore')
            if(infoContact.nom.length < 2 || infoContact.email.length < 5 || infoContact.text.length < 5){
              return;
            }
            console.log('sent')
            //POST request with body equal on data in JSON format
            fetch('https://leserablieresbonneville.ca/email/infos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(infoContact),
            })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              // IF SUBMIT IS OK
              formSubmitted.value = true;
              isDisabled.value = true;
              

            })
            //Then with the error genereted...
            .catch((error) => {
            console.error('Error:', error);
            });

       

    }

    return{
      infoContact, sendForm, formSubmitted, isDisabled
    }
  }
}
</script>

<style>

.fa-check-circle{
  color: rgb(101, 189, 101);
}
.trois-points li{
  padding-bottom: 3px;
}

.contact-main {
  
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
    background-color: #f5f3f0;
}

.contact-contenu-bloc-1{
  grid-row-start: 1;
  grid-column-start:2;
  grid-column-end: 12;
  padding: 60px 20px;
}

.contact-formulaire {
  
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
  background-color: #ffffff;
}


.contact-bloc-2{
  grid-column-start: 2;
  grid-column-end: 12;
  padding:60px 20px ;
}


.contact-points-services h1, .questions h2{
  font-family: "Lato", sans-serif;
  font-size: 30px;
  color: #9B552B;
  letter-spacing: 1.35px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 40px;
}


.contact-points-services h2{
  padding-top: 20px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #222222;
  letter-spacing: 1.35px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}


.contact-separation {
  margin-top: 30px;
  min-width: 100px;
  width: 10%;
  border-top: 1px solid #000000;

}


.contact-contenu-bloc-1 h4, .questions a, #contact-horraire{
  font-family: "Lato", sans-serif;
  font-size: 15px;
  letter-spacing: 1.13px;
  color: #9B522B;
  font-weight: bold;
}

#contact-horraire {
  margin-top: -5px;
}

.rendez-vous-wrapper, .livraison-wrapper{
  padding-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 15px;
}

.pin-rendezvous{
  padding-right: 15px;
  padding-bottom: 8px;
}

.livraison-logo{
  padding-right: 15px;
  padding-bottom: 7px;
}



.contact-contenu-bloc-1 p{
  font-family : "Lato", sans-serif;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #222222;
}

.contact-bloc-adresse{
  display: flex;
  justify-content: space-between;
  align-items: center;


}

.contact-vertical-separation{

  border-left: 1px solid #000000;
  height: 52px;
  width: 1px;
  margin-bottom: 8px;

}


.contact-marge-left{
  padding-left:80px;
   
}

.input-fields{
  width: 100%;
  min-height: 100%;
  border: 1px solid #9b552b;
  border-radius: 1px;
  padding: 7px;
  
}

.contact-bloc-bouton{
  display: flex;
  justify-content: flex-end;
}

.contact-bouton-envoyer{
  width: 138px;
  height: 59px;
  background-color: #D6D1C4;
  border: none;

}

.questions p{
  padding-top: 20px;
  font-family : "Lato", sans-serif;
  font-size: 15px;
  letter-spacing: 0.53px;
  color: #000000;

}

.bloc-textarea{
  height: 140px;
}




@media (max-width: 1024px) {
.contact-vertical-separation{

display: none;

}

.formulaire-contact{
  padding-right: 20px;
}


}
@media (max-width: 768px) {

.contact-bloc-adresse{
  display: block;

}


.contact-marge-left{
  padding-left: 12px;
   
}

.formulaire-contact{
  padding: 20px;
}


}

@media (max-width: 576px) {

  .contact-contenu-bloc-1{

  padding: 30px 20px;
}


.contact-recuperer{
 max-width: 87%;
}

.contact-points-services h1, .questions h2{
  font-family: "Lato", sans-serif;
  font-size: 24px;
  letter-spacing: 1.13px;
  line-height: 30px;
}





}
</style>
