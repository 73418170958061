<template>
<div class="grid_gestion_wrapper">
    <aside class="menuside">
        <Sidebar/>
    </aside>
    <header class="header__gestion">
         <HeaderGestion/>
    </header>
    <main class="gestion">
    </main>
    <div class="footer__gestion">
        <Footer/>
    </div>

</div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Footer from './Footer.vue';
import HeaderGestion from './HeaderGestion.vue'
import { useRouter } from "vue-router";
import { onMounted } from 'vue';


export default {
  components:  {Sidebar, Footer, HeaderGestion},
    name : 'Gestion',
    setup(){

        const route = useRouter();
        onMounted(()=>{
               route.push({ path: '/gestion/ventes' });
        })
         


        return{
     
        }
    }

}
</script>

<style>

.grid_gestion_wrapper{
    min-height: 100vh;
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas: 
    "sidebar header"
    "sidebar main"
    "sidebar footer"
    ;
}



.menuside{
    grid-area: sidebar;
    background-color: #F8F8F8;
}

.gestion{
    grid-area: main;
    background-color: #f8f8f8;
}

.footer__gestion{
    grid-area: footer;
}

.header__gestion, .footer_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #f8f8f8;

  }

</style>