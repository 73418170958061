<template>

   <div id="wrapper">
    <Sidebar />
        <div id="page-content-wrapper">
                <div class="row">
                    <HeaderGestion />
                </div>
                <div class="container-fluid p-0">
                    <div class="row">
    
                    <div class="col-lg-12">
                    <h2 class="text-center pt-2 pb-4">VENTES</h2>
                   </div>
                     <div class="col-12">
                         <div class="input-group mb-3 pt-3 pt-lg-0">
                            <input type="text" class="form-control" placeholder="RECHERCHE @ PRENOM / NOM / COURRIEL / TELEPHONE / MOBILE / #FACTURE"  v-model="filter" @keydown="filteredFactures()">
                        </div>
            

                    </div>
                    <div class="col-12">
                    
                        <div class="list-group">
                            <button type="button" class="list-group-item list-group-item-action active vente-facture" aria-current="true"><span>{{infoListing}}</span></button>
                            <div v-for="(facture, index) in filteredFactures()" :key="index"  class="list-group-item list-group-item-action vente-facture isClickable" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="getCurrentFacture(facture.factureId)">
                                <div class="row w-100">
                                    <div class="col-sm-6 col-md-2">
                                        {{convertDate(facture.dateCreated)}}
                                    </div> 
                                    <div class="col-sm-6 col-md-3 text-capitalize">
                                        {{facture.prenom}}
                                        {{facture.nom}}
                                    </div>
                                   
                                    <div class="col-sm-6 col-md-2 col-lg-1">
                                        {{facture.montantTotal}}$
                                    </div>
                                    <div class="col-sm-6 col-md-2 d-none d-lg-block">
                                        {{facture.numFacture.toUpperCase()}} 
                                    </div>
                                    <div class="col-sm-6 col-md-2 commande-recue">
                                        {{facture.itemsSold.length}} item<span v-if="facture.itemsSold.length > 1">s </span>
                                    </div>
                                    <div class="col-sm-6 col-md-1">
                                        <span class="badge badge-listing bg-warning text-dark" v-if="facture.marchandiseDonnee == false"> En attente <i class="fas fa-truck meth_recup blackiconcolor" v-if="facture.methodeShipping == 'livraison'"></i><i class="fas fa-box-open blackiconcolor meth_recup" v-else></i></span>
                                        <span class="badge badge-listing bg-success text-right text-center" v-if="facture.marchandiseDonnee == true">-- Livrée --  <i class="fas fa-truck meth_recup blackiconcolor" v-if="facture.methodeShipping == 'livraison'"></i><i class="fas fa-box-open blackiconcolor meth_recup" v-else></i></span>
                                       
                                    </div>
                                    
                               </div>
                            </div>
                         
                        </div>
                    </div>
                   
                    </div>
                    
           
                </div>

                    
    
    </div>


<!-- Modal -->

<div class="modal fade modal-body modal-facture" id="staticBackdrop" data-bs-backdrop="static"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Facture # {{currentFacture.numFacture.toUpperCase()}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <div class="container-fluid">
              <div class="row ">
                    <div class="col-12 pb-3 p-0 box-button-vente border-bottom">
                            <button class="btn btn-danger" @click="deleteCurrentFacture()">EFFACER LA FACTURE</button>
                       
                            <button class="btn btn-success" @click="handleLivraison()">DÉCLARER FACTURE LIVRÉE</button>
                        </div>
                     
                  
            
            <div class="col-lg-7 bloc-vente mt-3">
                <div class="row">
                    <div class="col-6 vente-border ">
                        <p class="text-capitalize">{{currentFacture.prenom}} {{currentFacture.nom}}</p>
                        <p class="text-capitalize">Tel: <a :href="`tel:${currentFacture.telephoneMaison}`"> {{currentFacture.telephoneMaison}}</a></p>
                        <p>Mobile: <a :href="`tel:${currentFacture.telephoneMaison}`"> {{currentFacture.telephoneMaison}}</a></p>
                        <p><a v-if="currentFacture !== undefined" :href="`mailto:${currentFacture.courriel}`">{{currentFacture.courriel}}</a></p>
                    </div>
                    <div class="col-6 vente-adresse">
                        <a :href="`https://maps.google.com/?q=${currentFacture.numNomRue} ${currentFacture.ville} ${currentFacture.codePostal}`" target="_blank">
                        <p class="text-capitalize">{{currentFacture.numNomRue}}</p>
                        <p class="text-capitalize">{{currentFacture.ville}}</p><p class="text-uppercase">{{currentFacture.codePostal}}</p>
                        </a>
                    </div>
                
                </div>
            </div>
            
            <div class="col-lg-3 offset-lg-2 bloc-vente vente-adresse mt-3">
                <p> {{convertDate(currentFacture.dateCreated)}}</p>
                <p v-if="currentFacture.paymentMethod == 'card'">Payé Stripe Web</p>
                <p v-else>Argent</p>
                
                        <span class="badge bg-warning text-dark mt-3" v-if="currentFacture.marchandiseDonnee == false"> En attente<i class="fas fa-truck meth_recup blackiconcolor" v-if="currentFacture.methodeShipping == 'livraison'"></i><i class="fas fa-box-open blackiconcolor meth_recup" v-else></i></span>
                        <span class="badge bg-success text-right text-center mt-3" v-if="currentFacture.marchandiseDonnee == true">-- Livrée --<i class="fas fa-truck meth_recup blackiconcolor" v-if="currentFacture.methodeShipping == 'livraison'"></i><i class="fas fa-box-open blackiconcolor meth_recup" v-else></i></span>
            </div>
            </div>
            <div class="row">

                 <div class="col-12 mt-4 bloc-vente">
                 <ul>
                        <li v-for="(item, index) in currentFacture.itemsSold" :key="index">
                            <div class="row w-100">
                                <div class="col-md-1">
                                    {{item.quantite}}
                                </div>
                                <div class="col-md-5">
                                    {{item.nom.toUpperCase()}} - {{item.format}}
                                </div>
                                <div class="col-md-1">
                                    {{(item.prix).toFixed(2)}}$
                                </div>
                                <div class="col-md-2">
                                    / unitée
                                </div>
                                <div class="col-md-3 vente-total-ligne">
                                    {{((item.prix)*item.quantite).toFixed(2)}}$
                                </div>
                            </div>
                        </li>
                           <li v-if="currentFacture.methodeRecuperation == 'livraison-autres'">
                            <div class="row w-100">
                                <div class="col-md-1">
                                    1
                                </div>
                                <div class="col-md-8">
                                    Livraison Ste-Gertrude, Daveluyville, St-Wenceslas
                                </div>
                               
                                <div class="col-md-3 vente-total-ligne">
                                    10.00$
                                </div>
                            </div>
                        </li>
                         <li v-if="currentFacture.methodeRecuperation == 'livraison-st-sylvere'">
                            <div class="row w-100">
                                <div class="col-md-1">
                                    1
                                </div>
                                <div class="col-md-5">
                                    Livraison St-Sylvère
                                </div>
                                <div class="col-md-1">
                                   
                                </div>
                                <div class="col-md-2">
                                  
                                </div>
                                <div class="col-md-3 vente-total-ligne">
                                    5.00$
                                </div>
                            </div>
                        </li>
                          <li v-if="currentFacture.montantTPS > 0">
                            <div class="row w-100">
                                <div class="col-md-1">
                                   
                                </div>
                                <div class="col-md-5">
                                     Total TPS / TVQ
                                </div>
                                <div class="col-md-1">
                                   
                                </div>
                                <div class="col-md-2">
                                  
                                </div>
                                <div class="col-md-3 vente-total-ligne">
                                    {{(parseFloat(currentFacture.montantTPS)+parseFloat(currentFacture.montantTVQ)).toFixed(2)}}$
                                </div>
                            </div>
                        </li>
                          <li>
                            <div class="row w-100 border-top">
                                <div class="col-md-1">
                                   
                                </div>
                                <div class="col-md-5">
                                     
                                </div>
                                <div class="col-md-1">
                                   
                                </div>
                                <div class="col-md-2">
                                  Total :
                                </div>
                                <div class="col-md-3 vente-total-ligne">
                                    {{parseFloat(currentFacture.montantTotal).toFixed(2)}}$
                                </div>
                            </div>
                        </li>
                    </ul>

                <div class="col-12 vente-total-ligne">

                </div>
                </div>
            </div>

          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
      
      </div></div>
    </div>
  </div>
</div>
</template>


<script>

import { useRouter } from "vue-router";
import getFactures from "../../../composables/getFactures";
// IMPORT COMPOSANTS
import Sidebar from '../Sidebar.vue';
import HeaderGestion from '../HeaderGestion.vue'
import { ref } from 'vue';
import { projectFirestore } from '../../../firebase/config'

export default {
    name: 'Ventes',
    components:{Sidebar, HeaderGestion},
    setup(){
    // GET FACTURES
    const { factures, errorFacture, loadFacture } = getFactures();
    const facturesToFilter = ref(factures);
    const filter = ref('');
    const infoListing = ref('30 Dernieres Factures')
    loadFacture();
    const route = useRouter();
    const currentFacture = ref({
        numFacture: '--',
        prenom: '--',
        telephoneMaison: 8888888,
        couriel: 'aaa@aaa.com',
        codePostal: 'GGGGGGG',
        marchandiseDonnee: '--',
        methodeShipping: '--'

    });

    const docFactureId = ref();


    const getCurrentFacture = async (factureId) => {  
        docFactureId.value = factureId
        var docRef = projectFirestore.collection("factures").doc(factureId);
        docRef.get().then((doc) => {
            if (doc.exists) {
         
                currentFacture.value = doc.data()
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                currentFacture.value = 'La facture est introuvable'
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        
    }


    const filteredFactures = () => {
        
        let listing = facturesToFilter.value;
        var lastFactures = []
        if(filter.value == ''){
            infoListing.value = '30 Dernieres Factures';
           listing.forEach((facture, index) => {
               if(index<30){
                   lastFactures.push(facture);
               }
           })
            return lastFactures;
        }else{
            let filtered = [];
            for(let i = 0; i < listing.length; i++){
                let splitSearch = filter.value.split(" "); // SPLIT SI PLUSIEURS MOTS

           

               if(splitSearch.length == 1){ // SI UN SEUL MOT DE RECHERCHE
                infoListing.value = 'RECHERCHE POUR : '+filter.value;

                   if(listing[i].prenom.toLowerCase().includes(filter.value.toLowerCase())){
                       filtered.push(listing[i])
                   }
                   else if(listing[i].nom.toLowerCase().includes(filter.value.toLowerCase())){
                       filtered.push(listing[i])
                   }
                   else if(listing[i].courriel.toLowerCase().includes(filter.value.toLowerCase())){
                       filtered.push(listing[i])
                   }
                   else if(listing[i].numFacture.toLowerCase().includes(filter.value.toLowerCase())){
                       filtered.push(listing[i])
                   }
                   else if(listing[i].telephoneMaison.includes(filter.value)){
                       filtered.push(listing[i])
                   }
                    else if(listing[i].mobile.includes(filter.value)){
                       filtered.push(listing[i])
                   }
               }
                    else if(splitSearch.length > 1){ // SI PLUSIEURS MOTS DE RECHERCHES
                        infoListing.value = 'RECHERCHE POUR : '+splitSearch;
                        for(let j=0; j < splitSearch.length; j++){
                                if(listing[i].prenom.toLowerCase().includes(splitSearch[j].toLowerCase())){
                                    filtered.push(listing[i])
                                }
                                else if(listing[i].nom.toLowerCase().includes(splitSearch[j].toLowerCase())){
                                    filtered.push(listing[i])
                                }
                                else if(listing[i].courriel.toLowerCase().includes(splitSearch[j].toLowerCase())){
                                    filtered.push(listing[i])
                                }
                                else if(listing[i].numFacture.toLowerCase().includes(splitSearch[j].toLowerCase())){
                                    filtered.push(listing[i])
                                }
                                else if(listing[i].telephoneMaison.includes(splitSearch[j])){
                                    filtered.push(listing[i])
                                }
                                else if(listing[i].mobile.includes(splitSearch[j])){
                                filtered.push(listing[i])
                                }
                        }
                    }
            }
            
            const factureUnique = [...new Map(filtered.map(item => [item.numFacture, item])).values()] // RETIRE LES DOUBLONS

            return factureUnique; 

        }
    }

    const deleteCurrentFacture = async () => {
        
        var proceed = confirm("Voulez-vous vraiment effacer cette facture?");
        if (proceed) {
            try{

                const res = await projectFirestore.collection('factures').doc(docFactureId.value).update({isDeleted: true}) // 
                  route.go();
              
            }catch(err){
                console.log(err)
            }
            
                
        } else {
        //don't proceed
        }

    }


    const handleLivraison = async () => {
        var proceed = confirm("Voulez-vous vraiment déclarer cette facture livrée?");
        if (proceed) {
        try{
            const res = await projectFirestore.collection('factures').doc(docFactureId.value).update({marchandiseDonnee: true})
            route.go();
        }catch(err){
            console.log(err)
        }
          
           
                
        } else {
        //don't proceed
        }

    }



    const convertDate = (inputDate) => {


                // Unixtimestamp
                var unixtimestamp = inputDate;

                // Months array
                var months_arr = ['01','02','03','04','05','06','07','08','09','10','11','12'];

                // Convert timestamp to milliseconds
                var date = new Date(unixtimestamp*1000);

                // Year
                var year = (date.getFullYear()-1969);

                // Month
                var month = months_arr[date.getMonth()];

                // Day
                var day = date.getDate();

                // Hours
                var hours = date.getHours();

                // Minutes
                var minutes = "0" + date.getMinutes();

                // Seconds
                var seconds = "0" + date.getSeconds();

                // Display date time in MM-dd-yyyy h:m:s format
                return day+'-'+month+'-'+year;

            

    }

        return{
            factures, errorFacture, loadFacture, convertDate, currentFacture, getCurrentFacture, deleteCurrentFacture, docFactureId, handleLivraison,filteredFactures, facturesToFilter, filter, infoListing
        }
    }

}
</script>

<style scope>

.vente-facture{
    display:flex;
    justify-content: space-between;
}

.badge-listing{
    margin-left: 10px;
}

.modal-facture p{
    color: #222222;
    margin:0;
    padding: 0;

}

.bloc-vente{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #000000;
}

.vente-border{
    border-right: 1px solid #000000;
}

.vente-adresse{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.meth_recup{
    padding-left: 10px;
}

.modal{
    position: fixed;
    top: 100px;
}

.vente-total-ligne{
    display: flex;
    justify-content: flex-end;
}

.box-button-vente{
    display: flex;
    justify-content:space-between;
}

.isClickable{
    cursor: pointer;
}


</style>