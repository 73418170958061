<template>
    <div class="sousnav">
        <div class="contenu">
                    <h1 v-if="adresse == '/boutique'" class="titre text-uppercase">boutique</h1>
                    <h1 v-else-if="adresse == '/boutique/panier'" class="titre text-uppercase">panier</h1>
                    <h1 v-else class="titre text-uppercase">produit</h1>
                    <div class="adresse">
                        <router-link to="/" class="link_adress titre text-uppercase"><h2>accueil</h2></router-link>
                        <h2 class="titre slash"> / </h2>
                        <h2 v-if="adresse == '/boutique'" class="link_adress titre text-uppercase">boutique</h2>
                        <div v-else-if="adresse == '/boutique/panier'" class="adresse">
                            <router-link to="/boutique" class="link_adress titre text-uppercase"><h2>boutique</h2></router-link>
                            <h2 class="titre slash"> / </h2>
                            <h2 class="titre text-uppercase">panier</h2>
                        </div>
                        <div v-else class="adresse">
                            <router-link to="/boutique" class="link_adress titre text-uppercase"><h2>boutique</h2></router-link>
                            <h2 class="titre slash"> / </h2>
                            <h2 class="titre text-uppercase">produit</h2>
                        </div>
                    </div>
        </div>
    </div>
</template>

<script>



import { ref } from 'vue';
import { useRoute } from 'vue-router';


export default {
    name : 'TheHeader',
    components:{},
    setup(){

    const route = useRoute();
    const adresse = ref(route.path);

        return{
          adresse
        }
    
}}
</script>

<style scope>

.sousnav{

    height: 90px;
    background-color:#f5f3f0;
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
    grid-template-areas:
      ". contenu ."
}

.contenu{
    height: 100%;
    width: 100%;
    grid-area: contenu;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.titre{
    padding-top: 5px;
    color: #9b552b;
}

.contenu h1{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1.35px;
    
}

.contenu h2{
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #222222;
    letter-spacing: 0.68px;
    
}

.adresse{
display: flex;
justify-content:flex-end;
}

.slash{
  margin-left: -8px;
  padding-right: 5px;
}


/* TABLETTE  */
@media only screen and (max-width: 767px) { 
    
.contenu h1{
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    
}


.titre{
    margin-left: -5px;
}



.sousnav{
    margin-top: -20px;
    height: 60px;
}


.contenu h2{
   display: none;
    
}
   
}


</style>