<template>
<TheNavbar />
    <div class="boutique-erreur">
        <div class="carre-erreur">
            <h1>Oups!</h1>
            <p class="text-center">
                Une erreur est survenue !
            <span class="mobile-erreur"><br/></span>
            Veuillez recommencer,<br />si cette
            erreur persiste, contactez-nous.</p>

        </div>
    </div>
    
  <TheFooter />
</template>

<script>

import TheNavbar from '../../components/TheNavbar';
import TheFooter from '../../components/TheFooter';
import { inject, onMounted} from "vue";




export default {
  name: "Erreur",
  components: { TheFooter, TheNavbar},

  setup() {
    const store = inject('store');
    // LECTURE PRODUIT
   
 
 

  


    const onLoad = onMounted(()=>{
        // CHANGE BACKGROUND NAVBAR
        document.querySelector('#nav').classList.remove('split');
 
           
    })

    return {
        store
    };
  },
};
</script>

<style scope>

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');



.boutique-erreur{
    min-height: calc(100vh - 290px);
    width: 100%;
    background-color: #f5f2f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9B552B;
    font-family: "Lato", sans-serif;
}

.carre-erreur{
    width: 80%;
    max-width: 1445px;
    height: 300px;
    border: 1px  solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carre-erreur h1{
    font-size: 6em;
    font-family: "Lato", sans-serif;
}

.carre-erreur p{
    font-size: 1em;
    text-transform: uppercase;
}



@media (max-width: 600px) {

.boutique-erreur{
    min-height: calc(100vh - 265px);

}


.carre-erreur{
    padding: 20px;
}



.carre-erreur h1{
    font-size: 5em;
    font-family: 'Libre Baskerville', serif;
}

.carre-erreur p{
    font-size: 0.9em;
    text-transform: uppercase;
}




}


</style>