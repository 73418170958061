const menuGestion = () => {
    const menu = [
    {
        nom: 'Ventes',
        adresse: '/gestion/ventes',
        icone: 'fas fa-hand-holding-usd',
        alert: 'true',
    },
    {
        nom: 'Inventaire',
        adresse: '/gestion/inventaire/ajout/',
        icone:'fas fa-plus-circle',
        alert:'false'
    },
    {
        nom: 'Inventaire',
        adresse: '/gestion/inventaire/suppression/',
        icone:'fas fa-minus-circle',
        alert:'false'
    },
    {
        nom: 'Inventaire',
        adresse: '/gestion/inventaire/modif/',
        icone:'far fa-list-alt',
        alert:'false'
    }]

    return menu
}

export default menuGestion