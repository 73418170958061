<template>

    <router-view />
 
</template>
<script>
import store from './store/index';
import { provide } from 'vue';

export default {
    name : 'App',

    
    setup(){ 
    
    provide('store', store);
 

        return{

        }
    
}}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body{ margin: 0; padding: 0;}
.no-scroll {
  overflow: hidden;
}
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  background-color:#ffffff;
}

@media (min-width: 577px) and (max-width: 991px) {
  
#app {
  background-color: #f5f3f0;
  
}

}



@media (max-width: 576px) {
#app {
  background-color: #f5f3f0;
  
}


}


</style>