<template>
<div class="bandeau-container">
     <div class="bandeau-contenu">
                <div class="bandeau-info item-bandeau marge-gauche">
                          
                        <h3>Où nous trouver</h3>
                        <div class="bandeau-adresses">
                            <div class="bandeau-pin-adresse">
                                <img src="../../../public/images/pin_adresse.png" class="bandeau-pin">
                            </div>
                            <div class="bandeau-adresse-texte">
                            <p>St-Sylvère (bureau coordonnateur)<br /> 1315 Route du 10eme Rang, St-Sylvère G0Z 1H0.</p>
                             <div class="separation-mobile-bandeau"></div>
                            <p>Ste-Thècle <br />1671 ch. Saint-Georges, Sainte-Thècle, G0X 3G0.</p>
                            </div>
                        </div>
                      
                </div>
                    <div class="separation-mobile-bandeau"></div>
                    <div class="logo-erabliere item-bandeau">
                        <img src="../../../public/images/chevreuil-erablieres-bonneville.png" alt="Chevreuil Les Erablieres Bonneville Sirop D'Erable">
                    </div>
                    <div class="bandeau-communiquer item-bandeau ">
                        <div class="communiquer-items">
                            <p>Pour toutes questions</p>
                            <button @click="goToContact()" class="accueil-bouton-lien-contact">communiquer avec nous</button>
                            <img src="../../../public/images/pin_adresse.png" class="bandeau-pin-mob">
                        </div>
                
                    </div>  
     </div>
</div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
    name : 'BandeauContact',
    
    setup(){
            const router = useRouter();
            const goToContact = () => {
                router.push({path: '/contact'});
            }
    
        return{
          goToContact
        }
    
}}
</script>

<style>

.bandeau-pin-mob{
    display: none;
}

.bandeau-container{
    background-color: #9B552B;
    min-height: 300px;
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1450px) 1fr;
    grid-template-areas:
      ". bandeau-contenu ."
}
    
.bandeau-contenu{
    grid-area: bandeau-contenu;
    display: flex;
    justify-content:space-between;

}

.bandeau-info h3{
    font-family: Lato;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    align-self: flex-start;
    padding-bottom: 20px;
    padding-left: 13px;
}

.bandeau-contenu p{
    color: #ffffff;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    letter-spacing: 0.68px;
}

.item-bandeau{
    width: 33%;
}

.bandeau-info{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo-erabliere{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bandeau-communiquer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-communiquer{
    display: flex;
    flex-direction: column;
}


.bandeau-adresses{
    
    display: grid;
    grid-template-columns: 1fr, 1fr, 3fr;
    
}

.bandeau-pin-adresse{
    grid-column-start: 2;
    grid-column-end: 3;
    width: 60px;
  
}


.accueil-bouton-lien-contact{
 
cursor: pointer;
  max-width: 260px;
  background: #222222;
  border-radius: 13px;
  padding: 13px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.58px;
  color: #D6D1C4;
  border: none;
  height: 50px;


}


.bandeau-adresse-texte{
    grid-column-start: 3;
    grid-column-end:4;
}


.separation-mobile-bandeau{
    display: none;
}

@media (max-width: 965px) {



.bandeau-info h3{
    padding-left: 0px;
    font-size: 20px;

}

.bandeau-pin-adresse{
    display: none;
}



}



@media only screen and (max-width: 767px){

.accueil-bouton-lien-contact{
 
    cursor: pointer;
    max-width: 260px;
    font-size: 14px;
    min-height: 60px;

}




.bandeau-communiquer{
    display: flex;
    align-items: center;
    justify-content: center;

}


.bandeau-info {
    padding-top: 30px;
}

.bandeau-contenu{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.bandeau-info h3{
    padding-left: 0px;
    font-size: 14px;
    text-align: center;
    align-self: center;

}


.bandeau-info p{

font-size: 12px;
}



.item-bandeau{
    width: 100%;
}

.bandeau-info{
    text-align: center;
    margin-right: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
 
}

.logo-erabliere{
    display: none;
}

.separation-mobile-bandeau{
    display: block;
    margin: auto;
    width: 70px;
    border-top: 1px solid #ffffff;
    padding-bottom: 15px;

}

.bandeau-adresses{
    display: grid;
    grid-template-columns: 1fr
    
}
.communiquer-items{
    display: flex;
    flex-direction: column;

}

.communiquer-items p{
    text-align: center;
    margin: 0;
    padding: 0 0 10px 0;
}


.accueil-bouton-lien-contact{
 

    max-width: 220px;
    font-size: 12px;
    letter-spacing: 0.58px;
    color: #D6D1C4;
    height: 40px;
    margin-bottom: 20px;


}


.bandeau-pin-mob{
    display:block;
    margin:auto;
    width : 40px;
    padding-bottom: 20px;
}



}

</style>