<template>
<TheNavbar />
  <main class="apropos-main">
    <div class="titre-photo-cabane">
      <div class="container-fluid">
        <div class="row apropos-bloc1 mt-lg-5">
          <div class="apropos-bloc-titre-gauche col-lg-5 order-1 p-md-0 p-lg-1">
            <div class="titre-apropos">
              <h1>a propos de nous</h1>
              <div class="description-apropos">
                <div class="apropos-separation"></div>
                <h5 class="apropos-nomob">
                  2 érablières et une famille impliquée
                </h5>
                <h5 class="apropos-mob">
                  2 érablières<br />
                  et une famille impliquée
                </h5>
              </div>
            </div>
          </div>

          <div class="photo-cabane col-lg-7 p-0  order-lg-1  text-center">
            <img
              src="../../public/images/erablierebonneville.jpg"
              alt="Cabane à sucre les Érablières Bonneville"
              class="img-fluid apropos-nomob image-fw p-0"
            />
            <img
              src="../../public/images/erable-bonneville-mobile.jpg"
              alt="Erablieres Sirop Érables"
              class="img-fluid apropos-mob"
            />
          </div>

          <div class="col-12 mobile-sous-image-apropos"></div>

          <div
            class="col-lg-6 col-xl-7 order-5 order-lg-4 mt-md-5 pb-md-2 p-0 "
          >
            <img
              src="../../public/images/erabliere.jpg"
              alt="Erablieres Sirop Érables"
              class="img-fluid apropos-nomob image-fw p-0 m-0"
            />
            <img
              src="../../public/images/cabane-sucre.jpg"
              alt="Cabane à sucre les Érablières Bonneville"
              class="img-fluid apropos-mob"
            />
          </div>

          <div
            class="apropos-bloc-historique col-lg-6 col-xl-5 order-4 mt-md-3"
          >
            <h5 class="no-mob pb-md-4 pt-xl-5 inner-historique">Historique</h5>
            <p class="apropos-historique inner-historique">
              Notre érablière est non commerciale, est à dire que nous ne
              faisons pas de repas et n’avons pas de salle de réception. Nous
              vendons en vrac notre sirop d’érable a la PPAQ et vendons des
              produits aux particuliers. Tout simplement.
            </p>

            <p class="inner-historique">
              L’érablière a été acquise en 2001, par Daniel Bonneville et sa
              femme Dominique Guay. Ils ont été conquis par cette belle région
              du Centre du Québec. Originaires de la Nouvelle Beauce, Daniel
              quitte la ferme laitière familiale afin de se consacrer à temps
              pleins a sa passion pour les boisés. Agé de seulement 25 ans lors
              de l’acquisition de l’érablière, il doit occuper un autre travail
              en même temps pendant quelques années.
            </p>
            <p class="inner-historique">
              Après l’acquisition de petites érablières dans le 10eme Rang à
              St-Sylvère, Les propriétaires achètent l’Érablière Aux mille
              érables Inc., située à Ste-Thècle, en Mauricie. Le sol sablonneux
              de St-Sylvère, des basses terres du St-Laurent combiné au sol
              rocailleux de l’érablière de Ste-Thècle située au pieds des
              montagnes de la Mauricie donne au sirop un gout exceptionnel et
              distinctif.
            </p>
            <p class="inner-historique">
              En 2020, nous avons commencé à fabriquer du sirop de merisier
              (bouleau jaune). Cet arbre que l’on retrouve principalement dans
              les forets centenaires du Québec.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>

  <article>
    <div class="apropos-bloc-logo-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="apropos-bloc-logo-desc col-sm-6">
            <h5 class="apropos-logo-inner">LOGO</h5>
            <div>
              <p class="apropos-logo-inner">
                Le logo représente le respect de la nature qui est une valeur
                très importante pour nous. Autant au niveau des arbres de
                l’érablières que des animaux sauvages qui peuplent ces
                érablières, dont des cerfs, qui ne craignent pas la chasse sur
                nos terrains.
              </p>
              <p class="apropos-logo-inner">
                La simplicité est aussi caractéristique de nos érablières.
                Daniel aime travailler dans la simplicité et la bonne humeur et
                ses employés sont consultés et écoutés. Professionnalisme : Le
                propriétaire vise la perfection de son sirop d’érable, dont le
                bon gout raffiné et délicat est reconnu par ses acheteurs et
                clients.
              </p>
            </div>
          </div>
          <div class="col-sm-6 apropos-logo-full apropos-nomob">
            <img
              src="../../public/images/erablieres_bonneville_logo-full.svg"
              class="img-fluid pt-5 pb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
  <div class="apropos-logo-mob-full-width apropos-mob">
    <img
      src="../../public/images/erablieres_bonneville_logo-full.svg"
      class="img-fluid pt-5 pb-5"
    />
  </div>

<TheFooter />
</template>

<script>

import TheNavbar from '../components/TheNavbar';
import TheFooter from '../components/TheFooter';
import { onMounted } from 'vue';

export default {
  name: "Apropos",
  components: {TheNavbar, TheFooter},
  setup() {
    const onLoad = onMounted(()=>{
      window.scrollTo(0, 0);
    })
    return {};
  },
};
</script>

<style scoped>
.apropos-main {
  background: linear-gradient(90deg, #ffffff 50%, rgba(214, 209, 196, 0.3) 50%);
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
}

article {
  display: grid;
  grid-template-columns: 1fr repeat(10, minmax(0, 145px)) 1fr;
  background: linear-gradient(90deg, #9b552b 50%, #ffffff 50%);
}

.apropos-bloc-logo-wrapper {
  grid-column-start: 2;
  grid-column-end: 12;
  margin: 0 20px;
}

.titre-photo-cabane {
  grid-column-start: 2;
  grid-column-end: 12;

  margin: 0px;
}
.apropos-full-width {
  grid-column-start: 1;
  grid-column-end: 13;
}

.apropos-bloc1 {
  position: relative;
}

.apropos-bloc-titre-gauche {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titre-apropos{

  padding-left: 20px;

}

.titre-apropos h1 {
  font-weight: bold;
  font-size: 30px;
  font-family: "Lato", sans-serif;
  letter-spacing: 1.35px;
  color: #9b552b;
  text-transform: uppercase;
  padding-bottom: 70px;
 
}

.apropos-separation {
  width: 100px;
  border-top: 1px solid #222222;
  text-align: right;
}

.description-apropos h5 {
  padding-top: 20px;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.68px;
  color: #222222;
  text-transform: uppercase;
}

.mobile-sous-image-apropos {
  display: none;
}

.apropos-mob {
  display: none;
}

.apropos-bloc-historique p {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #222222;
  letter-spacing: 0.53px;
  
}

.apropos-bloc-logo-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.apropos-bloc-logo-desc p {
  margin-left: -15px;
  color: #d6d1c4;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  letter-spacing: 0.53px;
}

.apropos-bloc-logo-desc h5 {
  margin-left: -15px;
  color: #d6d1c4;
  padding: 10px 0 20px 0;
}

.apropos-logo-full {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-fw {
  padding: 0 10px;
}

.photo-cabane {
  margin-bottom: 50px;
}

.inner-historique{
  padding-left: 20px;
}

.apropos-logo-inner{
  padding: 0 5px !important;
}



/* ====== VERSION TABLETTE OU PLUS  ======  */
@media (min-width: 992px) and (max-width: 1200px) {
  .apropos-bloc-historique {
    padding-left: 3%;
  }

  .image-fw {
    padding: 0px;
  }

  .apropos-bloc-historique:last-child {
    padding-bottom: 70px;
  }
  
  .inner-historique{
  padding-left: 0;
}
}



@media (min-width: 577px) and (max-width: 991px) {
  .apropos-bloc-historique h5 {
    margin-top: 5%;
  }

  .apropos-main {
    background: none;
  }

  .apropos-logo-full {
    background-color: #f5f3f0;
  }

  .apropos-bloc-logo-wrapper {
    padding-top: 40px;
  }

  .photo-cabane {
    margin-bottom: 30px;
  }

  .titre-apropos h1 {
    padding-bottom: 35px;
  }


  .titre-apropos{
  padding-left: 20px;
}
  .inner-historique{
  padding-left: 8px;
}

  article {
    background: linear-gradient(90deg, #9b552b 50%, #f5f3f0 50%);
  }
}

@media (min-width: 577px) and (max-width: 767px){
  .titre-apropos{
  padding: 0 0 10px 10px;
}
}

/* ====== VERSION MOBILE ======  */
@media (max-width: 576px) {
  .apropos-main {
    background: none;
  }

  article {
    background: none;
    background-color: #9b552b;
  }

  .apropos-bloc-logo-desc {
    padding: 20px 0;
  }

  .apropos-mob {
    display: block;
    width: 100%;
  }

  .apropos-nomob {
    display: none;
  }

  .titre-apropos h1 {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }

.titre-apropos{
  padding-left: 0;
}

  .description-apropos h5 {
    font-size: 12px;
    letter-spacing: 0.54px;
  }

  .mobile-sous-image-apropos {
    display: block;
    height: 40px;
    background: linear-gradient(90deg, #f5f3f0 50%, #9b552b 50%);
  }

  .titre-photo-cabane {
    grid-column-start: 0;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 5;
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    padding-top: 40px;
  }

  .titre-apropos {
    max-width: 100%;
  }

  .titre-photo-cabane {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 2;
    margin-top: -40px;
  }

  .photo-cabane {
    margin-bottom: 0px;
  }

  .apropos-historique {
    padding-top: 20px;

  }

   .inner-historique{
  padding-left: 0;
}

  .apropos-bloc-historique p {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    color: #222222;
    letter-spacing: 0.53px;
   
  }

  .apropos-logo-mob-full-width {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }
  .apropos-logo-mob-full-width img {
    width: 70%;
  }

  .apropos-bloc-logo-desc {
    padding-left: 8px;
  }
  .apropos-logo-inner{
  padding: 0 0 !important;
}

}
</style>
