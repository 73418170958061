<template>

<div class="onaddcartwrapper shadow p-3">
<div class="row">

  <div class="col-12 mb-4">
    <h4 class="text-center">AJOUT AU PANIER</h4>
  </div>
</div>
<div class="row">
  <div class="col-5 image-cart-holder">
    <img :src="store.state.currentItem.image" :alt="store.state.currentItem.nom" class="thumb-cart" width="100" height="100">
  </div>
  <div class="col-7 item-cart">
      <router-link to="/boutique/panier">
      <p class="item-cart-nom ">{{store.state.currentItem.nom}}</p>
      <p>{{store.state.currentItem.format}}</p>
      <p>Quantité: {{store.state.currentItem.quantite}}</p>
      <p>Total: <span class="item-cart-price">{{(store.state.currentItem.prix*store.state.currentItem.quantite).toFixed(2)}} $</span></p>
      </router-link>
      
  </div>



</div>

</div>

</template>

<script>

import { inject } from 'vue';

export default {
    name : 'PopCart',
    components:{},
    setup(){
         const store = inject('store');
    
        return{
            store
        }
    
}}
</script>

<style>

.icon_cart{
  transform: scale(1.2);
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform:translateY(-120px);
    opacity: 0;
  }
  50% {
    transform:translateY(40px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}
.onaddcartwrapper{
    position: fixed;
    top: 0;
    right: 30px;
    width: 33%;
    min-width: 300px;
    max-width: 480px;
    min-height: 100px;
    background-color: white;
    border-radius: 5px;
    z-index: 200;
}
/* .thumb-cart{
  min-height:100px;
} */
.item-cart{
  text-align: left;
}
.item-cart p{
  line-height: 1.4;
  font-size:15px;
  color: #222222;
  text-transform: uppercase;
}

.item-cart-nom {
  font-weight: normal;
}
.item-cart-price{
  color: #9B552B;
  font-weight: bold;
  font-size: 15px;
}

.image-cart-holder{
  display: flex;
  justify-content: center;
  
}
@media (max-width: 576px) {
.onaddcartwrapper{
    right: 0;
    left:0;
    width: calc(100% - 20px);
    margin-left:10px;
  
}


/* .thumb-cart{

  width: 100%;
  
  min-height: 70%;


} */


}

</style>