<template>

  <div class="slide-mobile">
    

  </div>

</template>

<script>


export default {
    name : 'Slidemobile',
    
    setup(){


        return{
          
        }
    
}}
</script>

<style>

.slide-mobile{
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background-color: #000000;
    
   
}






</style>