<template>
<div class="footer_wrapper">
<p>EFFSET</p>
<p>Copyright 2021</p>
</div>
</template>

<script>

export default {
    name : 'Footer',
    setup(){

          

        return{
     
          
        }
    }

}
</script>

<style>

.footer_wrapper{
    height: 50px;
    display: flex;
    align-items: center;
}

</style>