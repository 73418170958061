<template>
  <div id="wrapper">
    <Sidebar />
    <div id="page-content-wrapper">
      <div class="row">
        <HeaderGestion />
      </div>
      <div class="row">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-center pt-2">
              MODIFICATION D'UN PRODUIT
              <span class="material-icons">add_circle_outline</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- MESSAGE ERREUR OU SUCCESS -->
              <div
                v-if="erreur.length > 5"
                class="alert alert-danger alert-dismissible fade show mt-2"
                role="alert"
              >
                <strong>Erreur</strong>{{ erreur }}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                v-if="success"
                class="alert alert-success alert-dismissible fade show mt-2"
                role="alert"
              >
                <strong>L'item à été modifié avec succès</strong>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div class="row m-3">
            <div class="col-lg-7">
              <div class="row">
                <div class="col-12">
                  <p class="h3">Sélectionner un produit à modifier</p>
                </div>
                <div class="pb-5 col-6">
                  <div v-if="produits">
                    <!-- FORMULAIRE SELECTION DU PRODUIT -->
                    <select
                      v-model="selectedId"
                      class="form-select"
                      aria-label="Default select example"
                      @change="onSelectedProduit($event)"
                    >
                      <option disabled selected
                        >- Sélectionner un produit -</option
                      >
                      <option
                        v-for="produit in produits"
                        :key="produit.produitId"
                        :value="produit.produitId"
                        >{{ produit.nom }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <!-- AFFICHAGE INFO PRODUIT POUR MODIFICATION -->
              <div v-if="selectedProduit" class="col-12">
                <!-- UPLOAD FICHIER THUMBNAIL -->
                <section class="fieldset p-4">
                  <h1>Images principales du produit</h1>
                  <!-- UPLOAD FICHIER THUMBNAIL -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Image principale ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            id="main_thumb"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imageThumbUrl !== null && upImageThumb"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>

                  <!-- UPLOAD FICHIER HIGHRES -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage2">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image principale ( HighRes )</label
                          >
                          <input
                            class="form-control"
                            id="main_HighRes"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imageHighResUrl !== null && upImageHR"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                </section>
                <!-- UPLOAD IMAGE SECONDAIRE #1 -->
                <section class="fieldset p-4 mt-4">
                  <h1>Images secondaires #1 du produit</h1>
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage3">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Image secondaire #1 ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            type="file"
                            id="imgSec1Thumb"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgSec1ThumbUrl !== null && upImageSec1Th"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>

                  <!-- UPLOAD FICHIER HIGHRES SEC1  -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage4">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image secondaire #2 ( HighRes )</label
                          >
                          <input
                            class="form-control"
                            type="file"
                            id="imgSec1HighRes"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgSec1HighResUrl !== null && upImageSec1HR"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                </section>
                <!-- UPLOAD IMAGE SECONDAIRE #2 -->
                <section class="fieldset p-4 mt-4">
                  <h1>Images secondaires #2 du produit</h1>
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage5">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Secondaire #2 ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            type="file"
                            id="imgSec2Thumb"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgSec2ThumbUrl !== null && upImageSec2Th"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>

                  <!-- UPLOAD FICHIER HIGHRES SEC2 -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage6">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image Secondaire #2 ( HighRes )</label
                          >
                          <input
                            class="form-control"
                            type="file"
                            id="imgSec2HighRes"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgSec2HighResUrl !== null && upImageSec2HR"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                </section>

                <!-- INFO DE L'ITEM -->
                <form v-on:submit.prevent="handleModifs()">
                  <div class="row g-2 mt-1">
                    <!-- NOM PRODUIT -->
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="nomProduit"
                          class="form-control"
                          id="nom_produit_inventaire"
                          placeholder="Nom du produit"
                          required
                        />
                        <label for="nom_produit_inventaire"
                          >Nom du produit</label
                        >
                      </div>
                    </div>
                    <!-- ID PRODUIT -->
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="selectedId"
                          class="form-control"
                          id="id_produit_inventaire"
                          readonly
                          placeholder="Id du produit"
                          required
                        />
                        <label for="id_produit_inventaire">Id du produit</label>
                      </div>
                    </div>
                  </div>
                  <!-- SOUS-TITRE DU PRODUIT -->
                  <div class="col-md-12 mt-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="soustitreProduit"
                        class="form-control"
                        id="soustitre_produit_inventaire"
                        placeholder="Sous-titre du produit"
                        required
                      />
                      <label for="soustitre_produit_inventaire"
                        >Sous-titre du produit</label
                      >
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="form-floating input_link">
                      <input
                        type="text"
                        v-model="lienProduit"
                        class="form-control"
                        id="lien_produit_inventaire"
                        placeholder="Nom du produit"
                        required
                      />
                      <label for="nom_produit_inventaire">/LienProduit/</label>
                    </div>
                  </div>
                  <!-- DESCRIPTION DU PRODUIT -->
                  <div class="row g-2 mt-1">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        v-model="descriptionProduit"
                        placeholder="Description du produit"
                        id="floatingTextarea2"
                        required
                        style="height: 120px"
                      ></textarea>
                      <label for="floatingTextarea2"
                        >Description du produit</label
                      >
                    </div>
                  </div>
                  <!-- TYPE DE MESURE -->
                  <div class="row g-2 mt-1">
                    <!-- CHECKBOX PRODUITS -->
                    <div class="col-12">
                      <div
                        class="form-check form-switch d-flex justify-content-start"
                      >
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="dispo"
                          type="checkbox"
                          id="afficher__produit"
                          checked
                        />
                        <label class="form-check-label " for="afficher__produit"
                          >Afficher le produit dans la boutique</label
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="form-check form-switch d-flex justify-content-start"
                      >
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="display"
                          type="checkbox"
                          id="dispo__produit"
                          checked
                        />
                        <label class="form-check-label" for="dispo__produit"
                          >Permettre la vente de ce produit dans la
                          boutique</label
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="form-check form-switch d-flex justify-content-start"
                      >
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="isTaxable"
                          type="checkbox"
                          id="isTaxable__produit"
                          checked
                        />
                        <label class="form-check-label" for="isTaxable__produit"
                          >Ce produit est-il taxable ?</label
                        >
                      </div>
                    </div>

                    <div class="col-12">
                      <p>Combien de formats à ajouter pour ce produit</p>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="nbrFormats"
                        @change="changeNbrFormat"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                  </div>
                  <!-- SECTION FORMAT + TARIFICATION DE PRODUITS #1-->
                  <div class="row g-2 mt-1 pb-4 border-bottom">
                    <p>FORMAT #1</p>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="format1"
                          class="form-control"
                          id="format_produit1"
                          required
                          placeholder="Quantité Format #1"
                        />
                        <label for="format_produit1">Quantité Format #1</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="number"
                          v-model="prix1"
                          class="form-control"
                          id="prix_format1"
                          required
                          placeholder="Prix du format #1"
                          step="0.01"
                        />
                        <label for="prix_format1">Prix du format #1</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex justify-content-start align-items-center"
                    >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="disp1"
                          type="checkbox"
                          id="format1_disp"
                          checked
                        />
                        <label class="form-check-label" for="format1_disp"
                          >Format #1 disponible</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- SECTION FORMAT + TARIFICATION DE PRODUITS #2 -->
                  <div
                    v-if="nbrFormats > 1"
                    class="row g-2 mt-1 pb-2 border-bottom"
                  >
                    <p>FORMAT #2</p>
                    <!-- UPLOAD FORMAT 2 THUMBNAIL -->
                    <section class="fieldset p-4 mt-4">
                      <h1>Images Format #2</h1>
                      <!-- UPLOAD FICHIER THUMBNAIL -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat2Thumb">
                            <div>
                              <label for="formFile" class="form-label"
                                >Petite Image Format #2 ( Thumbnail )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat2Thumb"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageThumbUrl !== null && upImageF2Th"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>

                      <!-- UPLOAD FICHIER HIGHRES -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat2HighRes">
                            <div>
                              <label for="formFile" class="form-label"
                                >Grande Image Format #2 ( HighRes )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat2HighRes"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageHighResUrl !== null && upImageF2HR"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>
                    </section>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="format2"
                          class="form-control"
                          id="format_produit2"
                          placeholder="Quantité Format #2"
                        />
                        <label for="format_produit1">Quantité Format #2</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="number"
                          v-model="prix2"
                          class="form-control"
                          id="prix_format2"
                          placeholder="Prix du format #2"
                          step="0.01"
                        />
                        <label for="prix_format1">Prix du format #2</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex justify-content-start align-items-center"
                    >
                      <div class="form-check form-switch ">
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="disp2"
                          type="checkbox"
                          id="format2_disp"
                          checked
                        />
                        <label class="form-check-label" for="format2_disp"
                          >Format #2 disponible</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- AJOUT FORMAT #3 -->
                  <div
                    v-if="nbrFormats > 2"
                    class="row g-2 mt-1 pb-2 border-bottom"
                  >
                    <p>FORMAT #3</p>
                    <!-- UPLOAD FORMAT 3 THUMBNAIL -->
                    <section class="fieldset p-4 mt-4">
                      <h1>Images Format #3</h1>
                      <!-- UPLOAD FICHIER THUMBNAIL -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat3Thumb">
                            <div>
                              <label for="formFile" class="form-label"
                                >Petite Image Format #3 ( Thumbnail )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat3Thumb"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageThumbUrl !== null && upImageF3Th"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>

                      <!-- UPLOAD FICHIER HIGHRES -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat3HighRes">
                            <div>
                              <label for="formFile" class="form-label"
                                >Grande Image Format #3 ( HighRes )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat3HighRes"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageHighResUrl !== null && upImageF3HR"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>
                    </section>

                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="format3"
                          class="form-control"
                          id="format_produit3"
                          placeholder="Quantité Format #3"
                        />
                        <label for="format_produit1">Quantité Format #3</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="number"
                          v-model="prix3"
                          class="form-control"
                          id="prix_format3"
                          placeholder="Prix du format #3"
                          step="0.01"
                        />
                        <label for="prix_format1">Prix du format #3</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex justify-content-start align-items-center"
                    >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="disp3"
                          type="checkbox"
                          id="format3_disp"
                          checked
                        />
                        <label class="form-check-label" for="format3_disp"
                          >Format #3 disponible</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- AJOUT FORMAT #4 -->
                  <div
                    v-if="nbrFormats > 3"
                    class="row g-2 mt-1 pb-2 border-bottom"
                  >
                    <p>FORMAT #4</p>
                    <!-- UPLOAD FORMAT 4 THUMBNAIL -->
                    <section class="fieldset p-4 mt-4">
                      <h1>Images Format #4</h1>
                      <!-- UPLOAD FICHIER THUMBNAIL -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat4Thumb">
                            <div>
                              <label for="formFile" class="form-label"
                                >Petite Image Format #4 ( Thumbnail )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat4Thumb"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageThumbUrl !== null && upImageF4Th"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>

                      <!-- UPLOAD FICHIER HIGHRES -->
                      <div class="row">
                        <div class="col-11 mb-2">
                          <form class="formUploadFormat4HighRes">
                            <div>
                              <label for="formFile" class="form-label"
                                >Grande Image Format #4 ( HighRes )</label
                              >
                              <input
                                class="form-control"
                                id="imageFormat4HighRes"
                                type="file"
                                @change="onFileSelected"
                                required
                              />
                            </div>
                          </form>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                          <span
                            v-if="imageHighResUrl !== null && upImageF4HR"
                            class="material-icons text-success pb-3"
                            >check_circle_outline</span
                          >
                        </div>
                      </div>
                    </section>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          v-model="format4"
                          class="form-control"
                          id="format_produit4"
                          placeholder="Quantité Format #4"
                        />
                        <label for="format_produit1">Quantité Format #4</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <input
                          type="number"
                          v-model="prix4"
                          class="form-control"
                          id="prix_format4"
                          placeholder="Prix du format #4"
                          step="0.01"
                        />
                        <label for="prix_format1">Prix du format #4</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4 d-flex justify-content-start align-items-center"
                    >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input checkbox_inventaire"
                          v-model="disp4"
                          type="checkbox"
                          id="format4_disp"
                          checked
                        />
                        <label class="form-check-label" for="format3_disp"
                          >Format #4 disponible</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- BOUTON FONCTION -->
                  <div class="col-1"></div>
                  <div class="col-5">
                    <button class="bouton_inventaire" type="submit">
                      Soumettre les modifications
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- DISPLAY DU PRODUIT SELECTIONNE -->
            <div class="col-md-5">
              <h3>DISPLAY PRODUIT</h3>

              <div>
                <div class="h5 fieldset mt-4" v-if="imageThumbUrl">
                  <h1>Image Principale Thumbnail</h1>
                  <img
                    :src="imageThumbUrl"
                    alt="Image Principale Thumbnail"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imageHighResUrl">
                  <h1>Image Principale HighRes</h1>
                  <img
                    :src="imageHighResUrl"
                    alt="Image Principale HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgSec1ThumbUrl">
                  <h1>Image Sec #1 Thumbnail</h1>
                  <img
                    :src="imgSec1ThumbUrl"
                    alt="Image Sec #1 Thumbnail"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgSec1HighResUrl">
                  <h1>Image Sec #1 HighRes</h1>
                  <img
                    :src="imgSec1HighResUrl"
                    alt="Image Sec #1 HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgSec2ThumbUrl">
                  <h1>Image Sec #2 Thumbnail</h1>
                  <img
                    :src="imgSec2ThumbUrl"
                    alt="Image Sec #2 Thumbnail"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgSec2HighResUrl">
                  <h1>Image Sec #2 HighRes</h1>
                  <img
                    :src="imgSec2HighResUrl"
                    alt="Image Sec #2 HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat2ThumbUrl">
                  <h1>Image Format #2 Thumb</h1>
                  <img
                    :src="imgFormat2ThumbUrl"
                    alt="Image Sec #2 Thumb"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat2HighResUrl">
                  <h1>Image Format #2 HighRes</h1>
                  <img
                    :src="imgFormat2HighResUrl"
                    alt="Image Sec #2 HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat3ThumbUrl">
                  <h1>Image Format #3 Thumb</h1>
                  <img
                    :src="imgFormat3ThumbUrl"
                    alt="Image Sec #3 Thumb"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat3HighResUrl">
                  <h1>Image Format #3 HighRes</h1>
                  <img
                    :src="imgFormat3HighResUrl"
                    alt="Image Sec #3 HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat4ThumbUrl">
                  <h1>Image Format #4 Thumb</h1>
                  <img
                    :src="imgFormat4ThumbUrl"
                    alt="Image Sec #4 Thumb"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>

                <div class="h5 fieldset mt-4" v-if="imgFormat4HighResUrl">
                  <h1>Image Format #4 HighRes</h1>
                  <img
                    :src="imgFormat4HighResUrl"
                    alt="Image Sec #4 HighRes"
                    class="img-fluid p-4 img_edit_inventaire"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// IMPORT COMPOSANTS
import Sidebar from '../Sidebar.vue'
import HeaderGestion from '../HeaderGestion.vue'
import getProduits from '../../../composables/getProduits'

// FIREBASE STORAGE //
import { projectFirestore } from '../../../../src/firebase/config'
import firebase from 'firebase/app'
import 'firebase/storage'
import { ref } from 'vue'

export default {
  name: 'InventaireModif',
  components: { Sidebar, HeaderGestion },
  setup () {
    // SELECTION D'UN PRODUIT EN PARTICULIER
    // VARIABLES DE FORMULAIRE
    // PRODUIT GLOBALE
    const uploadFrom = ref('')
    const nomProduit = ref('')
    const soustitreProduit = ref('')
    const isTaxable = ref(false)
    const lienProduit = ref('')
    const descriptionProduit = ref('')
    const dispo = ref('')
    const display = ref('')
    const multiFormat = ref('')
    // VARIABLES UPLOAD
    const selectedFile = ref('')

    const imageHighResUrl = ref('')
    const imgSec1HighResUrl = ref('')
    const imgSec2HighResUrl = ref('')
    const imageThumbUrl = ref('')
    const imgSec1ThumbUrl = ref('')
    const imgSec2ThumbUrl = ref('')
    const imgFormat2ThumbUrl = ref('')
    const imgFormat2HighResUrl = ref('')
    const imgFormat3ThumbUrl = ref('')
    const imgFormat3HighResUrl = ref('')
    const imgFormat4ThumbUrl = ref('')
    const imgFormat4HighResUrl = ref('')

    const upImageThumb = ref(false)
    const upImageHR = ref(false)
    const upImageSec1Th = ref(false)
    const upImageSec1HR = ref(false)
    const upImageSec2Th = ref(false)
    const upImageSec2HR = ref(false)

    const upImageF2Th = ref(false)
    const upImageF2HR = ref(false)

    const upImageF3Th = ref(false)
    const upImageF3HR = ref(false)

    const upImageF4Th = ref(false)
    const upImageF4HR = ref(false)

    const nbrFormats = ref(1)
    const erreur = ref('')
    const success = ref('')
    // PAR FORMAT PRODUITS

    const prix1 = ref('')
    const format1 = ref('')
    const disp1 = ref(true)

    const prix2 = ref('')
    const format2 = ref('')
    const disp2 = ref(true)

    const prix3 = ref('')
    const format3 = ref('')
    const disp3 = ref(true)

    const prix4 = ref('')
    const format4 = ref('')
    const disp4 = ref(true)

    var modifsList = {}
    var modifsListUrl = {}

    // LISTING DES PRODUITS
    const { produits, error, load } = getProduits()
    load()

    // GESTION NOMBRE DE FORMATS POUR LE PRODUIT
    const setNbrFormat = (siPlusieurs) => {
      if (siPlusieurs) {
        nbrFormats.value = 2
      } else {
        nbrFormats.value = 1
      }
    }

    // AFFICHAGE DES PRODUITS

    const selectedProduit = ref('') //  check
    const selectedId = ref('- Sélectionner un produit -')

    const statusModif = ref()

    const onSelectedProduit = () => {
      selectedProduit.value = produits._value.find(
        (obj) => obj.produitId == selectedId.value
      ) // ASSOCIER VALUER DU PRODUIT SELECTIONER DANS LE SELECT BOX
      resetSelectedProduit()
      updateInfosProduit()
    }

    const resetSelectedProduit = () => {
      // RESET INFOS AVANT NOUVEAU PRODUITS
      nomProduit.value = ''
      soustitreProduit.value = ''
      lienProduit.value = ''
      descriptionProduit.value = ''
      dispo.value = ''
      display.value = ''
      multiFormat.value = ''

      // VARIABLES UPLOAD

      imageHighResUrl.value = null
      imgSec1HighResUrl.value = null
      imgSec2HighResUrl.value = null
      imageThumbUrl.value = null
      imgSec1ThumbUrl.value = null
      imgSec2ThumbUrl.value = null
      imgFormat2ThumbUrl.value = null
      imgFormat2HighResUrl.value = null
      imgFormat3ThumbUrl.value = null
      imgFormat3HighResUrl.value = null
      imgFormat4ThumbUrl.value = null
      imgFormat4HighResUrl.value = null

      nbrFormats.value = ref(1)

      // PAR FORMAT PRODUITS

      prix1.value = ref('')
      format1.value = ref('')
      disp1.value = ref('')

      prix2.value = ref('')
      format2.value = ref('')
      disp2.value = ref('')

      prix3.value = ref('')
      format3.value = ref('')
      disp3.value = ref('')

      prix4.value = ref('')
      format4.value = ref('')
      disp4.value = ref('')
    }

    // GET INFOS DU PRODUIT EN PARTICULIER
    const updateInfosProduit = () => {
      nomProduit.value = selectedProduit.value.nom
      soustitreProduit.value = selectedProduit.value.soustitreProduit
      isTaxable.value = selectedProduit.value.isTaxable
      lienProduit.value = selectedProduit.value.lienProduit
      descriptionProduit.value = selectedProduit.value.description
      dispo.value = selectedProduit.value.dispo
      display.value = selectedProduit.value.display
      multiFormat.value = selectedProduit.value.multiFormat
      nbrFormats.value = selectedProduit.value.tarification.length // NOMBRE DE FORMAT

      // FICHIERS IMAGES
      imageThumbUrl.value = selectedProduit.value.imageThumb
      imageHighResUrl.value = selectedProduit.value.imageHighRes
      imgSec1ThumbUrl.value = selectedProduit.value.imageSec1Thumb
      imgSec1HighResUrl.value = selectedProduit.value.imageSec1HighRes
      imgSec2ThumbUrl.value = selectedProduit.value.imageSec2Thumb
      imgSec2HighResUrl.value = selectedProduit.value.imageSec2HighRes

      if (nbrFormats.value >= 2) {
        imgFormat2ThumbUrl.value = selectedProduit.value.imageFormat2Thumb
        imgFormat2HighResUrl.value = selectedProduit.value.imageFormat2HighRes
      }
      if (nbrFormats.value >= 3) {
        imgFormat3ThumbUrl.value = selectedProduit.value.imageFormat3Thumb
        imgFormat3HighResUrl.value = selectedProduit.value.imageFormat3HighRes
      }
      if (nbrFormats.value == 4) {
        imgFormat4ThumbUrl.value = selectedProduit.value.imageFormat4Thumb
        imgFormat4HighResUrl.value = selectedProduit.value.imageFormat4HighRes
      }

      // ASSOCIER LES VARIABLE A RIEN POUR NOMBRE DE FORMATS FUTURS

      prix2.value = ''
      format2.value = ''
      disp2.value = true

      prix3.value = ''
      format3.value = ''
      disp3.value = true

      prix4.value = ''
      format4.value = ''
      disp4.value = true

      if (nbrFormats.value >= 1) {
        prix1.value = selectedProduit.value.tarification[0].prix
        format1.value = selectedProduit.value.tarification[0].format
        disp1.value = selectedProduit.value.tarification[0].disp

        modifsListUrl = {
          imageThumb: selectedProduit.value.imageThumb,
          imageHighRes: selectedProduit.value.imageHighRes,
          imageSec1Thumb: selectedProduit.value.imageSec1Thumb,
          imageSec1HighRes: selectedProduit.value.imageSec1HighRes,
          imageSec2HighRes: selectedProduit.value.imageSec2HighRes,
          imageSec2Thumb: selectedProduit.value.imageSec2Thumb
        }
      }
      if (nbrFormats.value >= 2) {
        prix2.value = selectedProduit.value.tarification[1].prix
        format2.value = selectedProduit.value.tarification[1].format
        disp2.value = selectedProduit.value.tarification[1].disp

        modifsListUrl = {
          imageThumb: selectedProduit.value.imageThumb,
          imageHighRes: selectedProduit.value.imageHighRes,
          imageSec1Thumb: selectedProduit.value.imageSec1Thumb,
          imageSec1HighRes: selectedProduit.value.imageSec1HighRes,
          imageSec2HighRes: selectedProduit.value.imageSec2HighRes,
          imageSec2Thumb: selectedProduit.value.imageSec2Thumb,
          imageFormat2Thumb: selectedProduit.value.imageFormat2Thumb,
          imageFormat2HighRes: selectedProduit.value.imageFormat2HighRes
        }
      }
      if (nbrFormats.value >= 3) {
        prix3.value = selectedProduit.value.tarification[2].prix
        format3.value = selectedProduit.value.tarification[2].format
        disp3.value = selectedProduit.value.tarification[2].disp

        modifsListUrl = {
          imageThumb: selectedProduit.value.imageThumb,
          imageHighRes: selectedProduit.value.imageHighRes,
          imageSec1Thumb: selectedProduit.value.imageSec1Thumb,
          imageSec1HighRes: selectedProduit.value.imageSec1HighRes,
          imageSec2HighRes: selectedProduit.value.imageSec2HighRes,
          imageSec2Thumb: selectedProduit.value.imageSec2Thumb,
          imageFormat2Thumb: selectedProduit.value.imageFormat2Thumb,
          imageFormat2HighRes: selectedProduit.value.imageFormat2HighRes,
          imageFormat3Thumb: selectedProduit.value.imageFormat3Thumb,
          imageFormat3HighRes: selectedProduit.value.imageFormat3HighRes
        }
      }
      if (nbrFormats.value == 4) {
        prix4.value = selectedProduit.value.tarification[3].prix
        format4.value = selectedProduit.value.tarification[3].format
        disp4.value = selectedProduit.value.tarification[3].disp

        modifsListUrl = {
          imageThumb: selectedProduit.value.imageThumb,
          imageHighRes: selectedProduit.value.imageHighRes,
          imageSec1Thumb: selectedProduit.value.imageSec1Thumb,
          imageSec1HighRes: selectedProduit.value.imageSec1HighRes,
          imageSec2HighRes: selectedProduit.value.imageSec2HighRes,
          imageSec2Thumb: selectedProduit.value.imageSec2Thumb,
          imageFormat2Thumb: selectedProduit.value.imageFormat2Thumb,
          imageFormat2HighRes: selectedProduit.value.imageFormat2HighRes,
          imageFormat3Thumb: selectedProduit.value.imageFormat3Thumb,
          imageFormat3HighRes: selectedProduit.value.imageFormat3HighRes,
          imageFormat4Thumb: selectedProduit.value.imageFormat4Thumb,
          imageFormat4HighRes: selectedProduit.value.imageFormat4HighRes
        }
      }
    }

    // UPDATE DES INFOS + IMAGES DE PRODUITS

    // SELECTION DU FICHIER PLUS NOMMER LE FICHIER THUMBNAIL

    const nomFichier = ref('')
    const onFileSelected = (event) => {
      uploadFrom.value = event.target.id
      selectedFile.value = event.target.files[0]

      // INSERER RANDOM 5 CARAC
      nomFichier.value = [
        selectedFile.value._value.name.slice(0, selectedFile.value._value.name.length - 4),
        '_' + randomString(5, uploadFrom.value),
        selectedFile.value._value.name.slice(selectedFile.value._value.name.length - 4)
      ].join('')
      onUploadImage(uploadFrom.value)
    }
    const randomString = (length, photoType) => {
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result + '_' + photoType
    }

    // UPLOAD THUMBNAIL
    const onUploadImage = (typePhoto) => {
      let tempMod = {}

      const sendFile = firebase
        .storage()
        .ref('imagesProduits/' + nomFichier.value)
        .put(selectedFile.value)
      sendFile.on(
        'state_changed',
        (snapshot) => {
          // PROGRESS
        },
        (error) => {
          // ERREUR
          console.log(error)
        },
        () => {
          // COMPLETE

          firebase
            .storage()
            .ref('imagesProduits')
            .child(nomFichier.value)
            .getDownloadURL()
            .then((url) => {
              console.log(modifsListUrl)

              switch (typePhoto) {
                case 'main_thumb':
                  tempMod = { imageThumb: url }
                  imageThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageThumb.value = true
                  break
                case 'main_HighRes':
                  tempMod = { imageHighRes: url }
                  imageHighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageHR.value = true
                  break
                case 'imgSec1Thumb':
                  tempMod = { imageSec1Thumb: url }
                  imgSec1ThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageSec1Th.value = true
                  break
                case 'imgSec1HighRes':
                  tempMod = { imageSec1HighRes: url }
                  imgSec1HighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageSec1HR.value = true
                  break
                case 'imgSec2Thumb':
                  tempMod = { imageSec2Thumb: url }
                  imgSec2ThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageSec2Th.value = true
                  break
                case 'imgSec2HighRes':
                  tempMod = { imageSec2HighRes: url }
                  imgSec2HighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageSec2HR.value = true
                  break
                case 'imageFormat2Thumb':
                  tempMod = { imageFormat2Thumb: url }
                  imgFormat2ThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF2Th.value = true
                  break
                case 'imageFormat2HighRes':
                  tempMod = { imageFormat2HighRes: url }
                  imgFormat2HighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF2HR.value = true
                  break
                case 'imageFormat3Thumb':
                  tempMod = { imageFormat3Thumb: url }
                  imgFormat3ThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF3Th.value = true
                  break
                case 'imageFormat3HighRes':
                  tempMod = { imageFormat3HighRes: url }
                  imgFormat3HighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF3HR.value = true
                  break
                case 'imageFormat4Thumb':
                  tempMod = { imageFormat4Thumb: url }
                  imgFormat4ThumbUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF4Th.value = true
                  break
                case 'imageFormat4HighRes':
                  tempMod = { imageFormat4HighRes: url }
                  imgFormat4HighResUrl.value = url
                  modifsListUrl = { ...modifsListUrl, ...tempMod }
                  upImageF4HR.value = true
                  break

                default:
                  // ERREUR
                  console.log(typePhoto)
                  alert("Une erreur est survenue lors de l'envoie de la photo")
              }
            })
        }
      )
    }

    var priceListFormat = {}

    // GESTION NOMBRE DE FORMATS POUR LE PRODUIT

    const changeNbrFormat = () => {
      if (nbrFormats.value == 1) {
        multiFormat.value = false
      } else {
        multiFormat.value = true
      }
    }

    const handleModifs = () => {
      console.log(parseFloat(prix1.value).toFixed(2))

      modifsList = {
        nom: nomProduit.value,
        soustitreProduit: soustitreProduit.value,
        lienProduit: lienProduit.value,
        description: descriptionProduit.value,
        display: display.value,
        dispo: dispo.value,
        multiFormat: multiFormat.value,
        isTaxable: isTaxable.value,
        deleted: false
      }
      if (nbrFormats.value == 1) {
        priceListFormat = {
          tarification: [
            {
              format: format1.value,
              prix: parseFloat(prix1.value),
              disp: disp1.value
            }
          ]
        }
      } else if (nbrFormats.value == 2) {
        priceListFormat = {
          tarification: [
            {
              format: format1.value,
              prix: parseFloat(prix1.value),
              disp: disp1.value
            },
            {
              format: format2.value,
              prix: parseFloat(prix2.value),
              disp: disp2.value
            }
          ]
        }
      } else if (nbrFormats.value == 3) {
        priceListFormat = {
          tarification: [
            {
              format: format1.value,
              prix: parseFloat(prix1.value),
              disp: disp1.value
            },
            {
              format: format2.value,
              prix: parseFloat(prix2.value),
              disp: disp2.value
            },
            {
              format: format3.value,
              prix: parseFloat(prix3.value),
              disp: disp3.value
            }
          ]
        }
      } else if (nbrFormats.value == 4) {
        priceListFormat = {
          tarification: [
            {
              format: format1.value,
              prix: parseFloat(prix1.value),
              disp: disp1.value
            },
            {
              format: format2.value,
              prix: parseFloat(prix2.value),
              disp: disp2.value
            },
            {
              format: format3.value,
              prix: parseFloat(prix3.value),
              disp: disp3.value
            },
            {
              format: format4.value,
              prix: parseFloat(prix4.value),
              disp: disp4.value
            }
          ]
        }
      }

      modifsList = { ...modifsList, ...priceListFormat, ...modifsListUrl }

      let countLiensProduit = 0 // VERIFICATION SU LIEN PRODUIT EST UNIQUE

      var erreurFormatsModifs = false

      if (nbrFormats.value == 1) {
        if (
          modifsList.imageThumb == undefined ||
          modifsList.imageHighRes == undefined ||
          modifsList.imageSec1Thumb == undefined ||
          modifsList.imageSec1HighRes == undefined
        ) {
          erreurFormatsModifs = true
        }
        if (modifsList.imageFormat2Thumb != undefined) {
          delete modifsList.imageFormat2Thumb
        }
        if (modifsList.imageFormat2HighRes != undefined) {
          delete modifsList.imageFormat2HighRes
        }
        if (modifsList.imageFormat3Thumb != undefined) {
          delete modifsList.imageFormat3Thumb
        }
        if (modifsList.imageFormat3HighRes != undefined) {
          delete modifsList.imageFormat3HighRes
        }
        if (modifsList.imageFormat4Thumb != undefined) {
          delete modifsList.imageFormat4Thumb
        }
        if (modifsList.imageFormat4HighRes != undefined) {
          delete modifsList.imageFormat4HighRes
        }
      } else if (nbrFormats.value == 2) {
        if (
          modifsList.imageThumb == undefined ||
          modifsList.imageHighRes == undefined ||
          modifsList.imageSec1Thumb == undefined ||
          modifsList.imageSec1HighRes == undefined ||
          modifsList.imageFormat2Thumb == undefined ||
          modifsList.imageFormat2HighRes == undefined
        ) {
          erreurFormatsModifs = true
        }
        if (modifsList.imageFormat3Thumb != undefined) {
          delete modifsList.imageFormat3Thumb
        }
        if (modifsList.imageFormat3HighRes != undefined) {
          delete modifsList.imageFormat3HighRes
        }
        if (modifsList.imageFormat4Thumb != undefined) {
          delete modifsList.imageFormat4Thumb
        }
        if (modifsList.imageFormat4HighRes != undefined) {
          delete modifsList.imageFormat4HighRes
        }
      } else if (nbrFormats.value == 3) {
        if (
          modifsList.imageThumb == undefined ||
          modifsList.imageHighRes == undefined ||
          modifsList.imageSec1Thumb == undefined ||
          modifsList.imageSec1HighRes == undefined ||
          modifsList.imageFormat2Thumb == undefined ||
          modifsList.imageFormat2HighRes == undefined ||
          modifsList.imageFormat3Thumb == undefined ||
          modifsList.imageFormat3HighRes == undefined
        ) {
          erreurFormatsModifs = true
        }
        if (modifsList.imageFormat4Thumb != undefined) {
          delete modifsList.imageFormat4Thumb
        }
        if (modifsList.imageFormat4HighRes != undefined) {
          delete modifsList.imageFormat4HighRes
        }
      } else if (nbrFormats.value == 4) {
        if (
          modifsList.imageThumb == undefined ||
          modifsList.imageHighRes === undefined ||
          modifsList.imageSec1Thumb == undefined ||
          modifsList.imageSec1HighRes === undefined ||
          modifsList.imageFormat2Thumb == undefined ||
          modifsList.imageFormat2HighRes == undefined ||
          modifsList.imageFormat3Thumb == undefined ||
          modifsList.imageFormat3HighRes == undefined ||
          modifsList.imageFormat4Thumb == undefined ||
          modifsList.imageFormat4HighRes == undefined
        ) {
          erreurFormatsModifs = true
          console.log(erreurFormatsModifs)
        }
      }

      if (selectedProduit.value.lienProduit != lienProduit.value) {
        produits._value.forEach((produit) => {
          if (produit.lienProduit == lienProduit.value) {
            countLiensProduit++ //
          }
        })
        if (countLiensProduit == 0) {
          if (!erreurFormatsModifs) {
            submitModifs() // OK POUR UPDATER VALUES
          } else {
            erreur.value =
              ' Vérifier que toutes les photos pour chaque formats sont téléversées'
          }
        } else {
          erreur.value =
            ' Le lien produit est déja utilisé pour un autre produit, veuillez choisir un lien unique.'
        }
      } else {
        if (!erreurFormatsModifs) {
          submitModifs() // OK POUR UPDATER VALUES
        } else {
          erreur.value =
            ' Vérifier que toutes les photos pour chaque formats sont téléversées'
        }
      }
    }

    const submitModifs = async () => {
      const res = await projectFirestore
        .collection('inventaire')
        .doc(selectedId.value)
        .set(modifsList) // UPDATE OBJET L'OBJET
      success.value = "L'item a été modifié avec success"
    }

    return {
      produits,
      error,
      nomProduit,
      descriptionProduit,
      dispo,
      display,
      multiFormat,
      selectedFile,
      statusModif,
      changeNbrFormat,
      isTaxable,
      nbrFormats,
      erreur,
      success,
      prix1,
      format1,
      disp1,
      prix2,
      format2,
      disp2,
      prix3,
      format3,
      disp3,
      prix4,
      uploadFrom,
      lienProduit,
      soustitreProduit,
      format4,
      disp4,
      updateInfosProduit,
      selectedProduit,
      onSelectedProduit,
      selectedId,
      setNbrFormat,
      imgSec2HighResUrl,
      imgSec1ThumbUrl,
      imgSec1HighResUrl,
      imgSec2ThumbUrl,
      imageThumbUrl,
      imgSec2ThumbUrl,
      imageHighResUrl,
      resetSelectedProduit,
      onFileSelected,
      nomFichier,
      modifsList,
      handleModifs,
      submitModifs,
      priceListFormat,
      modifsListUrl,
      imgFormat2ThumbUrl,
      imgFormat2HighResUrl,
      imgFormat3ThumbUrl,
      imgFormat3HighResUrl,
      imgFormat4ThumbUrl,
      imgFormat4HighResUrl,
      upImageThumb,
      upImageHR,
      upImageSec1Th,
      upImageSec1HR,
      upImageSec2Th,
      upImageSec2HR,
      upImageF2Th,
      upImageF2HR,
      upImageF3Th,
      upImageF3HR,
      upImageF4Th,
      upImageF4HR
    }
  }
}
</script>

<style scoped>
.img_edit_inventaire {
  height: 200px;
}
</style>
