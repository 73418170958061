<template>
  <div id="wrapper">
    <Sidebar />
    <div id="page-content-wrapper">
      <div class="row">
        <HeaderGestion />
      </div>
      <div class="row">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-center pt-2">
              AJOUT D'UN PRODUIT
              <span class="material-icons">add_circle_outline</span>
            </h2>
          </div>
          <div class="row m-3">
            <div class="col-lg-7">
              <h3 class="mb-4">Informations du nouveau produit</h3>
              <section class="fieldset p-4">
                <h1>Images principales du produit</h1>
                <!-- UPLOAD FICHIER THUMBNAIL -->
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage">
                      <div>
                        <label for="formFile" class="form-label"
                          >Petite Image principale ( Thumbnail )</label
                        >
                        <input
                          class="form-control"
                          id="main_thumb"
                          type="file"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgThumbUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>

                <!-- UPLOAD FICHIER HIGHRES -->
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage2">
                      <div>
                        <label for="formFile" class="form-label"
                          >Grande Image principale ( HighRes )</label
                        >
                        <input
                          class="form-control"
                          id="main_HighRes"
                          type="file"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgHighResUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>
              </section>
              <!-- UPLOAD IMAGE SECONDAIRE #1 -->
              <section class="fieldset p-4 mt-4">
                <h1>Images secondaires #1 du produit</h1>
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage3">
                      <div>
                        <label for="formFile" class="form-label"
                          >Petite Image secondaire #1 ( Thumbnail )</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="imgSec1Thumb"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgSec1ThumbUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>
                <!-- UPLOAD FICHIER HIGHRES -->
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage4">
                      <div>
                        <label for="formFile" class="form-label"
                          >Grande Image secondaire #2 ( HighRes )</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="imgSec1HighRes"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgSec1HighResUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>
              </section>
              <!-- UPLOAD IMAGE SECONDAIRE #2 -->
              <section class="fieldset p-4 mt-4">
                <h1>Images secondaires #2 du produit</h1>
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage5">
                      <div>
                        <label for="formFile" class="form-label"
                          >Petite Secondaire #2 ( Thumbnail )</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="imgSec2Thumb"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgSec2ThumbUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>

                <!-- UPLOAD FICHIER HIGHRES -->
                <div class="row">
                  <div class="col-11 mb-2">
                    <form class="formUploadImage6">
                      <div>
                        <label for="formFile" class="form-label"
                          >Grande Image Secondaire #2 ( HighRes )</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="imgSec2HighRes"
                          @change="onFileSelected"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div class="col-1 d-flex align-items-end">
                    <span
                      v-if="imgSec2HighResUrl !== null"
                      class="material-icons text-success pb-3"
                      >check_circle_outline</span
                    >
                  </div>
                </div>
              </section>
              <!-- INFO DE L'ITEM -->
              <form v-on:submit.prevent="handleSubmit()">
                <div class="row g-2 mt-1">
                  <!-- NOM PRODUIT -->
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="nomProduit"
                        class="form-control"
                        id="nom_produit_inventaire"
                        placeholder="Nom du produit"
                        required
                      />
                      <label for="nom_produit_inventaire">Nom du produit</label>
                    </div>
                  </div>
                  <!-- ID PRODUIT -->
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="id_produit_inventaire"
                        readonly
                        placeholder="Id du produit N/A"
                        value="N/A"
                        required
                      />
                      <label for="id_produit_inventaire">Id du produit</label>
                    </div>
                  </div>
                </div>
                <!-- SOUS-TITRE DU PRODUIT -->
                <div class="col-md-12 mt-2">
                  <div class="form-floating">
                    <input
                      type="text"
                      v-model="soustitreProduit"
                      class="form-control"
                      id="soustitre_produit_inventaire"
                      placeholder="Sous-titre du produit"
                      required
                    />
                    <label for="soustitre_produit_inventaire"
                      >Sous-titre du produit</label
                    >
                  </div>
                </div>
                <!-- DESCRIPTION DU PRODUIT -->
                <div class="row g-2 mt-1">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      v-model="descriptionProduit"
                      placeholder="Description du produit"
                      id="floatingTextarea2"
                      required
                      style="height: 120px"
                    ></textarea>
                    <label for="floatingTextarea2"
                      >Description du produit</label
                    >
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-floating input_link">
                    <input
                      type="text"
                      v-model="lienProduit"
                      class="form-control"
                      id="lien_produit_inventaire"
                      placeholder="Nom du produit"
                      required
                    />
                    <label for="nom_produit_inventaire">/LienProduit/</label>
                  </div>
                </div>
                <!-- TYPE DE MESURE -->
                <div class="row g-2 mt-1">
                  <!-- CHECKBOX PRODUITS -->
                  <div class="col-12">
                    <div
                      class="form-check form-switch d-flex justify-content-start"
                    >
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="dispo"
                        type="checkbox"
                        id="afficher__produit"
                        checked
                      />
                      <label class="form-check-label " for="afficher__produit"
                        >Afficher le produit dans la boutique</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="form-check form-switch d-flex justify-content-start"
                    >
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="display"
                        type="checkbox"
                        id="dispo__produit"
                        checked
                      />
                      <label class="form-check-label" for="dispo__produit"
                        >Permettre la vente de ce produit dans la
                        boutique</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="form-check form-switch d-flex justify-content-start"
                    >
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="isTaxable"
                        type="checkbox"
                        id="isTaxable__produit"
                        checked
                      />
                      <label class="form-check-label" for="isTaxable__produit"
                        >Ce produit est-il taxable ?</label
                      >
                    </div>
                  </div>

                  <div class="col-12">
                    <p>Combien de formats à ajouter pour ce produit</p>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="nbrFormats"
                      @change="changeNbrFormat"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>
                <!-- SECTION FORMAT + TARIFICATION DE PRODUITS #1-->
                <div class="row g-2 mt-1 pb-4 border-bottom">
                  <p>FORMAT #1</p>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="format1"
                        class="form-control"
                        id="format_produit1"
                        required
                        placeholder="Quantité Format #1"
                      />
                      <label for="format_produit1">Quantité Format #1</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="number"
                        v-model="prix1"
                        class="form-control"
                        id="prix_format1"
                        required
                        placeholder="Prix du format #1"
                        step="0.01"
                      />
                      <label for="prix_format1">Prix du format #1</label>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex justify-content-start align-items-center"
                  >
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="disp1"
                        type="checkbox"
                        id="format1_disp"
                        checked
                      />
                      <label class="form-check-label" for="format1_disp"
                        >Format #1 disponible</label
                      >
                    </div>
                  </div>
                </div>
                <!-- SECTION FORMAT + TARIFICATION DE PRODUITS #2 -->
                <div
                  v-if="nbrFormats > 1"
                  class="row g-2 mt-1 pb-2 border-bottom"
                >
                  <p>FORMAT #2</p>
                  <!-- UPLOAD FICHIER THUMBNAIL -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Image Format #2 ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat2Thumb"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat2Thumb !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <!-- UPLOAD FICHIER HIGH RES -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image Format #2 ( High Res )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat2HighRes"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat2HighRes !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="format2"
                        class="form-control"
                        id="format_produit2"
                        placeholder="Quantité Format #2"
                      />
                      <label for="format_produit1">Quantité Format #2</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="number"
                        v-model="prix2"
                        class="form-control"
                        id="prix_format2"
                        placeholder="Prix du format #2"
                        step="0.01"
                      />
                      <label for="prix_format1">Prix du format #2</label>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex justify-content-start align-items-center"
                  >
                    <div class="form-check form-switch ">
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="disp2"
                        type="checkbox"
                        id="format2_disp"
                        checked
                      />
                      <label class="form-check-label" for="format2_disp"
                        >Format #2 disponible</label
                      >
                    </div>
                  </div>
                </div>
                <!-- AJOUT FORMAT #3 -->
                <div
                  v-if="nbrFormats > 2"
                  class="row g-2 mt-1 pb-2 border-bottom"
                >
                  <p>FORMAT #3</p>
                  <!-- UPLOAD FICHIER THUMBNAIL -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Image Format #2 ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat3Thumb"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat3Thumb !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <!-- UPLOAD FICHIER HIGH RES -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image Format #3 ( High Res )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat3HighRes"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat3HighRes !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="format3"
                        class="form-control"
                        id="format_produit3"
                        placeholder="Quantité Format #3"
                      />
                      <label for="format_produit1">Quantité Format #3</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="number"
                        v-model="prix3"
                        class="form-control"
                        id="prix_format3"
                        placeholder="Prix du format #3"
                        step="0.01"
                      />
                      <label for="prix_format1">Prix du format #3</label>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex justify-content-start align-items-center"
                  >
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="disp3"
                        type="checkbox"
                        id="format3_disp"
                        checked
                      />
                      <label class="form-check-label" for="format3_disp"
                        >Format #3 disponible</label
                      >
                    </div>
                  </div>
                </div>
                <!-- AJOUT FORMAT #4 -->
                <div
                  v-if="nbrFormats > 3"
                  class="row g-2 mt-1 pb-2 border-bottom"
                >
                  <p>FORMAT #4</p>
                  <!-- UPLOAD FICHIER THUMBNAIL -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Petite Image Format #4 ( Thumbnail )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat4Thumb"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat4Thumb !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <!-- UPLOAD FICHIER HIGH RES -->
                  <div class="row">
                    <div class="col-11 mb-2">
                      <form class="formUploadImage">
                        <div>
                          <label for="formFile" class="form-label"
                            >Grande Image Format #4 ( High Res )</label
                          >
                          <input
                            class="form-control"
                            id="imgFormat4HighRes"
                            type="file"
                            @change="onFileSelected"
                            required
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-1 d-flex align-items-end">
                      <span
                        v-if="imgFormat4HighRes !== null"
                        class="material-icons text-success pb-3"
                        >check_circle_outline</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="text"
                        v-model="format4"
                        class="form-control"
                        id="format_produit4"
                        placeholder="Quantité Format #4"
                      />
                      <label for="format_produit1">Quantité Format #4</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-floating">
                      <input
                        type="number"
                        v-model="prix4"
                        class="form-control"
                        id="prix_format4"
                        placeholder="Prix du format #4"
                        step="0.01"
                      />
                      <label for="prix_format1">Prix du format #4</label>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex justify-content-start align-items-center"
                  >
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input checkbox_inventaire"
                        v-model="disp4"
                        type="checkbox"
                        id="format4_disp"
                        checked
                      />
                      <label class="form-check-label" for="format3_disp"
                        >Format #4 disponible</label
                      >
                    </div>
                  </div>
                </div>
                <!-- BOUTON FONCTION -->
                <div class="row mt-2">
                  <div class="col-12">
                    <!-- MESSAGE ERREUR OU SUCCESS -->
                    <div
                      v-if="erreur.length > 5"
                      class="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Erreur</strong>{{ erreur }}
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      v-if="success"
                      class="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>L'item à été ajouté avec success</strong>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <button class="bouton_inventaire" type="submit">
                      Soumettre le produit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-5">
              <h3 class="mb-4">Visualisation du produit</h3>

              <div
                v-if="imgThumbUrl != null"
                class="thumb_img_inventaire mx-auto"
              >
                <img :src="imgThumbUrl" class="img-fluid" />
              </div>
              <div v-else class="thumb_img_inventaire mx-auto">
                <div class="canvaThumb">
                  <div>
                    <p class="info_img_thumb">458 X 462</p>
                    <p class="info_img_thumb">938 X 946</p>
                  </div>
                </div>
              </div>
              <h5>Nom : {{ nomProduit }}</h5>
              <p>Description : {{ descriptionProduit }}</p>
            </div>
          </div>

          <div class="col-md-6 pt-4 m-5">
            <div v-if="imgHighResUrl != null">
              <img :src="imgHighResUrl" class="imgagePreview" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// IMPORT COMPOSANTS
import Sidebar from "../Sidebar.vue";
import HeaderGestion from "../HeaderGestion.vue";
import getProduits from "../../../composables/getProduits";

// FIREBASE STORAGE //
import { projectFirestore } from "../../../../src/firebase/config";
import firebase from "firebase/app";
import "firebase/storage";
import { ref } from "vue";

export default {
  name: "InventaireAjout",
  components: { Sidebar, HeaderGestion },
  setup() {
    // LISTING DES PRODUITS
    const { produits, error, load } = getProduits();
    load();

    // VARIABLES DE FORMULAIRE
    const uploadFrom = ref("");
    // PRODUIT GLOBALE
    const nomProduit = ref("");
    const soustitreProduit = ref("");
    const descriptionProduit = ref("");
    const isTaxable = ref(true);
    const dispo = ref(true);
    const display = ref(true);
    const multiFormat = ref(false);
    const lienProduit = ref("");
    // VARIABLES UPLOAD
    const selectedFile = ref();
    const nomFichier = ref();
    const nbrFormats = ref(1);
    const erreur = ref("none");
    const success = ref(false);

    // PAR FORMAT PRODUITS

    const prix1 = ref("");
    const format1 = ref("");
    const disp1 = ref(true);

    const prix2 = ref("");
    const format2 = ref("");
    const disp2 = ref(true);

    const prix3 = ref("");
    const format3 = ref("");
    const disp3 = ref(true);

    const prix4 = ref("");
    const format4 = ref("");
    const disp4 = ref(true);

    // SELECTION DU FICHIER PLUS NOMMER LE FICHIER THUMBNAIL
    const onFileSelected = (event) => {
      uploadFrom.value = event.target.id;
      selectedFile.value = event.target.files[0];

      // INSERER RANDOM 5 CARAC
      nomFichier.value = [
        selectedFile._value.name.slice(0, selectedFile._value.name.length - 4),
        "_" + randomString(5, uploadFrom.value),
        selectedFile._value.name.slice(selectedFile._value.name.length - 4),
      ].join("");
      onUploadImage(uploadFrom);
    };

    const randomString = (length, photoType) => {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result + "_" + photoType;
    };
    const imgThumbUrl = ref(null);
    const imgHighResUrl = ref(null);
    const imgSec1ThumbUrl = ref(null);
    const imgSec1HighResUrl = ref(null);
    const imgSec2ThumbUrl = ref(null);
    const imgSec2HighResUrl = ref(null);
    const imgFormat2Thumb = ref(null);
    const imgFormat2HighRes = ref(null);
    const imgFormat3Thumb = ref(null);
    const imgFormat3HighRes = ref(null);
    const imgFormat4Thumb = ref(null);
    const imgFormat4HighRes = ref(null);

    // UPLOAD THUMBNAIL
    const onUploadImage = (typePhoto) => {
      const sendFile = firebase
        .storage()
        .ref("imagesProduits/" + nomFichier.value)
        .put(selectedFile.value);
      sendFile.on(
        "state_changed",
        (snapshot) => {
          // PROGRESS
        },
        (error) => {
          // ERREUR
          console.log(error);
        },
        () => {
          // COMPLETE
          firebase
            .storage()
            .ref("imagesProduits")
            .child(nomFichier.value)
            .getDownloadURL()
            .then((url) => {
              switch (typePhoto._value) {
                case "main_thumb":
                  imgThumbUrl.value = url;
                  break;
                case "main_HighRes":
                  imgHighResUrl.value = url;
                  break;
                case "imgSec1Thumb":
                  imgSec1ThumbUrl.value = url;
                  break;
                case "imgSec1HighRes":
                  imgSec1HighResUrl.value = url;
                  break;
                case "imgSec2Thumb":
                  imgSec2ThumbUrl.value = url;
                  break;
                case "imgSec2HighRes":
                  imgSec2HighResUrl.value = url;
                  break;
                case "imgFormat2Thumb":
                  imgFormat2Thumb.value = url;
                  break;
                case "imgFormat2HighRes":
                  imgFormat2HighRes.value = url;
                  break;
                case "imgFormat3Thumb":
                  imgFormat3Thumb.value = url;
                  break;
                case "imgFormat3HighRes":
                  imgFormat3HighRes.value = url;
                  break;
                case "imgFormat4Thumb":
                  imgFormat4Thumb.value = url;
                  break;
                case "imgFormat4HighRes":
                  imgFormat4HighRes.value = url;
                  break;
                default:
                  // ERREUR
                  console.log(typePhoto);
                  alert("Une erreur est survenue lors de l'envoie de la photo");
              }
            });
        }
      );
    };

    // GESTION NOMBRE DE FORMATS POUR LE PRODUIT

    const changeNbrFormat = () => {
      if (nbrFormats == 1) {
        multiFormat.value = false;
      } else {
        multiFormat.value = true;
      }
    };

    // VERIFICATION DU NOMBRE DE FORMAT ET QUE LES CHAMPS SOIENT BIEN REMPLIS
    const verifBeforePush = () => {
      let countLiensProduit = 0; // VERIFICATION SU LIEN PRODUIT EST UNIQUE
      produits._value.forEach((produit) => {
        if (produit.lienProduit == lienProduit.value) {
          countLiensProduit++; //
        }
      });

      if (
        imgThumbUrl.value !== null &&
        imgHighResUrl.value !== null &&
        imgSec1ThumbUrl.value !== null &&
        imgSec2ThumbUrl.value !== null &&
        imgSec1HighResUrl.value !== null &&
        imgSec2HighResUrl.value !== null
      ) {
        // SI PAS EGALE A NULL
        if (imgThumbUrl.value == null) {
          erreur.value = " Image principale thumbnail manquante";
          return [1, " Image principale Thumbnail manquante"];
        } else if (imgHighResUrl.value == null) {
          erreur.value = " Image principale HighRes manquante";
          return [1, " Image principale HighRes manquante"];
        } else if (imgSec1ThumbUrl.value == null) {
          erreur.value = " Image Thumbnail sec 1 manquante";
          return [1, " Image Thumbnail HighRes sec 1 manquante"];
        } else if (imgSec2ThumbUrl.value == null) {
          erreur.value = " Image Thumbnail sec 2 manquate";
          return [1, " Image Thumbnail HighRes sec 2 manquante"];
        } else if (imgSec1HighResUrl.value == null) {
          erreur.value = " Image HighRes sec 1 manquante";
          return [1, " Image  HighRes sec 1 manquante"];
        } else if (imgSec2HighResUrl.value == null) {
          erreur.value = " Image HighRes sec 2 manquante";
          return [1, " Image  HighRes sec 2 manquante"];
        } else if (imgFormat2Thumb.value == null && nbrFormats.value >= 2) {
          erreur.value = " Image Thumb Format 2 manquante";
          return [1, " Image Thumb Format 2 manquante"];
        } else if (imgFormat2HighRes == null && nbrFormats.value >= 2) {
          erreur.value = " Image Thumb Format 2 manquante";
          return [1, " Image Thumb Format 2 manquante"];
        } else if (imgFormat3Thumb.value == null && nbrFormats.value >= 3) {
          erreur.value = " Image Thumb Format 3 manquante";
          return [1, " Image Thumb Format 2 manquante"];
        } else if (imgFormat3HighRes.value == null && nbrFormats.value >= 3) {
          erreur.value = " Image Thumb Format 3 manquante";
          return [1, " Image Thumb Format 3 manquante"];
        } else if (imgFormat4Thumb.value == null && nbrFormats.value == 4) {
          erreur.value = " Image Thumb Format 4 manquante";
          return [1, " Image Thumb Format 4 manquante"];
        } else if (imgFormat4HighRes.value == null && nbrFormats.value == 4) {
          erreur.value = " Image Thumb Format 4 manquante";
          return [1, " Image Thumb Format 4 manquante"];
        } else if (countLiensProduit != 0) {
          erreur.value =
            " Le lien du produit doit etre unique, veuillez changer de lien.";
          return [
            1,
            " Le lien du produit doit etre unique, veuillez changer de lien.",
          ];
        }
      } else {
        erreur.value = " Image(s) manquante(s)";
        return [1, " Image(s) manquante(s)"];
      }

      if (nbrFormats.value == 1) {
        return [0, 1];
      } else if (nbrFormats.value == 2) {
        return [0, 2];
      } else if (nbrFormats.value == 3) {
        return [0, 3];
      } else if (nbrFormats.value == 4) {
        return [0, 4];
      } else {
        erreur.value = "ERREUR ICONNUE";
        return [1, "ERREUR ICONNUE"];
      }
    };

    // ENREGISTREMENT DU PRODUIT
    const handleSubmit = async () => {
      erreur.value = "none";
      success.value = false;
      let okToPushItem = confirm(
        "Voulez-vous vraiment ajouter l'item à votre inventaire ?"
      ); // CONFIRMATION
      if (!okToPushItem) {
        return;
      }
      let itemSpec = {};
      let priceListFormat = {};
      if (verifBeforePush()[0] == 0) {
        // VERIFICATION SI CHAMPS REMPLIS ET COMBIENS
        itemSpec = {
          nom: nomProduit.value,
          soustitreProduit: soustitreProduit.value,
          lienProduit: lienProduit.value,
          description: descriptionProduit.value,
          taxable: isTaxable.value,
          imageThumb: imgThumbUrl.value,
          imageHighRes: imgHighResUrl.value,
          imageSec1Thumb: imgSec1ThumbUrl.value,
          imageSec1HighRes: imgSec1HighResUrl.value,
          imageSec2Thumb: imgSec2ThumbUrl.value,
          imageSec2HighRes: imgSec2HighResUrl.value,

          dispo: dispo.value,
          display: display.value,
          multiFormat: multiFormat.value,
          deleted: false,
        };
        if (verifBeforePush()[1] == 1) {
          priceListFormat = {
            tarification: [
              {
                format: format1.value,
                prix: parseFloat(prix1.value),
                disp: disp1.value,
              },
            ],
          };
        } else if (verifBeforePush()[1] == 2) {
          priceListFormat = {
            imageFormat2Thumb: imgFormat2Thumb.value,
            imageFormat2HighRes: imgFormat2HighRes.value,

            tarification: [
              {
                format: format1.value,
                prix: parseFloat(prix1.value),
                disp: disp1.value,
              },
              {
                format: format2.value,
                prix: parseFloat(prix2.value),
                disp: disp2.value,
              },
            ],
          };
        } else if (verifBeforePush()[1] == 3) {
          priceListFormat = {
            imageFormat2Thumb: imgFormat2Thumb.value,
            imageFormat2HighRes: imgFormat2HighRes.value,
            imageFormat3Thumb: imgFormat3Thumb.value,
            imageFormat3HighRes: imgFormat3HighRes.value,

            tarification: [
              {
                format: format1.value,
                prix: parseFloat(prix1.value),
                disp: disp1.value,
              },
              {
                format: format2.value,
                prix: parseFloat(prix2.value),
                disp: disp2.value,
              },
              {
                format: format3.value,
                prix: parseFloat(prix3.value),
                disp: disp3.value,
              },
            ],
          };
        } else {
          priceListFormat = {
            imageFormat2Thumb: imgFormat2Thumb.value,
            imageFormat2HighRes: imgFormat2HighRes.value,
            imageFormat3Thumb: imgFormat3Thumb.value,
            imageFormat3HighRes: imgFormat3HighRes.value,
            imageFormat4Thumb: imgFormat4Thumb.value,
            imageFormat4HighRes: imgFormat4HighRes.value,

            tarification: [
              {
                format: format1.value,
                prix: parseFloat(prix1.value),
                disp: disp1.value,
              },
              {
                format: format2.value,
                prix: parseFloat(prix2.value),
                disp: disp2.value,
              },
              {
                format: format3.value,
                prix: parseFloat(prix3.value),
                disp: disp3.value,
              },
              {
                format: format4.value,
                prix: parseFloat(prix4.value),
                disp: disp4.value,
              },
            ],
          };
        }
        const item = { ...itemSpec, ...priceListFormat }; // MERGE LES OBJETS
        const res = await projectFirestore.collection("inventaire").add(item); // SOUMET L'OBJET
        if (res) {
          // RESET FORM FICHIER A UPLOADER
          success.value = true;
          nomProduit.value = "";
          soustitreProduit.value = "";
          lienProduit.value = "";
          descriptionProduit.value = "";
          isTaxable.value = true;
          dispo.value = true;
          display.value = true;
          multiFormat.value = true;
          prix1.value = "";
          format1.value = "";
          disp1.value = true;
          prix2.value = ref;
          format2.value = "";
          disp2.value = true;
          prix3.value = "";
          format3.value = "";
          disp3.value = true;
          prix4.value = "";
          format4.value = "";
          disp4.value = true;
          erreur.value = "none";

          selectedFile.value = "";
          nomFichier.value = "";
          nbrFormats.value = 1;
          imgThumbUrl.value = null;
          imgHighResUrl.value = null;
          imgSec1HighResUrl.value = null;
          imgSec2HighResUrl.value = null;
          imgSec1ThumbUrl.value = null;
          imgSec2ThumbUrl.value = null;
          imgFormat2Thumb.value = null;
          imgFormat2HighRes.value = null;
          imgFormat3Thumb.value = null;
          imgFormat3HighRes.value = null;
          imgFormat4Thumb.value = null;
          imgFormat4HighRes.value = null;
        }
      } else {
        console.log(verifBeforePush()[1]);
      }
    };

    return {
      onFileSelected,
      selectedFile,
      onUploadImage,
      nomFichier,
      randomString,
      changeNbrFormat,
      produits,
      error,
      load,
      multiFormat,
      imgThumbUrl,
      imgHighResUrl,
      nomProduit,
      descriptionProduit,
      lienProduit,
      soustitreProduit,
      isTaxable,
      dispo,
      display,
      multiFormat,
      prix1,
      prix2,
      prix3,
      prix4,
      format1,
      format2,
      format3,
      format4,
      erreur,
      success,
      disp1,
      disp2,
      disp3,
      disp4,
      nbrFormats,
      handleSubmit,
      verifBeforePush,
      uploadFrom,
      imgSec1ThumbUrl,
      imgSec1HighResUrl,
      imgSec2ThumbUrl,
      imgSec2HighResUrl,
      imgFormat2Thumb,
      imgFormat2HighRes,
      imgFormat3Thumb,
      imgFormat3HighRes,
      imgFormat4Thumb,
      imgFormat4HighRes,
    };
  },
};
</script>

<style>
.bouton_inventaire {
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid hsl(204, 86%, 53%);
  color: hsl(204, 86%, 53%);
  background-color: white;
}

/* PREVIEW INVENTAIRE  */

.thumb_img_inventaire,
.canvaThumb {
  height: 250px;
  width: 250px;
}

.canvaThumb {
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info_img_thumb {
  font-size: 30px;
  color: black;
}

.checkbox_inventaire {
  margin-right: 5px;
}

/* FIELDSET AUTOUR DES IMAGES */
.fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
}

.fieldset h1 {
  position: absolute;
  top: 0;
  font-size: 18px;
  line-height: 1;
  margin: -9px 0 0; /* half of font-size */
  background: #fff;
  padding: 0 3px;
}
</style>
