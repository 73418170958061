import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'
import Boutique from '../views/Boutique/Boutique.vue'
import Produit from '../views/Boutique/Produit.vue'
import Gestion from '../views/Gestion/Gestion.vue'
import InventaireAjout from '../views/Gestion/GestionInventaire/InventaireAjout.vue'
import InventaireModif from '../views/Gestion/GestionInventaire/InventaireModif.vue'
import InventaireSuppression from '../views/Gestion/GestionInventaire/InventaireSuppression.vue'
import Ventes from '../views/Gestion/GestionVentes/Ventes.vue'
import Certification from '../views/Certification.vue'
import Apropos from '../views/Apropos.vue'
import Contact from '../views/Contact.vue'
import Panier from '../views/Panier.vue'
import Login from '../views/Gestion/GestionLogin/Login.vue'
import Success from '../views/Boutique/Success.vue'
import Erreur from '../views/Boutique/Erreur.vue'


import firebase from 'firebase/app';
import 'firebase/auth';


const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/boutique',
    name: 'Boutique',
    component: () => import(/* webpackChuckName: "Boutique" */ "../views/Boutique/Boutique.vue")
  },
  {
    path: '/boutique/:produit',
    name: 'Produit',
    component: () => import(/* webpackChuckName: "Produit" */ "../views/Boutique/Produit.vue")
  },
  {
    path: '/boutique/panier',
    name: 'Panier',
    component: () => import(/* webpackChuckName: "Panier" */ "../views/Panier.vue")
  },
  {
    path: '/boutique/success/:id',
    name: 'Success',
    component: () => import(/* webpackChuckName: "Panier" */ "../views/Boutique/Success.vue")
  },
  {
    path: '/boutique/erreur/',
    name: 'Erreur',
    component: () => import(/* webpackChuckName: "Panier" */ "../views/Boutique/Erreur.vue")
  },
  {
    path: '/gestion/login',
    name: 'Login',
    component: () => import(/* webpackChuckName: "Login" */ "../views/Gestion/GestionLogin/Login.vue")
  },
  {
    path: '/gestion',
    name: 'Gestion',
    component: () => import(/* webpackChuckName: "Gestion" */ "../views/Gestion/Gestion.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '/gestion/inventaire/ajout',
    name: 'InventaireAjout',
    component: () => import(/* webpackChuckName: "InventaireAjout" */ "../views/Gestion/GestionInventaire/InventaireAjout.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '/gestion/inventaire/modif',
    name: 'InventaireModif',
    component: () => import(/* webpackChuckName: "InventaireModif" */ "../views/Gestion/GestionInventaire/InventaireModif.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '/gestion/inventaire/suppression',
    name: 'InventaireSuppression',
    component: () => import(/* webpackChuckName: "InventaireSuppresion" */ "../views/Gestion/GestionInventaire/InventaireSuppression.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '/gestion/ventes',
    name: 'Ventes',
    component: () => import(/* webpackChuckName: "Ventes" */ "../views/Gestion/GestionVentes/Ventes.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '/apropos',
    name: 'Apropos',
    component: () => import(/* webpackChuckName: "Apropos" */ "../views/Apropos.vue"),
  },
  {
    path: '/certification',
    name: 'Certification',
    component: () => import(/* webpackChuckName: "Certification" */ "../views/Certification.vue"),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChuckName: "Contact" */ "../views/Contact.vue"),
  }
  

]




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
});


router.beforeEach((to, from, next) => {
  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if(requiresAuth && !isAuthenticated){
    next('/gestion/login');
  }else{
    next();
  }
});
export default router
