import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getProduits = () => {
    const produits = ref([]) 

    const error = ref(null)


    const load = async () => {
        try {
            const res = await projectFirestore.collection('inventaire').get()
            res.docs.forEach( doc => {
               if(doc.data().deleted === false){ // SELECTIONNE SEULEMENT LES PRODUITS NON DELETÉ
                   produits.value.push({...doc.data(), produitId: doc.id})
               }
             
           })
        }
        catch(err) {
            error.value = err.message
            console.log(error.value)
        }
    }
    return { produits, error, load}
}

export default getProduits