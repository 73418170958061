<template>
 <!-- Sidebar -->
        <div id="sidebar-wrapper">
            <div class="sidebar-logo-gestion">
                <img src="../../../public/images/logo_erablieres_bonneville.png" alt="Les Erablieres Bonneville" class="logo-nav-image">
            </div>
            <div class="links_sidenav_inventaire">
              <ul class="sidebar-nav" v-for="item in menu" :key="item.nom">
                  <li class="link_sidenav_inventaire">
                      <div class="icon"><i :class="item.icone"></i></div>
                      <router-link :to="item.adresse">{{item.nom}}</router-link>
                  </li>
              </ul>
            </div>
        </div>
      
</template>

<script>
import menuGestion from '../../composables/menuGestion' // MENU
export default {
    name : 'Sidebar',
    setup(){

        // GET MENUGESTION
        const menu  = menuGestion();
      
        
        return{
            menu
        }
    }

}
</script>

<style>

.links_sidenav_inventaire{
  min-height: 50vh;
  padding-top: 50%;
}

.link_sidenav_inventaire{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
}

.icon {
  width: 40px;
}

ul{
  padding-left: 0 !important;
}

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 230px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 230px;
    width: 0;
    height: 100%;
    margin-left: -230px;
    overflow-y: auto;
    background: #f8f8f8;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 230px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -230px;
}

.sidebar-logo-gestion{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content:center;
    align-items: center;
}

/* Sidebar Styles */

li {
  
    list-style: none;
   
}
li a{
    text-decoration: none;
    color: #252323;
    font-size: 1.2rem;
    text-transform: uppercase;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 230px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 230px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}


</style>