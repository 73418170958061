<template>


<div class="section-admin">
    <div class="text-center pb-5">
        <h2>LES ERABLIERES BONNEVILLE</h2>
        <h4 class="text-muted">SECTION ADMINISTRATEUR</h4>
    </div>
    <div class="form-admin">
    <form @submit.prevent="submitLogin()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 text-center pb-5">
                    <img src="../../../../public/images/logo_erablieres_bonneville.png" alt="Les Erablieres Bonneville" class="logo-nav-image">
                </div>
                <div class="col-12 pb-3 text-center">
                    <input type="email" v-model="email" placeholder="Courriel" class="admin-input form-control">
                </div>
                <div class="col-12 pb-4 text-center">
                    <input type="password" v-model="password" placeholder="Mot de passe" class="admin-input form-control">
                </div>
                <div v-if="errorMessage.length > 0" class="alert alert-danger alert-dismissible fade show col-12 mx-auto w-25" role="alert">
                    Le nom d'usager ou le mot de passe est incorrect !
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <div class="col-12 text-center">  
                    <button type="submit" class="btn-submit-admin btn btn-outline-secondary">SE CONNECTER</button>
                </div>
            </div>
        </div>
        
      
    </form>
    </div>
</div>
</template>
<script>

import firebase from 'firebase/app';
import 'firebase/auth';
import { ref } from 'vue';
import { useRouter } from "vue-router";

export default {
    name : 'Login',
    setup(){

        const route = useRouter();
        const errorMessage = ref('');
    
      


        const email = ref('');
        const password = ref('');

        const submitLogin = () => {
            firebase.auth().signInWithEmailAndPassword(email.value, password.value)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                route.push({ name: 'Gestion' });
            
            })
            .catch((error) => {
                var errorCode = error.code;
                 errorMessage.value = error.message;
            });
        }
    

        return{
            email,
            password,
            submitLogin,
            errorMessage
        }
    }

}
</script>

<style>


.section-admin{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-admin{
 
}

.admin-input{
    width: 250px;
    height:50px;
    margin: auto;
}



</style>