<template>
   <div id="wrapper">
    <Sidebar />
        <div id="page-content-wrapper">
                <div class="row">
                    <HeaderGestion />
                </div>
                
                    <div class="row">
                    <div class="col-lg-12">
                    <h2 class="text-center pt-2">SUPRESSION D'UN PRODUIT <span class="material-icons">delete</span>
                    </h2>
                    </div>
                    <div class="row">
                            <div class="col-12">
                                <!-- MESSAGE ERREUR OU SUCCESS -->
                                <div v-if="erreur.length > 5" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                    <strong>Erreur</strong>{{erreur}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <div v-if="success" class="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                    <strong>L'item à été supprimé avec succès</strong>
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row m-3">
                    <div class="col-lg-12">
                      
                            <div class="col-12">
                                <p class="h3">Sélectionner un produit à supprimer</p>
                            </div>
                            <div class="pb-5 col-12">
                                <div v-if="produits"> <!-- FORMULAIRE SELECTION DU PRODUIT -->
                                    <select v-model="selectedId" class="form-select" aria-label="Default select example" @change="onSelectedProduit($event)">
                                        <option disabled selected>- Sélectionner un produit -</option>
                                        <option v-for="produit in produits" :key="produit.produitId" :value="produit.produitId">{{produit.nom}}</option>
                                    </select>
                                </div>
                                <button class="btn btn-danger mt-3" @click="deleteProduit()">SUPPRIMER</button>
                              </div>
                        
                  
                        </div></div>
                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
           
                </div>

                    
        </div>
    </div>
</template>

<script>
import getProduits from '../../../composables/getProduits'
import {projectFirestore} from '../../../../src/firebase/config'
// IMPORT COMPOSANTS
import Sidebar from '../Sidebar.vue';
import HeaderGestion from '../HeaderGestion.vue'
import {ref} from 'vue'
export default {
    name: 'InventaireSuppression',
    components:{Sidebar, HeaderGestion},
    setup(){

        
       const success = ref()
       const erreur = ref('')
       const selectedId = ref('- Sélectionner un produit -')
       const selectedProduit = ref('')
       const nomProduit = ref('')
       const descriptionProduit = ref('')
       const dispo = ref('')
       const display = ref('')
       const multiFormat = ref('')
       const nbrFormats = ref('')
        const imageHighResUrl = ref('')
        const imgSec1HighResUrl = ref('') 
        const imgSec2HighResUrl = ref('')
        const imageThumbUrl = ref('')
        const imgSec1ThumbUrl = ref('')
        const imgSec2ThumbUrl = ref('')
        const prix1 = ref('')
        const prix2 = ref('')
        const prix3 = ref('')
        const prix4 = ref('')
        const format1 = ref('')
        const format2 = ref('')
        const format3 = ref('')
        const format4 = ref('')
        const disp1 = ref('')
        const disp2 = ref('')
        const disp3 = ref('')
        const disp4 = ref('')


        // LISTING DES PRODUITS
        const { produits, error, load } = getProduits();
        load()

  

        const onSelectedProduit = () => {
            selectedProduit.value = produits._value.find( (obj) => obj.produitId == selectedId.value ); // ASSOCIER VALUER DU PRODUIT SELECTIONER DANS LE SELECT BOX
            resetSelectedProduit()
            updateInfosProduit()
        }

        const resetSelectedProduit = () =>{ // RESET INFOS AVANT NOUVEAU PRODUITS
            nomProduit.value = ''
            descriptionProduit.value = ''
            dispo.value = ''
            display.value = ''
            multiFormat.value = ''
            // VARIABLES UPLOAD
      
            imageHighResUrl.value = null
            imgSec1HighResUrl.value = null
            imgSec2HighResUrl.value = null
            imageThumbUrl.value = null
            imgSec1ThumbUrl.value = null
            imgSec2ThumbUrl.value = null

            nbrFormats.value = ref(1)

        

        // PAR FORMAT PRODUITS

        prix1.value = ref('')
        format1.value = ref('')
        disp1.value  = ref('')
       
        prix2.value  = ref('')
        format2.value  = ref('')
        disp2.value  = ref('')

        prix3.value  = ref('')
        format3.value  = ref('')
        disp3.value  = ref('')

        prix4.value  = ref('')
        format4.value  = ref('')
        disp4.value  = ref('')
        }
        // GET INFOS DU PRODUIT EN PARTICULIER
        const updateInfosProduit =  () => {
            nomProduit.value = selectedProduit.value.nom
            descriptionProduit.value = selectedProduit.value.description
            dispo.value = selectedProduit.value.dispo
            display.value = selectedProduit.value.display
            multiFormat.value = selectedProduit.value.multiFormat
            nbrFormats.value = selectedProduit.value.tarification.length // NOMBRE DE FORMAT
            // FICHIERS IMAGES
            imageThumbUrl.value = selectedProduit.value.imageThumb
            imageHighResUrl.value = selectedProduit.value.imageHighRes
            imgSec1ThumbUrl.value = selectedProduit.value.imageSec1Thumb
            imgSec1HighResUrl.value = selectedProduit.value.imageSec1HighRes
            imgSec2ThumbUrl.value = selectedProduit.value.imageSec2Thumb
            imgSec2HighResUrl.value = selectedProduit.value.imageSec2HighRes
            

            // ASSOCIER LES VARIABLE A RIEN POUR NOMBRE DE FORMATS FUTURS

                prix2.value = ''
                format2.value = ''
                disp2.value = true

                prix3.value = ''
                format3.value = ''
                disp3.value = true

                prix4.value = ''
                format4.value = ''
                disp4.value = true

            if(nbrFormats.value >= 1){
                prix1.value = selectedProduit.value.tarification[0].prix
                format1.value = selectedProduit.value.tarification[0].format
                disp1.value = selectedProduit.value.tarification[0].disp

            }
            if(nbrFormats.value >= 2){
                prix2.value = selectedProduit.value.tarification[1].prix
                format2.value = selectedProduit.value.tarification[1].format
                disp2.value = selectedProduit.value.tarification[1].disp
            }
            if(nbrFormats.value >= 3 ){
                prix3.value = selectedProduit.value.tarification[2].prix
                format3.value = selectedProduit.value.tarification[2].format
                disp3.value = selectedProduit.value.tarification[2].disp
            }
            if(nbrFormats.value == 4 ){
                prix4.value = selectedProduit.value.tarification[3].prix
                format4.value = selectedProduit.value.tarification[3].format
                disp4.value = selectedProduit.value.tarification[3].disp
            }
        }


        const deleteProduit =  () => {
            if(selectedId.value == '- Sélectionner un produit -'){
                erreur.value = ' Vous devez choisir un produit à supprimer'
                return
            }else{
                erreur.value = ''
            }
            let okToDeleteItem = confirm('Voulez-vous vraiment supprimer le produit de votre inventaire ?'); // CONFIRMATION
             if(!okToDeleteItem){
                 return
             }else{
                    projectFirestore.collection('inventaire').doc(selectedId.value).update({deleted: true}) // 
                    produits.value.splice(produits.value.findIndex(produit => produit.produitId == selectedId.value, 1))
                    selectedId.value = '- Sélectionner un produit -'
                    resetSelectedProduit()
                    produits.value.slice()
                    success.value = "L'item a été supprimé avec success"
            
             }
           
        }



        return{
            erreur, success, produits, error, load, selectedId, onSelectedProduit, selectedProduit, resetSelectedProduit, nomProduit, descriptionProduit, display, dispo, display, multiFormat,
            imageThumbUrl, imageHighResUrl, imgSec1ThumbUrl, imgSec1HighResUrl, imgSec2ThumbUrl, imgSec2HighResUrl, nbrFormats, disp1, disp2, disp3,disp4, prix1, prix2, prix3, prix4, format1, format2, format3, format4, 
            deleteProduit
        }
    }

}
</script>

<style>



</style>